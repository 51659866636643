import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './admission.types'
import { actions } from './admission.actions'
import { snackbarActions } from '@Root/store'
import { errorMessage, saveDataOptionsToLocalStorage } from '@Root/helpers'
import { API } from '@Root/API'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { contactId, history } = payload
  try {
    const response = yield call(API.contact.admissions.get, contactId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}
//test
function* create({ payload }) {
  const { contactId, data, resolve, reject } = payload
  yield put(actions.toggleLoading(true))
  try {
    const response = yield call(API.contact.admissions.create, contactId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
    yield put(actions.toggleSuccess(true))
    resolve()
    yield put(snackbarActions.setSuccessNotification({ text: 'The admission information has been created successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    yield put(actions.toggleSuccess(false))
    reject()
  }
  yield put(actions.toggleLoading(false))
  yield put(actions.toggleSuccess(false))
}

function* edit({ payload }) {
  const { contactId, data, resolve, reject, admisionId } = payload
  yield put(actions.toggleLoading(true))
  try {
    const response = yield call(API.contact.admissions.edit, contactId, admisionId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
    yield put(actions.toggleSuccess(true))
    resolve()
    yield put(snackbarActions.setSuccessNotification({ text: 'The admission information has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    yield put(actions.toggleSuccess(false))
    reject()
  }
  yield put(actions.toggleLoading(false))
  yield put(actions.toggleSuccess(false))
}

function* remove({ payload }) {
  const { contactId, admissionsId } = payload
  yield put(actions.toggleLoading(true))
  try {
    const response = yield call(API.contact.admissions.remove, contactId, admissionsId)
    yield put(actions.set(response.data.data))
    yield put(snackbarActions.setSuccessNotification({ text: 'The admission information has been deleted successfully!' }))
  } catch (error) {}
  yield put(actions.toggleLoading(false))
}

function* getOptions({ payload }) {
  const { id } = payload
  try {
    const response = yield all([
      yield call(API.admissions.application.options.getStatic),
      yield call(API.admissions.options.getStatic),
      yield call(API.contact.admissions.engagement.get, id),
    ])

    const { applications_status = [], forms_status = [], bap_statuses = [] } = {
      ...response[0].data,
      ...response[1].data,
    }

    const formattedData = {
      applications_status: applications_status.map(obj => ({ value: obj.id, label: obj.label })),
      forms_status: forms_status.map(obj => ({ value: obj.id, label: obj.label })),
      admission_bap_statuses: bap_statuses.map(obj => ({ value: obj.id, label: obj.name })),
      engagement: [...response[2].data.data].map(obj => ({ value: obj.id, label: obj.label })),
    }

    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('student_application', formattedData)
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_ADMISSION, get),
    takeEvery(types.CREATE_ADMISSION, create),
    takeEvery(types.EDIT_ADMISSION, edit),
    takeEvery(types.DELETE_ADMISSION, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}
