import React from 'react'
import styles from './style.module.scss'
import { TextInput } from '@Root/components'
import { CrossIcon } from '@Root/assets'

export const SearchField = ({ onChange, value, maxLength, placeholder = 'Search', styleWrapper, styleSearch, error }) => {
  return (
    <div className={styles.search} style={{ ...styleSearch }}>
      <div className={styles.wrapper} style={{ ...styleWrapper }}>
        <TextInput changeHandler={onChange} value={value} placeholder={placeholder} maxLength={maxLength} style={{ width: '100%' }} error={error} />
        <img src={CrossIcon} className={styles.reset} onClick={() => onChange('')} alt={'cross'} />
      </div>
    </div>
  )
}
