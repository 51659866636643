import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './globalSearch.types'
import { actions } from './globalSearch.actions'
import { snackbarActions } from '@Root/store'
import { errorMessage } from '@Root/helpers'
import { API } from '@Root/API'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield API.globalSearch.search(payload)
    yield put(actions.setSearchData(data))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getPagination({ payload }) {
  yield put(actions.toggleLoadingPagination(true))
  try {
    const { data } = yield API.globalSearch.search(payload)
    yield put(actions.setSearchDataPagination(data))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoadingPagination(false))
}

export function* rootSaga() {
  yield all([takeEvery(types.GET_SEARCH_DATA, get), takeEvery(types.GET_SEARCH_DATA_PAGINATION, getPagination)])
}
