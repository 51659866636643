import React from 'react'
import { SupervisorAllocationForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { supervisorAllocationSelectors, supervisorAllocationActions } from '@Store/studies'
import { hesaSelectors } from '@Store/contact/person'

export const CreateVenueActivity = ({ programmeRecord, onOpenDescriptionModal, handleBack }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { contactId, studyProgrammeId } = useParams()
  const supervisorAllocation = useSelector(supervisorAllocationSelectors.supervisorAllocation)
  const options = useSelector(supervisorAllocationSelectors.options)
  const errors = useSelector(supervisorAllocationSelectors.errors)
  const isLoading = useSelector(supervisorAllocationSelectors.isLoading)
  const dynamicOptions = useSelector(hesaSelectors.options).dynamic

  const onCreate = data => {
    dispatch(
      supervisorAllocationActions.create({
        data,
        history,
        studentCourseSessionId: programmeRecord.student_course_session_id,
        studyProgrammeId,
        contactId,
      })
    )
  }

  const onCancel = () => {
    handleBack()
  }

  return (
    <SupervisorAllocationForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      initialValue={supervisorAllocation}
      options={options}
      isLoading={isLoading}
      dynamicOptions={dynamicOptions}
      errorsFields={errors}
    />
  )
}
