import React, { useEffect } from 'react'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import DocumentTitle from 'react-document-title'
import { DetailInformation } from '../DetailInformation'
import { Spinner, BackButton, NameTitles, SaveButton } from '@Root/components'
import { admissionActions, admissionSelectors } from '@Store/admission'
import { useHistory, useParams } from 'react-router'
import { programmeActions, programmeSelectors, snackbarActions, modalActions } from '@Root/store'
import { API } from '@Root/API'

export const ApplicationPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { applicationId } = useParams()
  const application = useSelector(admissionSelectors.application)
  const { form } = useSelector(admissionSelectors.permissions)
  const isLoading = useSelector(admissionSelectors.isLoading)
  //const programmes = useSelector(programmeSelectors.programmesListOptions)

  useEffect(() => {
    dispatch(admissionActions.getApplication({ id: applicationId }))
    dispatch(programmeActions.getProgrammesOptions())
  }, [dispatch, applicationId])

  const showModal = (component, props) => {
    dispatch(modalActions.showModal(component, props))
  }

  const onDeleteApplicationForm = async () => {
    try {
      new Promise((resolve, reject) => {
        showModal('ConfirmationModal', {
          text: "This application will be removed?",
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      })
        .then(async () => {
          const res = await API.admissions.application.deleteApplications(applicationId)
          if (res?.data) {
            dispatch(snackbarActions.setSuccessNotification({ text: 'The application has been deleted successfully!' }))
            history.push('home/admissions/applications')
          }

        })
        .finally(() => dispatch(modalActions.hideModal()))

    } catch (error) {
      dispatch(snackbarActions.setSnackbar({ text: 'The application has\'t been deleted successfully!', isError: true }))
    }
  }

  return (
    <DocumentTitle title='Application'>
      <div className={classes.AdmissionsApplication}>
        <div className={classes.pageWrapper}>
          <div className={classes.backButtonWrapper}>
            <BackButton text='All Applications' clickHandler={() => history.goBack()} />
            {!isLoading ? application && <NameTitles data={application?.student} /> : <></>}
            {form?.delete && <SaveButton style={{ marginLeft: 'auto' }} onClick={() => onDeleteApplicationForm()}>Delete Application form</SaveButton>}
          </div>

          <div className={classes.line} />
          {!isLoading ? <DetailInformation /> : <Spinner style={{ height: 'calc(100vh - 280px)' }} />}
        </div>
      </div>
    </DocumentTitle>
  )
}
