import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './studentCourseSession.types'
import { API } from '@Root/API'
import { actions } from './studentCourseSession.actions'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage } from '@Root/helpers'
import { feeActions, feeRootSaga } from './fee'
import { admissionRootSaga, fundingAndMonitoringActions, studentCourseSessionPostgradActions } from '@Root/store'
import { venueActivityActions } from './venueActivity/index'
import { referencePeriodActions } from './referencePeriod/index'
import { studyLocationActions } from './studyLocation/index'
import { studentFinancialSupportActions } from './studentFinancialSupport/index'
import { sessionStatusActions } from './sessionStatus/index'
import { fundingBodyActions } from './fundingBody/index'
import { fundingAndMonitoringRootSaga } from './fundingAndMonitoring'
import { noteRootSaga } from './note'
import { venueActivityRootSaga } from './venueActivity'
import { referencePeriodRootSaga } from './referencePeriod'
import { attachmentRootSaga } from './attachment'
import { postgradRootSaga } from './postgrad'
import { sessionStatusRootSaga } from './sessionStatus'
import { studentFinancialSupportRootSaga } from './studentFinancialSupport'
import { studyLocationRootSaga } from './studyLocation'
import { fundingBodyRootSaga } from './fundingBody'
import { moduleInstancesRootSaga, moduleInstancesActions } from './moduleInstances/index'
import { supervisorAllocationRootSaga, supervisorAllocationActions } from './supervisorAllocation/index'

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  yield put(actions.setErrorsFields([]))
  const { data, history, contactId } = payload
  try {
    const response = yield call(API.studies.studentCourseSession.create, data)
    yield put(actions.set(null))
    history.push(`/home/studies/programmes/study/${contactId}/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The student course session has been created successfully!' }))
  } catch (error) {
    const fields = Object.keys(error.response.data.errors)
    yield put(actions.set(data))
    yield put(actions.setErrorsFields(fields))
  }
  yield put(actions.toggleLoading(false))
}

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { studyProgrammeId } = payload
  try {
    const response = yield call(API.studies.studentCourseSession.get, studyProgrammeId)
    const permissions = response.data.permissions
    const data = response.data.data
    yield put(feeActions.set(data.fees))
    if (data.fees?.permissions) yield put(feeActions.setPermissions(data.fees?.permissions))
    yield put(studentCourseSessionPostgradActions.set(data.post_grad))
    if (data.post_grad?.permissions) yield put(studentCourseSessionPostgradActions.setPermissions(data.post_grad?.permissions))
    yield put(studentCourseSessionPostgradActions.toggleCanBeAttached(data.can_attach_post_grad))
    yield put(fundingAndMonitoringActions.set(data.funding_and_monitoring))
    if (data.funding_and_monitoring?.permissions) yield put(fundingAndMonitoringActions.setPermissions(data.funding_and_monitoring?.permissions))
    yield put(actions.set(data.course_student_session))
    if (permissions) yield put(actions.setPermissions(permissions))
    yield put(actions.set(data.course_student_session))
    yield put(venueActivityActions.setCanBeCreated(data.can_be_created_off_venue_activity))
    yield put(referencePeriodActions.setCanBeCreated(data.can_be_created_off_venue_activity))
    yield put(studyLocationActions.setCanBeCreated(data.can_be_created_study_location))
    yield put(sessionStatusActions.setCanBeCreated(data.can_be_created_session_status))
    yield put(studentFinancialSupportActions.setCanBeCreated(data.can_be_created_student_financial_support))
    yield put(fundingBodyActions.setCanBeCreated(data.can_be_created_funding_body))
    yield put(moduleInstancesActions.setCanBeCreated(data.can_be_created_module_instance))
    yield put(supervisorAllocationActions.setCanBeCreated(data.can_be_created_supervisor_allocation))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  yield put(actions.setErrorsFields([]))
  const { data } = payload
  try {
    yield call(API.studies.studentCourseSession.edit, data)
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The student course session has been saved successfully!' }))
  } catch (error) {
    yield put(actions.set(data.course_student_session))
    yield put(actions.setErrorsFields(Object.keys(error.response.data.errors)))
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
  yield put(actions.toggleSuccess(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  try {
    yield call(API.studies.studentCourseSession.remove, data)
    history.push('/home/studies/programmes')
    yield put(snackbarActions.setSuccessNotification({ text: 'The student course session has been deleted successfully!' }))
    yield put(actions.set(null))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* copy({ payload }) {
  yield put(actions.toggleLoading(true))
  yield put(actions.setErrorsFields([]))
  const { data, history } = payload
  try {
    const response = yield call(API.studies.studentCourseSession.duplicate, data)
    yield put(actions.setErrorsFields([]))
    history.push(`/home/studies/programmes/study/${data.course_student_session.contact_id}/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The student course session has been copied successfully!' }))
  } catch (error) {
    const fields = Object.keys(error.response.data.errors).map(key => key.split('.')[1])
    yield put(actions.set(data.course_student_session))
    yield put(actions.setErrorsFields(fields))
  }
  yield put(actions.toggleLoading(false))
}

function* getStaticOptions() {
  try {
    const response = yield call(API.studies.engagement.studentCourseSession.options.getStatic)
    const {
      employer_fees = [],
      fee_methods = [],
      intercalations = [],
      pgr_language_identifiers = [],
      placements = [],
      preparatory_student_course_session_flags = [],
      session_year_identifiers = [],
      student_course_session_modes = [],
      student_registration_number = [],
      study_abroads = [],
      invoice_hesa_identifiers = [],
      accommodation = [],
      learning_centre = [],
      study_years = [],
      academic_years_list = [],
      reason_for_student_course_session_ending = [],
      sponsors = [],
      financial_support = [],
      program_years_list = [],
      In_HEAPES = [],
      SLC_Loan = [],
      include_in_hesa = [],
    } = response.data

    const hesaDataToObject = arr => {
      return arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    }

    const formattedData = {
      employer_fees: hesaDataToObject(employer_fees),
      fee_methods: hesaDataToObject(fee_methods),
      intercalations: hesaDataToObject(intercalations),
      pgr_language_identifiers: hesaDataToObject(pgr_language_identifiers),
      placements: hesaDataToObject(placements),
      preparatory_student_course_session_flags: hesaDataToObject(preparatory_student_course_session_flags),
      session_year_identifiers: hesaDataToObject(session_year_identifiers),
      student_course_session_modes: hesaDataToObject(student_course_session_modes),
      student_registration_number: hesaDataToObject(student_registration_number),
      study_abroads: hesaDataToObject(study_abroads),
      invoice_hesa_identifiers: hesaDataToObject(invoice_hesa_identifiers),
      accommodation: accommodation.map(obj => ({ value: obj.id, label: obj.name })),
      learning_centre: learning_centre.map(obj => ({ value: obj.id, label: obj.name })),
      study_years: study_years.map(obj => ({ value: obj.id, label: obj.name })),
      academic_years_list: academic_years_list.map(obj => ({ value: obj.id, label: obj.name })),
      reason_for_student_course_session_ending: hesaDataToObject(reason_for_student_course_session_ending),
      sponsors: sponsors.map(obj => ({ value: obj.id, label: obj.label })),
      financial_support: financial_support.map(obj => ({ value: obj.id, label: obj.label })),
      program_years_list: hesaDataToObject(program_years_list),
      In_HEAPES: In_HEAPES.map(obj => ({ value: obj.id, label: obj.name })),
      slc_loan: SLC_Loan.map(obj => ({ value: obj.id, label: obj.name })),
      include_in_hesa: include_in_hesa.map(obj => ({ value: obj.id, label: obj.label })),
    }
    yield put(actions.setOptions({ name: 'static', data: formattedData }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

function* getDynamicOptions() {
  try {
    const {
      data: { context, managed_by, ordinand, program_awarding_bodies, session_years, status, fee_status, pastoral_tutors, diocese },
    } = yield call(API.studies.engagement.studentCourseSession.options.getDynamic)

    const convertFataToObjectLabel = arr => {
      return arr.map(obj => ({ value: obj.id, label: obj.label }))
    }
    const convertFataToObjectName = arr => {
      return arr.map(obj => ({ value: obj.id, label: obj.name }))
    }

    const formattedData = {
      context: convertFataToObjectName(context),
      managed_by: convertFataToObjectName(managed_by),
      ordinand: convertFataToObjectName(ordinand),
      program_awarding_bodies: convertFataToObjectName(program_awarding_bodies),
      session_years: convertFataToObjectName(session_years),
      status: convertFataToObjectLabel(status),
      fee_status: convertFataToObjectLabel(fee_status),
      pastoral_tutors: convertFataToObjectName(pastoral_tutors),
      diocese: convertFataToObjectLabel(diocese),
    }
    yield put(actions.setOptions({ name: 'dynamic', data: formattedData }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

function* getDynamicOptionsByStudent({ payload }) {
  const { studentId } = payload
  try {
    const { data } = yield call(API.studies.studentCourseSession.getDynamicOptionByStudent, studentId)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: obj.label }))

    const { accommodations, engagements } = data
    const formattedData = {
      accommodation: generateObject(accommodations),
      engagement_ids: generateObject(engagements),
    }
    yield put(actions.setOptions({ name: 'dynamicByStudent', data: formattedData }))
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.CREATE_STUDENT_COURSE_SESSION, create),
    takeEvery(types.GET_STUDENT_COURSE_SESSION, get),
    takeEvery(types.EDIT_STUDENT_COURSE_SESSION, edit),
    takeEvery(types.DELETE_STUDENT_COURSE_SESSION, remove),
    takeEvery(types.COPY_STUDENT_COURSE_SESSION, copy),
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
    takeEvery(types.GET_DYNAMIC_OPTIONS, getDynamicOptions),
    takeEvery(types.GET_DYNAMIC_OPTIONS_BY_STUDENT, getDynamicOptionsByStudent),
    feeRootSaga(),
    fundingAndMonitoringRootSaga(),
    noteRootSaga(),
    attachmentRootSaga(),
    postgradRootSaga(),
    venueActivityRootSaga(),
    referencePeriodRootSaga(),
    sessionStatusRootSaga(),
    studentFinancialSupportRootSaga(),
    studyLocationRootSaga(),
    fundingBodyRootSaga(),
    moduleInstancesRootSaga(),
    supervisorAllocationRootSaga(),
  ])
}
