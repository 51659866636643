import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as types from './fieldTooltipManager.types'
import { actions } from './fieldTooltipManager.actions'
import { API } from '@Root/API'
import { errorMessage } from '@Root/helpers'
import { fieldTooltipManagerActions, snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.tooltipManager.get, payload)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    yield call(API.tooltipManager.edit, payload)
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The helper messages have been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getContactDescriptions({ payload }) {
  yield put(actions.toggleLoading(true))
  const { entity_sub_type, entity, entity_type } = payload
  try {
    const { data } = yield call(API.tooltipManager.get, {
      entity: entity,
      entity_type: entity_sub_type === 'form' || entity_type === 'form' ? entity_type : entity_sub_type,
    })
    yield put(fieldTooltipManagerActions.setContactDescriptions({ entity: payload.entity_type, data: data.data, entity_type: entity_sub_type }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getProgrammeDescriptions({ payload }) {
  yield put(actions.toggleLoading(true))
  const { entity, entity_type } = payload
  try {
    const { data } = yield call(API.tooltipManager.get, payload)
    yield put(fieldTooltipManagerActions.setDescriptions({ entity, data: data.data, entity_type }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getHesaDescriptions() {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.tooltipManager.getHesa)
    yield put(fieldTooltipManagerActions.setContactDescriptions({ entity: 'person', data, entity_type: 'hesa_details' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getRolloverDescription({ payload }) {
  yield put(actions.toggleLoading(true))
  const { entity, entity_type } = payload
  try {
    const { data } = yield call(API.tooltipManager.get, payload)
    yield put(fieldTooltipManagerActions.setRolloverDescription({ entity, data: data.data, entity_type }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_MESSAGES, get),
    takeEvery(types.EDIT_MESSAGES, edit),
    takeEvery(types.GET_CONTACT_DESCRIPTIONS, getContactDescriptions),
    takeEvery(types.GET_DESCRIPTIONS, getProgrammeDescriptions),
    takeEvery(types.GET_HESA_DESCRIPTIONS, getHesaDescriptions),
    takeEvery(types.GET_ROLLOVER_DESCRIPTION, getRolloverDescription),
  ])
}
