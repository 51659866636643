export const GET_DATA_FOR_CREATE_CONTACT = 'options/GET_DATA_FOR_CREATE_CONTACT'
export const SET_DATA_FOR_CREATE_CONTACT = 'options/SET_DATA_FOR_CREATE_CONTACT'
export const GET_TITLES = 'options/GET_TITLES'
export const SET_TITLES = 'options/SET_TITLES'
export const GET_GENDERS = 'options/GET_GENDERS'
export const SET_GENDERS = 'options/SET_GENDERS'
export const GET_COUNTRIES = 'options/GET_COUNTRIES'
export const SET_COUNTRIES = 'options/SET_COUNTRIES'
export const GET_ACADEMIC_YEARS = 'options/GET_ACADEMIC_YEARS'
export const SET_ACADEMIC_YEARS = 'options/SET_ACADEMIC_YEARS'
export const SET_CONTACT_ADDRESS_TYPES = 'options/SET_CONTACT_ADDRESS_TYPES'
export const GET_INSTITUTIONS = 'options/GET_INSTITUTIONS'
export const SET_INSTITUTIONS = 'options/SET_INSTITUTIONS'
export const GET_USERS = 'options/GET_USERS'
export const SET_USERS = 'options/SET_USERS'
export const SET_ETHNICITIES = 'options/SET_ETHNICITIES'
export const GET_MARITAL_STATUS = 'options/GET_MARITAL_STATUS'
export const SET_MARITAL_STATUS = 'options/SET_MARITAL_STATUS'
export const SET_DISABILITY = 'options/SET_DISABILITY'
export const SET_NATIONALITY = 'options/SET_NATIONALITY'
//Studies
export const SET_HESA_REASON_FOR_STUDENT_COURSE_SESSION_ENDING = 'options/SET_HESA_REASON_FOR_STUDENT_COURSE_SESSION_ENDING'
export const GET_SPONSORS = 'options/GET_SPONSORS'
export const SET_SPONSORS = 'options/SET_SPONSORS'
export const GET_PROGRAMME_YEARS = 'options/GET_PROGRAMME_YEARS'
export const SET_PROGRAMME_YEARS = 'options/SET_PROGRAMME_YEARS'
export const SET_MODEL_OF_STUDY = 'options/SET_MODEL_OF_STUDY'
export const SET_LEARNING_CENTER = 'options/SET_LEARNING_CENTER'
export const GET_FINANCIAL_SUPPORT = 'options/GET_FINANCIAL_SUPPORT'
export const SET_FINANCIAL_SUPPORT = 'options/SET_FINANCIAL_SUPPORT'
export const GET_ELIGIBLE_SUPPORT = 'options/GET_ELIGIBLE_SUPPORT'
export const SET_ELIGIBLE_SUPPORT = 'options/SET_ELIGIBLE_SUPPORT'
export const SET_AUDITING = 'options/SET_AUDITING'
export const GET_ADMISSION_BAP_STATUSES = 'options/GET_ADMISSION_BAP_STATUSES'
export const SET_ADMISSION_BAP_STATUSES = 'options/SET_ADMISSION_BAP_STATUSES'
export const GET_ACCOMMODATION = 'options/GET_ACCOMMODATION'
export const SET_ACCOMMODATION = 'options/SET_ACCOMMODATION'
export const SET_SLC_LOAN = 'options/SET_SLC_LOAN'
export const GET_IN_HEAPES = 'options/GET_IN_HEAPES'
export const SET_IN_HEAPES = 'options/SET_IN_HEAPES'
export const SET_STUDY_YEARS = 'options/SET_STUDY_YEARS'
//Programme
export const SET_COURSE_DELIVERY_MODE_TYPE = 'options/SET_COURSE_DELIVERY_MODE_TYPE'
export const SET_COURSE_DELIVERY_TYPE = 'options/SET_COURSE_DELIVERY_TYPE'
export const GET_DESIGNED_COURSE = 'options/GET_DESIGNED_COURSE'
export const SET_DESIGNED_COURSE = 'options/SET_DESIGNED_COURSE'
export const GET_PROGRAMME_MODES = 'options/GET_PROGRAMME_MODES'
export const SET_PROGRAMME_MODES = 'options/SET_PROGRAMME_MODES'
export const GET_TEACHER_TRAINING_COURSE_IDENTIFIER = 'options/GET_TEACHER_TRAINING_COURSE_IDENTIFIER'
export const SET_TEACHER_TRAINING_COURSE_IDENTIFIER = 'options/SET_TEACHER_TRAINING_COURSE_IDENTIFIER'
export const SET_CONTACTS_STUDENT_TYPE_OPTIONS = 'options/SET_CONTACTS_STUDENT_TYPE_OPTIONS'
export const GET_FEE_STATUSES = 'options/GET_FEE_STATUSES'
export const SET_FEE_STATUSES = 'options/SET_FEE_STATUSES'
export const GET_STUDIES_SUSPENSIONS_OPTIONS = 'options/GET_STUDIES_SUSPENSIONS_OPTIONS'
export const SET_STUDIES_SUSPENSIONS_OPTIONS = 'options/SET_STUDIES_SUSPENSIONS_OPTIONS'
//Module
export const SET_CREDIT_TRANSFER_SCHEME = 'options/SET_CREDIT_TRANSFER_SCHEME'
export const GET_LEVEL_OF_CREDIT_POINTS = 'options/GET_LEVEL_OF_CREDIT_POINTS'
export const SET_LEVEL_OF_CREDIT_POINTS = 'options/SET_LEVEL_OF_CREDIT_POINTS'
export const GET_MARK_SCHEME = 'options/GET_MARK_SCHEME'
export const SET_MARK_SCHEME = 'options/SET_MARK_SCHEME'
//Postgrad
export const GET_TCB_RESPONSE = 'options/GET_TCB_RESPONSE'
export const SET_TCB_RESPONSE = 'options/SET_TCB_RESPONSE'
export const GET_UOB_RESPONSE = 'options/GET_UOB_RESPONSE'
export const SET_UOB_RESPONSE = 'options/SET_UOB_RESPONSE'
export const GET_STATUSES = 'options/GET_STATUSES'
export const SET_STATUSES = 'options/SET_STATUSES'
//Hesa
export const GET_HESA_DETAILS_OPTIONS = 'options/GET_HESA_DETAILS_OPTIONS'
export const SET_HESA_DETAILS_OPTIONS = 'options/SET_HESA_DETAILS_OPTIONS'
export const GET_HESA_DETAILS_COURSE_SESSION_OPTIONS = 'options/GET_HESA_DETAILS_COURSE_SESSION_OPTIONS'
export const SET_HESA_DETAILS_COURSE_SESSION_OPTIONS = 'options/SET_HESA_DETAILS_COURSE_SESSION_OPTIONS'
//Finance
export const GET_FINANCE_FUNDING_STATIC = 'options/GET_FINANCE_FUNDING_STATIC'
export const GET_FINANCE_FUNDING_DYNAMIC = 'options/GET_FINANCE_FUNDING_DYNAMIC'
export const SET_FINANCE_FUNDING = 'options/SET_FINANCE_FUNDING'
export const GET_FINANCE_TUITION_STATIC = 'options/GET_FINANCE_TUITION_STATIC'
export const GET_FINANCE_TUITION_DYNAMIC = 'options/GET_FINANCE_TUITION_DYNAMIC'
export const SET_FINANCE_TUITION = 'options/SET_FINANCE_TUITION'

//Rollover
export const GET_ROLLOVER = 'options/GET_ROLLOVER'
export const SET_ROLLOVER = 'options/SET_ROLLOVER'

export const GET_ROLES = 'options/GET_ROLES'
export const SET_ROLES = 'options/SET_ROLES'
