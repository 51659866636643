import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  engagementSelectors,
  modalActions,
  moduleActions,
  optionsActions,
  optionsSelectors,
  programmeActions,
  studentCourseSessionActions,
  programmeSelectors,
  snackbarActions,
  studentCourseSessionSelectors,
  instanceActions,
  instanceSelectors,
  moduleSelectors,
} from '@Root/store'
import { API } from '@Root/API'
import { errorMessage, getDataOptionsFromLocalStorage } from '@Root/helpers'
import { booleanOptions, createDataConfig, trashInformationMandatoryRules } from '@Root/configs'
import { ActionsDropdown, Table } from '@Root/components'
import { useToggle } from '@Root/hooks'
import { useHistory, useLocation } from 'react-router'
import { permissionsActions, permissionsSelectors } from '@Store/permissions'
import { usePermissions, useTrackProcessImport } from '@Hooks'

export const StudiesTable = ({ styleConfig }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const pathname = location.pathname
  const suspensionsOptions = useSelector(optionsSelectors.suspensions)
  const programmesList = useSelector(programmeSelectors.programmesListOptions)
  const tableOptions = useSelector(programmeSelectors.tableOptions)
  const programmeOptions = useSelector(programmeSelectors.options)
  const studentCourseSessionOptions = useSelector(studentCourseSessionSelectors.formattedOptions)
  const engagementOptions = useSelector(engagementSelectors.options)
  const moduleOptions = useSelector(moduleSelectors.options).static
  const moduleInstanceOptions = useSelector(instanceSelectors.options).static
  const additionalPermissions = useSelector(permissionsSelectors.additionalPermissions)
  const rulePermissions = useSelector(permissionsSelectors.rulePermissions)
  const [shouldBeUpdated, toggleShouldBeUpdated] = useToggle()
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const tab = pathname.split('/')[3]
  const isEmptyPermissions = usePermissions({ additionalPermissions, tab })
  const [confRollover, setConfRollover] = useState({ open: false, conf: {} })
  const { trackProcessImport, importError } = useTrackProcessImport()
  const [handlerWithTableParams, setHandlerWithTableParams] = useState(null)

  useEffect(() => {
    dispatch(programmeActions.getProgrammesPostgardOptions())
  }, [dispatch])

  useEffect(() => {
    if (shouldBeUpdated) refreshTable()
  }, [shouldBeUpdated])

  useEffect(() => {
    if (isEmptyPermissions) {
      dispatch(permissionsActions.getAdditional(tab))
    }
  }, [dispatch, tab, isEmptyPermissions])

  const getProgrammeOptions = useCallback(() => {
    dispatch(programmeActions.getStaticOptions())
    dispatch(programmeActions.getDynamicOptions())
  }, [dispatch])

  const getStudiesSuspensionsOptions = useCallback(() => {
    dispatch(optionsActions.getStudiesSuspensions())
  }, [dispatch])

  const onSuccess = message => {
    dispatch(snackbarActions.setSuccessNotification({ text: message }))
  }

  const onError = message => {
    dispatch(snackbarActions.setSnackbar({ text: message, isError: true }))
  }

  const refreshTable = () => {
    toggleShouldBeUpdated()
  }

  const longImportCSV = type => {
    const config = {
      moduleInstances: {
        checkImport: API.studies.studentCourseSession.moduleInstances.imports.check,
        name: 'Module Instances',
        validFields: ['id', 'module_instance_identifier'],
        importFile: API.studies.studentCourseSession.moduleInstances.imports.csv,
        trackProcess: API.studies.studentCourseSession.moduleInstances.imports.trackProcess,
        isRollBack: false,
      },
    }[type]
    let importData = null
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('UploadingModal', {
          clickRejectButtonHandler: () => dispatch(modalActions.hideModal()),
          clickResolveButtonHandler: file => {
            importData = file
            config
              .checkImport(file)
              .then(({ data }) => {
                dispatch(modalActions.hideModal())
                confirmationModalImport(data, config, importData)
              })
              .catch(error => importError(error))
          },
        })
      )
    })
  }

  const confirmationModalImport = (data, config, memoFile) => {
    const { create, update } = data
    dispatch(
      modalActions.showModal('ConfirmationModal', {
        text: `${update} ${config.name} will be overwritten. ${create} ${config.name} will be create.  Proceed?`,
        clickRejectButtonHandler: () => dispatch(modalActions.hideModal()),
        clickResolveButtonHandler: () =>
          config
            .importFile(memoFile)
            .then(({ data: { data } }) => {
              dispatch(modalActions.hideModal())
              trackProcessImport(data, config)
            })
            .catch(error => importError(error)),
      })
    )
  }

  const importCSV = useCallback(
    type => {
      const config = {
        programmes: {
          postCsvFileHandler: API.studies.programme.imports.csv,
          importDataPath: 'programs',
          invalidDataPath: 'invalid_programmes',
          invalidFields: ['programme', 'programme_type', 'programme_mode'],
          validFields: ['programme', 'programme_type', 'programme_mode'],
          importRollbackHandler: API.studies.programme.imports.rollback,
        },
        modules: {
          postCsvFileHandler: API.studies.module.imports.csv,
          importDataPath: 'modules',
          invalidDataPath: 'invalid_modules',
          invalidFields: ['module_id', 'error_msg'],
          validFields: ['module_title', 'credit_transfer_scheme', 'full_time_equivalence'],
          importRollbackHandler: API.studies.module.imports.rollback,
        },
      }[type]
      let importData = {}
      new Promise((resolve, reject) => {
        dispatch(
          modalActions.showModal('UploadingModal', {
            clickRejectButtonHandler: reject,
            clickResolveButtonHandler: resolve,
          })
        )
      })
        .then(
          file => {
            return config.postCsvFileHandler(file)
          },
          () => {
            dispatch(modalActions.hideModal())
            Promise.resolve()
          }
        )
        .then(
          ({ data }) => {
            importData = data.data
            return new Promise((resolve, reject) => {
              dispatch(
                modalActions.showModal('ImportModal', {
                  headerText: 'Results',
                  importDescription: importData[config.importDataPath].length ? `The following ${type} were importing:` : `No ${type} were imported.`,
                  importData: { data: importData[config.importDataPath], fields: config.validFields },
                  invalidImportDescription: importData[config.invalidDataPath]?.length ? '' : 'No import conflicts were detected.',
                  invalidImportData: {
                    data: importData[config.invalidDataPath] || [],
                    fields: config.invalidFields,
                  },
                  leftButtonText: importData[config.importDataPath].length ? 'Roll back' : null,
                  rightButtonText: 'Ok',
                  clickRejectButtonHandler: reject,
                  clickResolveButtonHandler: resolve,
                })
              )
            })
          },
          error => {
            new Promise((resolve, reject) => {
              dispatch(
                modalActions.showModal('NotificationModal', {
                  text: `Unable to process the data. Please check your template. Error message: ${errorMessage(error)}`,
                  clickRejectButtonHandler: reject,
                  clickResolveButtonHandler: resolve,
                })
              )
            }).then(
              () => {
                dispatch(modalActions.hideModal())
              },
              () => {
                dispatch(modalActions.hideModal())
              }
            )
          }
        )
        .then(
          button => {
            if (button === 'left') {
              new Promise((resolve, reject) => {
                dispatch(
                  modalActions.showModal('ImportModal', {
                    headerText: 'Warning',
                    importDescription: `The ${type} from the most recent import will be deleted. Click the delete button to delete the records:`,
                    importData: { data: importData[config.importDataPath], fields: config.validFields },
                    leftButtonText: 'Cancel Rollback',
                    rightButtonText: 'Delete',
                    clickRejectButtonHandler: reject,
                    clickResolveButtonHandler: resolve,
                  })
                )
              }).then(
                button => {
                  if (button === 'left') {
                    dispatch(modalActions.hideModal())
                    refreshTable()
                  } else if (button === 'right') {
                    config.importRollbackHandler({ rollback: importData.rollback }).then(
                      () => {
                        new Promise((resolve, reject) => {
                          dispatch(
                            modalActions.showModal('NotificationModal', {
                              text: 'Your records were deleted.',
                              clickRejectButtonHandler: reject,
                              clickResolveButtonHandler: resolve,
                            })
                          )
                        }).then(
                          () => {
                            dispatch(modalActions.hideModal())
                          },
                          () => {
                            dispatch(modalActions.hideModal())
                          }
                        )
                      },
                      error => {
                        onError(`Unable to roll back. Error message: ${errorMessage(error)}`)
                        dispatch(modalActions.hideModal())
                        refreshTable()
                      }
                    )
                  }
                },
                () => {
                  dispatch(modalActions.hideModal())
                  Promise.resolve()
                }
              )
            } else if (button === 'right') {
              dispatch(modalActions.hideModal())
              dispatch(
                snackbarActions.setSuccessNotification({
                  text: `${config.importDataPath} have been imported successfully!`,
                })
              )
              refreshTable()
            }
          },
          () => {
            dispatch(modalActions.hideModal())
            Promise.resolve()
          }
        )
    },
    [dispatch, onError, refreshTable]
  )

  const exportHandlers = format => {
    const handlers = {
      csv: { handler: API.contact.exports.byFormat, useCustomCollumns: true },
      xml: { handler: API.contact.exports.byFormat, useCustomCollumns: true },
      moodle_csv: { handler: API.contact.exports.moodle, useCustomCollumns: false },
    }
    return handlers[format]
  }
  const engagementsTableData = useCallback(() => {
    return {
      fetchInitialDataHandler: () => {
        const hasOptions = Object.keys(engagementOptions).length > 1
        if (!hasOptions) getDataOptionsFromLocalStorage(['engagements'])
      },
      table: () => {
        const {
          employing_schools = [],
          entryrte = [],
          fee_eligility_types = [],
          fee_statuses = [],
          incoming_exchanges = [],
          lead_schools = [],
          nhs_employers = [],
          qualified_teacher_status = [],
          research_council_students = [],
          study_intentions = [],
          outside_uk = [],
          include_in_hesa = [],
        } = engagementOptions

        const options = {
          employing_school: employing_schools.map(x => x.label),
          entryrte: entryrte.map(x => x.label),
          fee_eligibility: fee_eligility_types.map(x => x.label),
          fee_status: fee_statuses.map(x => x.label),
          incoming_exchange: incoming_exchanges.map(x => x.label),
          lead_school: lead_schools.map(x => x.label),
          nhs_employer: nhs_employers.map(x => x.label),
          qualified_teacher_status: qualified_teacher_status.map(x => x.label),
          research_council_student: research_council_students.map(x => x.label),
          study_intention: study_intentions.map(x => x.label),
          outside_uk: outside_uk.map(x => x.label),
          include_in_hesa: include_in_hesa.map(x => x.label),
        }

        return {
          ...createDataConfig('engagements', trashInformationMandatoryRules, [], 'getEngagements', 'engagements', true, true, true, false, false, false),
          fetchExportedDataHander: format => exportHandlers(format),
          options,
          exportTypeName: 'engagements',
          hasExportConfiguration: true,
          permissions: additionalPermissions
            ? {
              rule: rulePermissions?.engagements,
              hasExport:
                additionalPermissions?.engagements?.xml_export ||
                additionalPermissions?.engagements?.csv_export ||
                additionalPermissions?.engagements?.moodle_export,
              hasCSVExport: additionalPermissions?.engagements?.csv_export,
            }
            : null,
        }
      },
      actions: [
        ...(dataCreationPermissions.rollover_leavers
          ? [
            {
              name: 'Rollover leavers',
              handler: () => {
                setConfRollover({
                  open: true,
                  conf: {
                    name: 'records',
                    handler: params => API.studies.engagement.rollover.get(params),
                    headerText: 'Rollover leavers',
                    text: 'Do you want to proceed?',
                    key: 'leavers',
                    handelSubmit: params => API.studies.engagement.rollover.post(params),
                    handelTrackProcess: (data, config) => trackProcessImport(data, config),
                    handelError: error => importError(error),
                    invalid_fields: ['id', 'name', 'error'],
                    validFields: ['sid', 'name'],
                    isRollBack: false,
                    notification_succeed: 'Rollover leavers is successfully completed!',
                  },
                })
              },
            },
          ]
          : []),
        ...(dataCreationPermissions.rollover_students_continuing
          ? [
            {
              name: 'Rollover continuing students',
              handler: () => {
                setConfRollover({
                  open: true,
                  conf: {
                    name: 'records',
                    handler: params => API.studies.engagement.rollover.get(params),
                    headerText: 'Rollover Continuing students',
                    text: 'Do you want to proceed?',
                    key: 'continuing_students',
                    handelSubmit: params => API.studies.engagement.rollover.postContinuingStudents(params),
                    handelTrackProcess: (data, config) => trackProcessImport(data, config),
                    handelError: error => importError(error),
                    invalid_fields: ['id', 'name', 'error'],
                    validFields: ['id', 'name'],
                    isRollBack: false,
                    notification_succeed: 'Rollover Continuing students is successfully completed',
                    handlerWarningCheck: params => API.studies.engagement.rollover.checkStudents(params),
                  },
                })
              },
            },
          ]
          : []),

        ...(dataCreationPermissions.rollover_students_newly
          ? [
            {
              name: 'Rollover newly recruited students',
              handler: () => {
                setConfRollover({
                  open: true,
                  conf: {
                    name: 'records',
                    handler: params => API.studies.engagement.rollover.get(params),
                    headerText: 'Rollover newly recruited students',
                    text: 'Do you want to proceed?',
                    key: 'recruited_students',
                    handelSubmit: params => API.studies.engagement.rollover.postNewlyRecruitedStudents(params),
                    handelTrackProcess: (data, config) => trackProcessImport(data, config),
                    handelError: error => importError(error),
                    invalid_fields: ['id', 'name', 'error'],
                    validFields: ['id', 'name'],
                    isRollBack: false,
                    notification_succeed: 'Rollover for newly recruited students is successfully completed',
                  },
                })
              },
            },
          ]
          : []),
        ...(additionalPermissions?.engagements?.include_in_hesa
          ? [
            {
              name: 'Include in HESA',
              handler: () => {
                setHandlerWithTableParams({
                  handler: params => {
                    setHandlerWithTableParams(null)
                    API.studies.engagement.includeInHesa
                      .putIncludeInHesa({ ...params, include_in_hesa: true })
                      .then(({ data }) => {
                        dispatch(snackbarActions.setSuccessNotification({ text: data.data[0] }))
                        toggleShouldBeUpdated()
                      })
                  },
                })
              },
            },
          ]
          : []),
        ...(additionalPermissions?.engagements?.include_in_hesa
          ? [
            {
              name: 'Not include in HESA',
              handler: () => {
                setHandlerWithTableParams({
                  handler: params => {
                    setHandlerWithTableParams(null)
                    API.studies.engagement.includeInHesa
                      .putIncludeInHesa({ ...params, include_in_hesa: false })
                      .then(({ data }) => {
                        dispatch(snackbarActions.setSuccessNotification({ text: data.data[0] }))
                        toggleShouldBeUpdated()
                      })
                  },
                })
              },
            },
          ]
          : []),
      ],
    }
  }, [additionalPermissions, engagementOptions, rulePermissions?.engagements])

  const recordsOfStudiesTableData = useCallback(() => {
    return {
      fetchInitialDataHandler: () => {
        const hasOptions = Object.keys(studentCourseSessionOptions).length > 0
        if (!hasOptions) {
          dispatch(studentCourseSessionActions.getStaticOptions())
          dispatch(studentCourseSessionActions.getDynamicOptions())
        }
      },
      table: () => {
        const {
          status = [],
          managed_by = [],
          ordinand = [],
          session_years = [],
          program_awarding_bodies = [],
          study_years = [],
          sponsors = [],
          academic_years_list = [],
          accommodation = [],
          learning_centre = [],
          slc_loan = [],
          In_HEAPES = [],
          reason_for_student_course_session_ending = [],
          fee_methods = [],
          employer_fees = [],
          invoice_hesa_identifiers = [],
          pgr_language_identifiers = [],
          placements = [],
          preparatory_student_course_session_flags = [],
          student_course_session_modes = [],
          study_abroads = [],
          fee_status = [],
          program_years_list = [],
        } = studentCourseSessionOptions

        const options = {
          status: status.map(x => x.label),
          management_by: managed_by.map(x => x.label),
          ordinand: ordinand.map(x => x.label),
          session_years,
          program_awarding_bodies,
          study_year: study_years,
          academic_year: academic_years_list,
          sponsor: sponsors.map(x => x.label),
          accommodation: accommodation.map(x => x.label),
          training_mode: learning_centre.map(x => x.label),
          support: booleanOptions.map(x => x.label),
          SLC_this_year: slc_loan.map(x => x.label),
          heapes: In_HEAPES.map(x => x.label),
          session_year: session_years.map(x => x.label),
          awarding_body: program_awarding_bodies.map(x => x.label),
          reason_course_session: reason_for_student_course_session_ending.map(x => x.label),
          fee_method: fee_methods.map(x => x.label),
          employer_fee: employer_fees.map(x => x.label),
          auditing: ['Yes', 'No'],
          include_in_hesa: ['Yes', 'No'],
          invoice_hesa_identifier: invoice_hesa_identifiers.map(x => x.label),
          pgr_language_identifier: pgr_language_identifiers.map(x => x.label),
          placement: placements.map(x => x.label),
          preparatory_student_course_session_flag: preparatory_student_course_session_flags.map(x => x.label),
          student_course_session_mode: student_course_session_modes.map(x => x.label),
          study_abroad: study_abroads.map(x => x.label),
          fee_status: fee_status.map(x => x.label),
          program_years: program_years_list.map(x => x.label),
        }
        return {
          ...createDataConfig(
            'student_course_session',
            trashInformationMandatoryRules,
            [],
            '',
            'student_course_session',
            true,
            true,
            true,
            false,
            false,
            false
          ),
          fetchExportedDataHander: format => exportHandlers(format),
          fetchDataHandler: API.studies.studentCourseSession.getAll,
          options,
          exportTypeName: 'course-sessions',
          hasExportConfiguration: true,
          permissions: additionalPermissions
            ? {
              rule: rulePermissions?.student_course_session,
              hasExport:
                additionalPermissions?.student_course_session?.xml_export ||
                additionalPermissions?.student_course_session?.csv_export ||
                additionalPermissions?.student_course_session?.moodle_export,
              hasCSVExport: additionalPermissions?.student_course_session?.csv_export,
            }
            : null,
        }
      },
      actions: [
        ...(additionalPermissions?.student_course_session?.include_in_hesa
          ? [
            {
              name: 'Include in HESA',
              handler: () => {
                setHandlerWithTableParams({
                  handler: params => {
                    setHandlerWithTableParams(null)
                    API.studies.studentCourseSession.includeInHesa
                      .putIncludeInHesa({ ...params, include_in_hesa: true })
                      .then(({ data }) => {
                        dispatch(snackbarActions.setSuccessNotification({ text: data.data[0] }))
                        toggleShouldBeUpdated()
                      })
                  },
                })
              },
            },
          ]
          : []),
        ...(additionalPermissions?.student_course_session?.include_in_hesa
          ? [
            {
              name: 'Not include in HESA',
              handler: () => {
                setHandlerWithTableParams({
                  handler: params => {
                    setHandlerWithTableParams(null)
                    API.studies.studentCourseSession.includeInHesa
                      .putIncludeInHesa({ ...params, include_in_hesa: false })
                      .then(({ data }) => {
                        dispatch(snackbarActions.setSuccessNotification({ text: data.data[0] }))
                        toggleShouldBeUpdated()
                      })
                  },
                })
              },
            },
          ]
          : []),
      ],
    }
  }, [studentCourseSessionOptions, dispatch, additionalPermissions, rulePermissions?.student_course_session])

  const moduleInstanceTableData = useCallback(() => {
    return {
      fetchInitialDataHandler: () => {
        const hasOptions = Object.keys(moduleInstanceOptions).length > 0
        if (!hasOptions) {
          dispatch(instanceActions.getStaticOptions())
        }
      },
      table: () => {
        const {
          module_counts = [],
          module_outcomes = [],
          module_results = [],
          inactive_module_flags = [],
          accreditation_of_priors = [],
        } = moduleInstanceOptions
        const options = {
          module_count: module_counts.map(x => x.label),
          module_outcomes: module_outcomes.map(x => x.label),
          module_result: module_results.map(x => x.label),
          accreditation_of_prior: accreditation_of_priors.map(x => x.label),
          inactive_module_flag: inactive_module_flags.map(x => x.label),
        }
        return {
          ...createDataConfig('module-instances', [], [], 'getModuleInstances', 'module-instances', true, true, true, false, true, false),
          clickImportButtonHandler: () => longImportCSV('moduleInstances'),
          fetchExportedDataHander: format => exportHandlers(format),
          options,
          fetchDataHandler: API.studies.module.instance.getAll,
          exportTypeName: 'module-instance',
          hasExportConfiguration: true,
          permissions: additionalPermissions
            ? {
              //hasCSVImport: additionalPermissions?.modulesInstances?.csv_import,
              hasCSVImport: true,
              rule: rulePermissions?.['module-instances'],
              hasExport:
                additionalPermissions?.modulesInstances?.xml_export ||
                additionalPermissions?.modulesInstances?.csv_export ||
                additionalPermissions?.modulesInstances?.moodle_export,
              hasCSVExport: additionalPermissions?.modulesInstances?.csv_export,
            }
            : null,
        }
      },
    }
  }, [dispatch, importCSV, moduleInstanceOptions, additionalPermissions, rulePermissions])

  const programmesTableData = useCallback(() => {
    return {
      fetchInitialDataHandler: () => {
        const programmeParsedOptions = { ...programmeOptions.static, ...programmeOptions.dynamic }
        const hasOptions = Object.keys(programmeParsedOptions).length > 0
        if (!hasOptions) getProgrammeOptions()
      },
      table: () => {
        const programmeParsedOptions = { ...programmeOptions.static, ...programmeOptions.dynamic }
        const { program_types = [], program_awarding_bodies = [], programme_modes = [] } = programmeParsedOptions
        const options = {
          programme_type: program_types.map(x => x.label),
          programme_mode: programme_modes.map(x => x.label),
          available: booleanOptions.map(x => x.label),
          program_awarding_body: program_awarding_bodies.map(x => x.label),
        }
        return {
          ...createDataConfig(
            'programmes',
            trashInformationMandatoryRules,
            [],
            'getProgrammes',
            'programmes',
            true,
            true,
            true,
            false,
            additionalPermissions?.programmes?.csv_import,
            false
          ),
          clickImportButtonHandler: () => importCSV('programmes'),
          fetchExportedDataHander: format => exportHandlers(format),
          options,
          exportTypeName: 'program',
          hasExportConfiguration: true,
          permissions: additionalPermissions
            ? {
              hasCSVImport: additionalPermissions?.programmes?.csv_import,
              rule: rulePermissions?.programmes,
              hasExport:
                additionalPermissions?.programmes?.xml_export ||
                additionalPermissions?.programmes?.csv_export ||
                additionalPermissions?.programmes?.moodle_export,
              hasCSVExport: additionalPermissions?.programmes?.csv_export,
            }
            : null,
        }
      },
      actions: [
        ...(dataCreationPermissions.programmes
          ? [
            {
              name: 'Create a Programme',
              path: '/home/studies/programmes/new',
            },
          ]
          : []),
      ],
    }
  }, [
    additionalPermissions,
    dataCreationPermissions.programmes,
    getProgrammeOptions,
    history,
    importCSV,
    programmeOptions.dynamic,
    programmeOptions.static,
    rulePermissions?.programmes,
  ])

  const modulesTableData = useCallback(() => {
    return {
      fetchInitialDataHandler: () => {
        const hasOptions = Object.keys(moduleOptions).length > 0
        if (!hasOptions) {
          dispatch(moduleActions.getStaticOptions())
        }
      },
      table: () => {
        const { credit_transfer_scheme = [], level_of_credit_points = [] } = moduleOptions
        const options = {
          credit_transfer_scheme: credit_transfer_scheme.map(x => x.label),
          level_of_credit_points_id: level_of_credit_points.map(x => x.label),
        }
        return {
          ...createDataConfig('modules', [], [], 'getModules', 'modules', true, true, true, false, true, false),
          clickImportButtonHandler: () => importCSV('modules'),
          fetchExportedDataHander: format => exportHandlers(format),
          options,
          fetchDataHandler: API.studies.module.getAll,
          exportTypeName: 'education-module',
          hasExportConfiguration: true,
          permissions: additionalPermissions
            ? {
              hasCSVImport: additionalPermissions?.modules?.csv_import,
              rule: rulePermissions?.modules,
              hasExport:
                additionalPermissions?.modules?.xml_export || additionalPermissions?.modules?.csv_export || additionalPermissions?.modules?.moodle_export,
              hasCSVExport: additionalPermissions?.modules?.csv_export,
            }
            : null,
        }
      },
      actions: [
        ...(dataCreationPermissions.modules
          ? [
            {
              name: 'Create a Module',
              path: '/home/studies/modules/new',
            },
          ]
          : []),
      ],
    }
  }, [additionalPermissions, dataCreationPermissions.modules, dispatch, history, importCSV, moduleOptions, rulePermissions?.modules])

  const postgradStudentDetailsTableData = useCallback(() => {
    return {
      fetchInitialDataHandler: () => {
        const hasOptions = Object.keys(programmeOptions).length > 0
        if (!hasOptions) {
          if (!programmesList || programmesList?.length <= 0) {
            dispatch(programmeActions.getProgrammesOptions())
          }
          getProgrammeOptions()
        } else {
          if (!programmesList || programmesList?.length <= 0) {
            dispatch(programmeActions.getProgrammesOptions())
          }
        }
      },
      table: () => {
        const options = {
          program_name: programmesList?.map(x => x.label),
          mode: tableOptions.programme_modes.map(x => x.label),
          status_program: tableOptions.reason_course_session_ending.map(x => x.label),
          academic_year: tableOptions.academic_years_list,
          managed_by: tableOptions.managed_by.map(x => x.label),
          status: tableOptions.status.map(x => x.label),
        }
        return {
          ...createDataConfig(
            'postgrad-student-details',
            trashInformationMandatoryRules,
            [],
            'getPostGrads',
            'postgrad-student-details',
            true,
            true,
            true,
            false,
            false,
            false
          ),
          fetchExportedDataHander: format => exportHandlers(format),
          options,
          exportTypeName: 'post-gard',
          hasExportConfiguration: true,
          permissions: additionalPermissions
            ? {
              rule: rulePermissions?.['postgrad-student-details'],
              hasExport:
                additionalPermissions?.postgradStudentDetails?.xml_export ||
                additionalPermissions?.postgradStudentDetails?.csv_export ||
                additionalPermissions?.postgradStudentDetails?.moodle_export,
              hasCSVExport: additionalPermissions?.postgradStudentDetails?.csv_export,
            }
            : null,
        }
      },
    }
  }, [
    additionalPermissions,
    dispatch,
    getProgrammeOptions,
    programmeOptions,
    programmesList,
    rulePermissions,
    tableOptions.academic_years_list,
    tableOptions.managed_by,
    tableOptions.programme_modes,
    tableOptions.reason_course_session_ending,
    tableOptions.status,
  ])

  const suspensionsStudentDetailsTableData = useCallback(() => {
    return {
      fetchInitialDataHandler: () => {
        const hasOptions = Object.keys(suspensionsOptions).length > 0
        if (!hasOptions) {
          getStudiesSuspensionsOptions()
        }
      },
      table: () => {
        const { institutions = [], reason_for_student_course_session_ending = [], academic_years_list = [] } = suspensionsOptions
        const options = {
          institution: institutions.map(x => x.label),
          academic_year: academic_years_list,
          status_program: reason_for_student_course_session_ending.map(x => x.label),
        }
        return {
          ...createDataConfig('suspensions', [], [], 'getSuspensions', 'suspensions', true, true, true, false, false, false),
          options,
          fetchExportedDataHander: format => exportHandlers(format),
          exportTypeName: 'suspension',
          hasExportConfiguration: true,
          permissions: additionalPermissions
            ? {
              rule: rulePermissions.suspensions,
              hasExport:
                additionalPermissions?.nonActive?.xml_export ||
                additionalPermissions?.nonActive?.csv_export ||
                additionalPermissions?.nonActive?.moodle_export,
              hasCSVExport: additionalPermissions?.nonActive?.csv_export,
            }
            : null,
        }
      },
    }
  }, [suspensionsOptions, getStudiesSuspensionsOptions, additionalPermissions, rulePermissions.suspensions])

  const tableData = useCallback(
    tab => {
      switch (tab) {
        case 'engagements':
          return engagementsTableData()
        case 'records-of-studies':
          return recordsOfStudiesTableData()
        case 'module-instances':
          return moduleInstanceTableData()
        case 'programmes':
          return programmesTableData()
        case 'modules':
          return modulesTableData()
        case 'postgrad-student-details':
          return postgradStudentDetailsTableData()
        case 'non-active':
          return suspensionsStudentDetailsTableData()
        default:
          return
      }
    },
    [
      engagementsTableData,
      moduleInstanceTableData,
      modulesTableData,
      postgradStudentDetailsTableData,
      programmesTableData,
      recordsOfStudiesTableData,
      suspensionsStudentDetailsTableData,
    ]
  )

  const pushProgramme = async () => {
    try {
      await API.studies.programme.pushToApplicationForm()
      onSuccess('Programmes pushed successfully')
    } catch (error) {
      onError(errorMessage(error))
    }
  }

  const { table, fetchInitialDataHandler, actions = [] } = useMemo(() => {
    const data = tableData(tab)
    return {
      ...data,
      table: data.table(),
    }
  }, [tab, tableData])

  useEffect(() => {
    fetchInitialDataHandler()
  }, [fetchInitialDataHandler])

  const onChooseAction = actionName => {
    actions.find(action => action.name === actionName).handler()
  }

  const isProgrammesTab = tab === 'programmes'
  return (
    <div className={classes.wrapper}>
      {!!actions.length && <ActionsDropdown actions={actions} style={styleConfig.actionsDropdown} clickHandler={onChooseAction} />}
      {isProgrammesTab && additionalPermissions?.programmes?.push_to_application && (
        <div style={{ ...styleConfig.tablePushBar }}>
          <div className={classes.tablePushBar}>
            <div className={classes.tableBarLeft}>
              <p>
                Data must be pushed into the online application from each time a programme is created or its availiability is changed. To renew all data push
                this button please
              </p>
            </div>
            <div className={classes.tableBarRight}>
              <p>All programmes with a study finish date greater than today</p>
              <div onClick={() => pushProgramme()} className={classes.pushBtn}>
                Push all Programmes to application form
              </div>
            </div>
          </div>
        </div>
      )}
      <Table
        datePeriodDropdownStyle={true && isProgrammesTab ? { top: 62 } : null}
        cleanFiltersStyle={true && isProgrammesTab ? { top: 170 } : null}
        datePeriodTop={62}
        key={table.name}
        name={table.name}
        mandatoryRules={table.mandatoryRules}
        fetchDataHandler={table.fetchDataHandler}
        fetchColumnOptions={table.fetchColumnOptions}
        fetchSaveColumnOptions={table.fetchSaveColumnOptions}
        hasImport={table.hasImport}
        hasSorting={table.hasSorting}
        hasFilters={table.hasFilters}
        clickImportButtonHandler={table.clickImportButtonHandler}
        hasRules={table.hasRules}
        errorHandler={error => onError(errorMessage(error))}
        successHandler={onSuccess}
        shouldBeUpdated={shouldBeUpdated}
        styleConfig={{
          ...styleConfig.table,
          upperPaginationContainerStyles: { marginTop: isProgrammesTab ? 315 : 250 },
          tableBar: styleConfig.tableBar,
          tableData: isProgrammesTab ? styleConfig.table.tableData : null,
          tablePositions: isProgrammesTab ? styleConfig.table.tablePositions : null,
        }}
        options={table.options}
        permissions={table.permissions}
        hasExportConfiguration={table.hasExportConfiguration}
        exportTypeName={table.exportTypeName}
        fetchExportedDataHander={table.fetchExportedDataHander}
        defaultExportFields={table.defaultExportFields}
        confRollover={confRollover}
        setConfRollover={setConfRollover}
        handlerWithTableParams={handlerWithTableParams?.handler || null}
      />
    </div>
  )
}
