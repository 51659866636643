import React, { useEffect, useRef } from 'react'
import classes from './style.module.scss'
import { getDataOptionsFromLocalStorage } from '@Root/helpers'
import { EditButton, SectionTitle } from '@Root/components'
import { Subform } from '../Subform'
import { useDispatch, useSelector } from 'react-redux'
import { contactSelectors, fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions, snackbarActions } from '@Root/store'
import { hesaActions, hesaSelectors } from '@Store/contact/person'
import { useToggle } from '@Root/hooks'
import { useEditedMode } from '@Root/hooks'

export const ContactsHesaDetailForm = ({ contactId }) => {
  const dispatch = useDispatch()
  const [isEditable, setIsEditable] = useToggle(false)
  const staticOptions = useSelector(hesaSelectors.options).static
  const dynamicOptions = useSelector(hesaSelectors.options).dynamic
  const permissions = useSelector(hesaSelectors.permissions)
  const data = useSelector(hesaSelectors.hesa)
  const hasStudentCourseSession = useSelector(contactSelectors.hasStudentCourseSession)
  const snapshots = useSelector(hesaSelectors.snapshots)
  const isSuccess = useSelector(hesaSelectors.isSuccess)
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.hesa_details
  const editButtonRef = useRef(null)
  const idForm = `hesa_${contactId}`

  const { startEditMode, exitEditMode } = useEditedMode({
    isEditable,
    clickToggleEditModeButtonHandler: setIsEditable,
    clickCancelButtonHandler: setIsEditable,
    idForm,
  })

  // useEffect(() => {
  //   if (isSuccess) exitEditMode()
  // }, [isSuccess])

  useEffect(() => {
    return () => {
      if (idForm) exitEditMode()
    }
  }, [idForm])

  useEffect(() => {
    dispatch(fieldTooltipManagerActions.getHesaDescriptions())
  }, [dispatch])

  useEffect(() => {
    if (contactId) {
      dispatch(hesaActions.get({ contactId }))
      dispatch(hesaActions.getSnapshots({ contactId }))
      dispatch(hesaActions.getDynamicOptions({ contactId }))
    }
  }, [contactId, dispatch])

  const onOpenDescription = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  const saveStudentForm = (initialPayload, formName, modifiedData) => {
    const payload = {
      data: {
        disabilities: data.disability,
        national_identities: data.nationalIdentity,
        language_proficiencies: data.languageProficity,
        ...modifiedData,
        ...modifiedData.unusedStudentFields[0],
        id: data.student.id,
      },
      ...initialPayload,
    }
    delete payload.data.unusedStudentFields
    dispatch(hesaActions.edit(payload))
  }

  const saveCourseSessionForm = (initialPayload, modifiedData) => {
    const payload = {
      data: {
        studentCourseSessions: modifiedData,
      },
      ...initialPayload,
      contactId,
    }
    dispatch(hesaActions.editCourseSession(payload))
  }

  const saveModuleInstanceForm = (initialPayload, modifiedData) => {
    const payload = {
      data: {
        moduleInstance: modifiedData,
      },
      ...initialPayload,
      contactId,
    }
    dispatch(hesaActions.editModuleInstance(payload))
  }

  const saveHesaDetailForm = (formName, data, callback) => {
    const modifiedData = formName === 'student' ? data[0] : data
    return new Promise((resolve, reject) => {
      callback()
      const initialPayload = {
        formName,
        contactId,
        resolve,
        reject,
      }
      if (formName === 'student') {
        saveStudentForm(initialPayload, formName, modifiedData)
      } else if (formName === 'student_course_session') {
        saveCourseSessionForm(initialPayload, modifiedData)
      } else if (formName === 'module_instance') {
        saveModuleInstanceForm(initialPayload, modifiedData)
      }
    })
      .then(() => {
        exitEditMode('cancel')
        return true
      })
      .catch(() => {
        return false
      })
  }

  const onOpenDeleteModal = (formName, data, callback, onToggleLoading) => {
    const titles = {
      student_course_session: 'Student Course Session',
      module_instance: 'Module Instance',
    }
    return new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: `The ${titles[formName]} will be removed?`,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        onToggleLoading()
        dispatch(modalActions.hideModal())
        if (formName === 'student_course_session' || formName === 'module_instance')
          await saveHesaDetailForm(formName, { student_course_session_id: data.student_course_session_id }, callback)
        return true
      })
      .catch(() => {
        dispatch(modalActions.hideModal())
        return false
      })
  }

  const onShowFieldError = error => {
    dispatch(snackbarActions.setSnackbar({ text: error, isError: true, delay: 15000 }))
  }

  useEffect(() => {
    getDataOptionsFromLocalStorage(['hesa_details_options'])
  }, [dispatch])

  const getSnapshotInformation = id => {
    if (id === data?.id) {
      dispatch(hesaActions.get({ contactId }))
      return
    }
    dispatch(hesaActions.getSnapshot({ id }))
  }

  const { fields, form } = permissions

  const subforms = [
    {
      name: 'student_course_session',
      initialData: data.student_course_session,
      groupsConfigs: {
        level0GroupConfig: {
          title: 'Student course session',
          field: 'student_course_session',
          quantity: {
            min: 0,
            max: hasStudentCourseSession ? Infinity : 0,
          },
          canBeSaved: false,
          canBeSavedPart: true,
          inputs: [
            {
              name: 'Student course session identifier',
              field: 'student_course_session_id',
              type: 'select',
              options: dynamicOptions.studentCourseSessions,
              validations: ['required', 'unique', 'disabled'],
              description: descriptions?.student_course_sessions?.student_course_session_id,
            },
          ],
        },
        level1GroupsConfigs: [
          {
            title: 'Funding and monitoring',
            field: 'funding_and_monitoring',
            quantity: {
              min: 0,
              max: 1,
            },
            inputs: [
              {
                name: 'Equivalent or lower qualification',
                field: 'equivalent_qualification_id',
                type: 'select',
                validations: [...(!fields?.course_session_monitoring?.equivalent_qualification_id?.edit ? ['disabled'] : [])],
                options: staticOptions?.studentCourseSessions?.equivalentLowerQualifications,
                maxLength: 2,
                description: descriptions?.student_course_sessions?.equivalent_qualification_id,
              },
              {
                name: 'Funding completion',
                field: 'funding_completion_id',
                type: 'select',
                validations: [...(!fields?.course_session_monitoring?.funding_completion_id?.edit ? ['disabled'] : [])],
                options: staticOptions.studentCourseSessions.fundingCompletions,
                maxLength: 2,
                description: descriptions?.student_course_sessions?.funding_completion_id,
              },
              {
                name: 'Funding length',
                field: 'funding_length_id',
                type: 'select',
                validations: [...(!fields?.course_session_monitoring?.funding_length_id?.edit ? ['disabled'] : [])],
                options: staticOptions?.studentCourseSessions?.fundingLengths,
                maxLength: 2,
                description: descriptions?.student_course_sessions?.funding_length_id,
              },
              {
                name: 'Non regulated fee flag',
                field: 'non_regulated_fee_flag_id',
                type: 'select',
                validations: [...(!fields?.course_session_monitoring?.non_regulated_fee_flag_id?.edit ? ['disabled'] : [])],
                options: staticOptions?.studentCourseSessions?.nonRegulatedFeeFlags,
                maxLength: 2,
                description: descriptions?.student_course_sessions?.non_regulated_fee_flag_id,
              },
            ],
          },
          {
            title: 'Funding body',
            field: 'funding_body',
            quantity: {
              min: 0,
              max: 1,
            },
            canBeSaved: true,
            inputs: [
              {
                name: 'Funding body',
                field: 'body_id',
                type: 'select',
                validations: ['required', ...(!fields?.course_session_body?.body_id?.edit ? ['disabled'] : [])],
                options: staticOptions.studentCourseSessions.fundingBodies,
                maxLength: 4,
                description: descriptions?.student_course_sessions?.body_id,
              },
            ],
          },
          {
            title: 'Off venue activity',
            field: 'off_venue_activity',
            quantity: {
              min: 0,
              max: Infinity,
            },
            canBeSaved: true,
            inputs: [
              {
                name: 'Off venue activity identifier',
                field: 'venue_activity_identifier',
                type: 'textInput',
                validations: ['required', ...(!fields?.course_session_venue?.venue_activity_identifier?.edit ? ['disabled'] : [])],
                maxLength: 50,
                description: descriptions?.student_course_sessions?.venue_activity_identifier,
              },
              {
                name: 'Activity duration amount',
                field: 'duration_amount',
                type: 'textInput',
                validations: ['required', ...(!fields?.course_session_venue?.duration_amount?.edit ? ['disabled'] : [])],
                maxLength: 3,
                description: descriptions?.student_course_sessions?.duration_amount,
              },
              {
                name: 'Activity duration type',
                field: 'duration_type_id',
                type: 'select',
                options: staticOptions?.studentCourseSessions?.activityDurationTypes,
                validations: ['required', ...(!fields?.course_session_venue?.duration_type_id?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.student_course_sessions?.duration_type_id,
              },
              {
                name: 'Activity end date',
                field: 'activity_end_date',
                type: 'datePicker',
                validations: ['dataValid', ...(!fields?.course_session_venue?.activity_end_date?.edit ? ['disabled'] : [])],
                maxLength: 10,
                description: descriptions?.student_course_sessions?.activity_end_date,
              },
              {
                name: 'Activity start date',
                field: 'activity_start_date',
                type: 'datePicker',
                validations: ['dataValid', ...(!fields?.course_session_venue?.activity_start_date?.edit ? ['disabled'] : [])],
                maxLength: 10,
                description: descriptions?.student_course_sessions?.activity_start_date,
              },
              {
                name: 'Activity type identifier',
                field: 'activity_type_id',
                type: 'select',
                options: staticOptions?.studentCourseSessions?.activityTypeIdentifiers,
                validations: ['required', ...(!fields?.course_session_venue?.activity_type_id?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.student_course_sessions?.activity_type_id,
              },
              {
                name: 'Country',
                field: 'activity_country_id',
                type: 'select',
                options: staticOptions?.studentCourseSessions?.countries,
                validations: ['required', ...(!fields?.course_session_venue?.activity_country_id?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.student_course_sessions?.activity_country_id,
              },
              {
                name: 'Host identifier',
                field: 'host_identifier',
                type: 'select',
                options: staticOptions?.studentCourseSessions?.hostIdentifiers,
                validations: ['required', ...(!fields?.course_session_venue?.host_identifier?.edit ? ['disabled'] : [])],
                maxLength: 8,
                description: descriptions?.student_course_sessions?.host_identifier,
                custom_component: true,
              },
              {
                name: 'Host identifier type',
                field: 'host_type_id',
                type: 'select',
                options: staticOptions?.studentCourseSessions?.hostIdentifierTypes,
                validations: [...(!fields?.course_session_venue?.host_type_id?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.student_course_sessions?.host_type_id,
              },
              {
                name: 'Mobility scheme',
                field: 'mobility_scheme_id',
                type: 'select',
                options: staticOptions?.studentCourseSessions?.mobilitySchemes,
                validations: ['required', ...(!fields?.course_session_venue?.mobility_scheme_id?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.student_course_sessions?.mobility_scheme_id,
              },
              {
                name: 'Module instance identifier',
                field: 'module_instance_id',
                type: 'select',
                options: dynamicOptions.moduleInstances,
                options_dynamic_field: 'module_instances',
                validations: [...(!fields?.course_session_venue?.module_instance_id?.edit ? ['disabled'] : [])],
                maxLength: 50,
                description: descriptions?.student_course_sessions?.module_instance_id,
              },
            ],
          },
          {
            title: 'Reference period student load',
            field: 'reference_period_student_load',
            quantity: {
              min: 0,
              max: 4,
            },
            canBeSaved: true,
            inputs: [
              {
                name: 'Reference period',
                field: 'reference_period_id',
                type: 'select',
                options: staticOptions.studentCourseSessions.referencePeriods,
                validations: ['required', ...(!fields?.course_session_reference?.reference_period_id?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.student_course_sessions?.reference_period_id,
              },
              {
                name: 'Year',
                field: 'year',
                type: 'textInput',
                validations: ['required', ...(!fields?.course_session_reference?.year?.edit ? ['disabled'] : [])],
                maxLength: 4,
                description: descriptions?.student_course_sessions?.year,
              },
              {
                name: 'RP Student load',
                field: 'rp_student_load',
                type: 'textInput',
                validations: ['required', 'percentage', ...(!fields?.course_session_reference?.rp_student_load?.edit ? ['disabled'] : [])],
                maxLength: 4,
                description: descriptions?.student_course_sessions?.rp_student_load,
              },
            ],
          },
          {
            title: 'Session status',
            field: 'session_status',
            quantity: {
              min: 0,
              max: Infinity,
            },
            canBeSaved: true,
            inputs: [
              {
                name: 'Status valid from',
                field: 'status_valid_from',
                type: 'datePicker',
                validations: ['required', 'dataValid', ...(!fields?.course_session_session_status?.status_valid_from?.edit ? ['disabled'] : [])],
                maxLength: 10,
                description: descriptions?.student_course_sessions?.status_valid_from,
              },
              {
                name: 'Status changed to',
                field: 'status_change_id',
                type: 'select',
                options: staticOptions.studentCourseSessions.statusChangedTo,
                validations: ['required', ...(!fields?.course_session_session_status?.status_change_id?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.student_course_sessions?.status_change_id,
              },
            ],
          },
          {
            title: 'Student financial support',
            field: 'student_financial_support',
            quantity: {
              min: 0,
              max: Infinity,
            },
            canBeSaved: true,
            inputs: [
              {
                name: 'Financial support type',
                field: 'financial_support_type_id',
                type: 'select',
                options: staticOptions.studentCourseSessions.financialSupportTypes,
                validations: ['required', ...(!fields?.course_session_support?.financial_support_type_id?.edit ? ['disabled'] : [])],
                maxLength: 3,
                description: descriptions?.student_course_sessions?.financial_support_type_id,
              },
              {
                name: 'Access and participation flag',
                field: 'access_participation_flag_id',
                type: 'select',
                options: staticOptions.studentCourseSessions.accessParticipationFlags,
                validations: [...(!fields?.course_session_support?.access_participation_flag_id?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.student_course_sessions?.access_participation_flag_id,
              },
              {
                name: 'Financial support amount',
                field: 'financial_support_amount',
                type: 'textInput',
                validations: [...(!fields?.course_session_support?.financial_support_amount?.edit ? ['disabled'] : [])],
                maxLength: 6,
                description: descriptions?.student_course_sessions?.financial_support_amount,
              },
            ],
          },
          {
            title: 'Study location',
            field: 'study_location',
            quantity: {
              min: 0,
              max: Infinity,
            },
            canBeSaved: true,
            inputs: [
              {
                name: 'Study location identifier',
                field: 'study_location_identifier',
                type: 'textInput',
                validations: ['required', ...(!fields?.course_session_location?.study_location_identifier?.edit ? ['disabled'] : [])],
                maxLength: 50,
                description: descriptions?.student_course_sessions?.study_location_identifier,
              },
              {
                name: 'Distance learning',
                field: 'session_distance_learning_id',
                type: 'select',
                options: staticOptions.studentCourseSessions.distanceLearning,
                validations: [...(!fields?.course_session_location?.session_distance_learning_id?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.student_course_sessions?.session_distance_learning_id,
              },
              {
                name: 'Study proportion',
                field: 'study_proportion',
                type: 'textInput',
                validations: ['required', 'percentage', ...(!fields?.course_session_location?.study_proportion?.edit ? ['disabled'] : [])],
                maxLength: 4,
                description: descriptions?.student_course_sessions?.study_proportion,
              },
              {
                name: 'Venue identifier',
                field: 'venue_id',
                type: 'select',
                options: dynamicOptions.offVenues,
                validations: [...(!fields?.course_session_location?.venue_id?.edit ? ['disabled'] : [])],
                maxLength: 50,
                description: descriptions?.student_course_sessions?.venue_id,
              },
            ],
          },
          {
            title: 'Supervisor allocation',
            field: 'supervisor_allocation',
            quantity: {
              min: 0,
              max: Infinity,
            },
            canBeSaved: true,
            inputs: [
              {
                name: 'Supervisor allocation identifier',
                field: 'supervisor_identifier',
                type: 'textInput',
                validations: ['required', ...(!fields?.course_session_allocation?.supervisor_identifier?.edit ? ['disabled'] : [])],
                maxLength: 50,
                description: descriptions?.student_course_sessions?.supervisor_identifier,
              },
              {
                name: 'HESA identifier',
                field: 'hesa_identifier',
                type: 'select',
                options: staticOptions.studentCourseSessions.hesaIdentifiers,
                validations: [...(!fields?.course_session_allocation?.supervisor_hesa_id?.edit ? ['disabled'] : [])],
                maxLength: 8,
                description: descriptions?.student_course_sessions?.supervisor_hesa_id,
                custom_component: true,
              },
              {
                name: 'REF2021 unit of assessment',
                field: 'ref2021_unit_of_assessment_id',
                type: 'select',
                options: staticOptions.studentCourseSessions.ref2021UnitAssessments,
                validations: [...(!fields?.course_session_allocation?.ref2021_unit_of_assessment_id?.edit ? ['disabled'] : [])],
                maxLength: 3,
                description: descriptions?.student_course_sessions?.ref2021_unit_of_assessment_id,
              },
              {
                name: 'Supervisor allocation proportion',
                field: 'proportion',
                type: 'textInput',
                validations: ['required', 'percentage', ...(!fields?.course_session_allocation?.proportion?.edit ? ['disabled'] : [])],
                maxLength: 4,
                description: descriptions?.student_course_sessions?.proportion,
              },
            ],
          },
        ],
      },
    },
    {
      name: 'engagements',
      initialData: data.engagements,
      groupsConfigs: {
        level0GroupConfig: {
          title: 'Engagement',
          field: 'engagement',
          quantity: {
            min: 0,
            max: Infinity,
          },
          canBeSaved: false,
          canBeSavedPart: false,
          isOnlyForView: true,
          inputs: [
            {
              name: 'Engagement number',
              field: 'number',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.engagement_number,
            },
            {
              name: 'School direct employing school',
              field: 'employing_school',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.employing_school_id,
            },
            {
              name: 'Start date',
              field: 'start_date',
              type: 'datePicker',
              validations: [],
              description: descriptions?.engagements?.start_date,
            },
            {
              name: 'ITT entry route',
              field: 'entryrte',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.entryrte_id,
            },
            {
              name: 'Expected end date',
              field: 'expected_end_date',
              type: 'datePicker',
              validations: [],
              description: descriptions?.engagements?.expected_end_date,
            },
            {
              name: 'Fee eligibility',
              field: 'fee_eligibility',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.fee_eligibility_id,
            },
            {
              name: 'Fee status',
              field: 'fee_status',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.fee_status_id,
            },
            {
              name: 'Incoming exchange',
              field: 'incoming_exchange',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.incoming_exchange_id,
            },
            {
              name: 'Lead school',
              field: 'lead_school',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.lead_school_id,
            },
            {
              name: 'NHS employer',
              field: 'nhs_employer',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.nhs_employer_id,
            },
            {
              name: 'Own Engagement identifier',
              field: 'own_identifier',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.own_identifier,
            },
            {
              name: 'Qualified teacher status',
              field: 'qualified_teacher_status',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.qualified_teacher_status_id,
            },
            {
              name: 'Research council student',
              field: 'research_council_student',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.research_council_student_id,
            },
            {
              name: 'Research council student identifier',
              field: 'research_council_student_identifier',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.research_council_student_identifier,
            },
            {
              name: 'Study intention',
              field: 'study_intention',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.study_intention_id,
            },
            {
              name: 'Primarily outside the UK',
              field: 'outside_uk',
              type: 'textInput',
              validations: [],
              description: descriptions?.engagements?.outside_uk,
            },
            {
              name: 'Do not include in hesa',
              field: 'include_in_hesa',
              type: 'checkbox',
              validations: [],
              description: descriptions?.engagements?.include_in_hesa,
            },
          ],
        },
        level1GroupsConfigs: [
          {
            title: 'Collaborative provision',
            field: 'collaborative_provision',
            quantity: {
              min: 0,
              max: Infinity,
            },
            inputs: [
              {
                name: 'Collaborative provision',
                field: 'colprovtypeid',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.colprovtypeid_id,
              },
              {
                name: 'Partner NUMHUS',
                field: 'partnernumhus',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.partnernumhus,
              },
              {
                name: 'Partner SID',
                field: 'partnersid',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.partnersid,
              },
              {
                name: 'Partner UKPRN',
                field: 'partnerukprn',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.partnerukprn,
              },
            ],
          },
          {
            title: 'Entry Profile',
            field: 'student_registration',
            quantity: {
              min: 0,
              max: Infinity,
            },
            inputs: [
              {
                name: 'Access programme',
                field: 'access_programme',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.access_programme_id,
              },
              {
                name: 'APEL credits',
                field: 'apel_credits',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.apel_credit_id,
              },
              {
                name: 'Care leaver',
                field: 'care_leaver',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.care_leaver_id,
              },
              {
                name: 'Credits points counted towards study',
                field: 'crdptstu',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.crdptstu,
              },
              {
                name: 'Credit scheme',
                field: 'credit_scheme',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.credit_scheme_id,
              },
              {
                name: 'Dependant',
                field: 'dependant',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.dependant_id,
              },
              {
                name: 'Estranged student',
                field: 'estranged_student',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.estranged_student_id,
              },
              {
                name: 'Highest qualification on entry',
                field: 'highestqualoe',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.highestqualoe_id,
              },
              {
                name: 'Marital status',
                field: 'marital_status',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.marital_status_id,
              },
              {
                name: 'Parental education',
                field: 'parental_education',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.parental_education_id,
              },
              {
                name: 'Country of permanent address',
                field: 'permanent_address',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.permanent_address_id,
              },
              {
                name: 'Postcode of permanent home address',
                field: 'postcode_permanent_address',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.postcode_permanent_address,
              },
              {
                name: 'Previous provider',
                field: 'previous_provider',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.previous_provider,
              },
              {
                name: 'Religious background',
                field: 'religious_background',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.religious_background_id,
              },
              {
                name: 'Socio economic classification',
                field: 'socio_economic_classification',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.socio_economic_classification_id,
              },
              {
                name: 'Standard occupational classification 2010',
                field: 'standard_occupational',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.standard_occupational_id,
              },
              {
                name: 'UCAS scheme code',
                field: 'ucas_scheme_code',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.ucas_scheme_code,
              },
              {
                name: 'Year left last provider',
                field: 'year_left_last_provider',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.year_left_last_provider,
              },
            ],
          },
          {
            title: 'Leaver',
            field: 'leaver',
            quantity: {
              min: 0,
              max: Infinity,
            },
            inputs: [
              {
                name: 'Engagement end date',
                field: 'engagement_end_date',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.engagement_end_date,
              },
              {
                name: 'Intended destination',
                field: 'intended_destination',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.intended_destination,
              },
              {
                name: 'Reason for Engagement ending',
                field: 'ending_reason',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.ending_reason_id,
              },
            ],
          },
          {
            title: 'Qualification awarded',
            field: 'qualification_awarded',
            quantity: {
              min: 0,
              max: Infinity,
            },
            inputs: [
              {
                name: 'Qualification awarded identifier',
                field: 'qualification_awarded_identifier',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.qualification_awarded_identifier,
              },
              {
                name: 'Qualification identifier',
                field: 'qualification_identifier',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.qualification_id,
              },
              {
                name: 'Qualification result',
                field: 'qualification_result',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.qualification_result,
              },
              {
                name: 'Thesis title',
                field: 'thesis_title',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.thesis_title,
              },
            ],
          },
          {
            title: 'Student accreditation aim',
            field: 'student_accreditation_aim',
            quantity: {
              min: 0,
              max: Infinity,
            },
            inputs: [
              {
                name: 'Accreditation identifier',
                field: 'accreditation',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.accreditation_id,
              },
            ],
          },
          {
            title: 'Student initiatives',
            field: 'student_initiatives',
            quantity: {
              min: 0,
              max: Infinity,
            },
            inputs: [
              {
                name: 'Student initiative',
                field: 'type',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.type_id,
              },
            ],
          },
        ],
        level2GroupsConfigs: [
          {
            title: 'Qualification award accreditation',
            field: 'qualification_award_accreditations',
            quantity: {
              min: 0,
              max: Infinity,
            },
            inputs: [
              {
                name: 'Accreditation identifier',
                field: 'accreditation',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.qualification_accreditation_id,
              },
            ],
          },
          {
            title: 'Entry qualification award',
            field: 'entry_qualification_awards',
            quantity: {
              min: 0,
              max: Infinity,
            },
            inputs: [
              {
                name: 'Entry qualification awarded identifier',
                field: 'qualification_award_identifier',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.qualification_award_identifier,
              },
              {
                name: 'Own qualification identifier',
                field: 'own_award_identifier',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.own_award_identifier,
              },
              {
                name: 'Qualification result',
                field: 'qualification_result',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.qualification_result_id,
              },
              {
                name: 'Qualification type identifier',
                field: 'qualification_type',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.qualification_type_id,
              },
              {
                name: 'Qualification year',
                field: 'qualification_year',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.qualification_year,
              },
            ],
          },
        ],
        level3GroupsConfigs: [
          {
            title: 'Entry qualification subject',
            field: 'entry_qualification_subjects',
            quantity: {
              min: 0,
              max: Infinity,
            },
            inputs: [
              {
                name: 'Subject identifier',
                field: 'subject_identifier',
                type: 'textInput',
                validations: [],
                description: descriptions?.engagements?.subject_identifier_id,
              },
            ],
          },
        ],
      },
    },
    {
      name: 'module_instance',
      initialData: data.module_instances,
      groupsConfigs: {
        level0GroupConfig: {
          title: 'Module instance',
          field: 'module_instance',
          quantity: {
            min: 0,
            max: hasStudentCourseSession ? Infinity : 0,
          },
          canBeSaved: false,
          canBeSavedPart: true,
          inputs: [
            {
              name: 'Student course session identifier',
              field: 'student_course_session_id',
              type: 'select',
              options: dynamicOptions.studentCourseSessions,
              validations: ['required', ...(!fields?.module_instance?.student_course_session_id?.edit ? ['disabled'] : [])],
              maxLength: 2,
              description: descriptions?.modules_instances?.student_course_session_id,
            },
          ],
        },
        level1GroupsConfigs: [
          {
            title: 'Module Instance',
            field: 'module_instance',
            quantity: {
              min: 0,
              max: Infinity,
            },
            inputs: [
              {
                name: 'Module instance identifier',
                field: 'module_instance_identifier',
                type: 'textInput',
                validations: ['required', ...(!fields?.module_instance?.module_instance_identifier?.edit ? ['disabled'] : [])],
                maxLength: 50,
                description: descriptions?.modules_instances?.module_instance_identifier,
              },
              {
                name: 'Accreditation of prior experiential learning',
                field: 'accreditation_of_prior_id',
                type: 'select',
                validations: [...(!fields?.module_instance?.accreditation_of_prior_id?.edit ? ['disabled'] : [])],
                options: staticOptions?.moduleInstance?.accreditationPriorExperientialLearning,
                maxLength: 2,
                description: descriptions?.modules_instances?.accreditation_of_prior_id,
              },
              {
                name: 'Continuing module',
                field: 'continuing_module_id',
                type: 'select',
                validations: [...(!fields?.module_instance?.continuing_module_id?.edit ? ['disabled'] : [])],
                options: staticOptions?.moduleInstance?.continuingModules,
                maxLength: 2,
                description: descriptions?.modules_instances?.continuing_module_id,
              },
              {
                name: 'Inactive module flag',
                field: 'inactive_module_flag_id',
                type: 'select',
                validations: [...(!fields?.module_instance?.inactive_module_flag_id?.edit ? ['disabled'] : [])],
                options: staticOptions?.moduleInstance?.inactiveModuleFlags,
                maxLength: 2,
                description: descriptions?.modules_instances?.inactive_module_flag_id,
              },
              {
                name: 'Language percentage',
                field: 'language_percentage',
                type: 'textInput',
                validations: ['percentage', ...(!fields?.module_instance?.language_percentage?.edit ? ['disabled'] : [])],
                maxLength: 4,
                description: descriptions?.modules_instances?.language_percentage,
              },
              {
                name: 'Module instance fee amount',
                field: 'fee_amount',
                type: 'textInput',
                validations: [...(!fields?.module_instance?.fee_amount?.edit ? ['disabled'] : [])],
                maxLength: 6,
                description: descriptions?.modules_instances?.fee_amount,
              },
              {
                name: 'Module count',
                field: 'module_count_id',
                type: 'select',
                options: staticOptions?.moduleInstance?.moduleCounts,
                validations: [...(!fields?.module_instance?.module_count_id?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.modules_instances?.module_count_id,
              },
              {
                name: 'Module identifier',
                field: 'educational_module_id',
                type: 'select',
                options: dynamicOptions.modules,
                validations: ['required', ...(!fields?.module_instance?.educational_module_id?.edit ? ['disabled'] : [])],
                maxLength: 50,
                description: descriptions?.modules_instances?.educational_module_id,
              },
              {
                name: 'Module instance start date',
                field: 'module_start_date',
                type: 'datePicker',
                validations: ['required', 'dataValid', ...(!fields?.module_instance?.module_start_date?.edit ? ['disabled'] : [])],
                maxLength: 10,
                description: descriptions?.modules_instances?.module_start_date,
              },
              {
                name: 'Module instance end date',
                field: 'module_end_date',
                type: 'datePicker',
                validations: ['dataValid', ...(!fields?.module_instance?.module_end_date?.edit ? ['disabled'] : [])],
                maxLength: 10,
                description: descriptions?.modules_instances?.module_end_date,
              },
              {
                name: 'Module outcome',
                field: 'module_outcome_id',
                type: 'select',
                options: staticOptions?.moduleInstance?.moduleOutcomes,
                validations: [...(!fields?.module_instance?.module_outcome_id?.edit ? ['disabled'] : [])],
                maxLength: 10,
                description: descriptions?.modules_instances?.module_outcome_id,
              },
              {
                name: 'Module result',
                field: 'module_result_id',
                type: 'select',
                options: staticOptions?.moduleInstance?.moduleResults,
                validations: [...(!fields?.module_instance?.module_result_id?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.modules_instances?.module_result_id,
              },
              {
                name: 'Include in hesa',
                field: 'include_in_hesa',
                type: 'select',
                options: staticOptions?.moduleInstance?.include_in_hesa,
                validations: [...(!fields?.module_instance?.include_in_hesa?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.modules_instances?.include_in_hesa,
              },
            ],
          },
        ],
      },
    },
    {
      name: 'student',
      initialData: data.student ? [data.student] : [],
      groupsConfigs: {
        level0GroupConfig: {
          title: 'Student',
          quantity: {
            min: 1,
            max: 1,
          },
          canBeSaved: true,
          inputs: [
            {
              name: 'Student identifier',
              field: 'student_identifier',
              type: 'textInput',
              validations: ['required', ...(!fields?.student?.student_identifier?.edit ? ['disabled'] : [])],
              maxLength: 17,
              description: descriptions?.student?.student_identifier,
            },
            {
              name: 'Birthdate',
              field: 'birthdate',
              type: 'datePicker',
              maxLength: 10,
              validations: ['dataValid', ...(!fields?.student?.birthdate?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.birthdate,
            },
            {
              name: 'Ethnicity',
              field: 'ethnicity_type_id',
              type: 'select',
              options: staticOptions?.student?.ethnicities,
              maxLength: 3,
              validations: [...(!fields?.student?.ethnicity_type_id?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.ethnicity_type_id,
              editable: fields?.student?.ethnicity_type_id?.edit,
            },
            {
              name: 'First names',
              field: 'first_names',
              type: 'textInput',
              maxLength: 100,
              validations: [...(!fields?.student?.first_names?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.first_names,
              editable: fields?.student?.first_names?.edit,
            },
            {
              name: 'Gender identity',
              field: 'gender_identity_type_id',
              type: 'select',
              options: staticOptions?.student?.genders,
              maxLength: 2,
              validations: [...(!fields?.student?.gender_identity_type_id?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.gender_identity_type_id,
              editable: fields?.student?.gender_identity_type_id?.edit,
            },

            {
              name: 'Nationality',
              field: 'nationality_type_id',
              type: 'select',
              options: staticOptions?.student?.nationalities,
              maxLength: 2,
              validations: [...(!fields?.student?.nationality_type_id?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.nationality_type_id,
            },
            {
              name: 'Own student identifier',
              field: 'own_student_identifier',
              maxLength: 50,
              type: 'textInput',
              validations: ['disabled'],
              description: descriptions?.student?.own_student_identifier,
            },
            {
              name: 'Religion',
              field: 'religion_type_id',
              type: 'select',
              options: staticOptions?.student?.religions,
              maxLength: 2,
              validations: [...(!fields?.student?.religion_type_id?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.religion_type_id,
            },
            {
              name: 'Sex identifier',
              field: 'sex_identifier',
              type: 'select',
              options: staticOptions?.student?.sexIdentifiers,
              maxLength: 2,
              validations: [...(!fields?.student?.sex_identifier?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.sex_identifier,
            },

            {
              name: 'Sexual orientation',
              field: 'sexual_orientation_type_id',
              type: 'select',
              options: staticOptions?.student?.sexualOrientations,
              maxLength: 2,
              validations: [...(!fields?.student?.sexual_orientation_type_id?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.sexual_orientation_type_id,
            },
            {
              name: 'Surname at 16',
              field: 'surname_at_16',
              type: 'textInput',
              maxLength: 100,
              validations: [...(!fields?.student?.surname_at_16?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.surname_at_16,
            },
            {
              name: 'Student support number',
              field: 'student_support_number',
              type: 'textInput',
              maxLength: 13,
              validations: [...(!fields?.student?.student_support_number?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.student_support_number,
            },
            {
              name: 'Surname',
              field: 'surname',
              type: 'textInput',
              validations: ['required'],
              maxLength: 100,
              description: descriptions?.student?.surname,
              editable: fields?.student?.surname?.edit,
            },
            {
              name: 'Term time accommodation type',
              field: 'accommodation_type_id',
              type: 'select',
              options: staticOptions?.student?.termTimeAccommodationTypes,
              maxLength: 2,
              validations: [...(!fields?.student?.accommodation_type_id?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.accommodation_type_id,
            },
            {
              name: 'Term time postcode',
              field: 'term_time_postcode',
              type: 'textInput',
              maxLength: 8,
              validations: [...(!fields?.student?.term_time_postcode?.edit ? ['disabled'] : [])],
              description: descriptions?.student?.term_time_postcode,
            },

          ],
        },
        level1GroupsConfigs: [
          {
            title: 'Disability',
            field: 'disabilities',
            quantity: {
              min: 0,
              max: Infinity,
            },
            canBeSaved: true,
            inputs: [
              {
                name: 'Disability',
                field: 'disability_type_id',
                type: 'select',
                options: staticOptions?.disability?.disabilityTypes,
                validations: ['required', ...(!fields?.studentDisability?.disability_type_id?.edit ? ['disabled'] : [])],
                description: descriptions?.disabilities?.disability_type_id,
              },
            ],
          },
          {
            title: 'Unused Student fields',
            field: 'unusedStudentFields',
            quantity: {
              min: 1,
              max: 1,
            },
            canBeSaved: true,
            inputs: [
              {
                name: 'Carer',
                field: 'carer_type_id',
                type: 'select',
                options: staticOptions?.student?.carers,
                maxLength: 2,
                validations: [...(!fields?.student?.carer_type_id?.edit ? ['disabled'] : [])],
                description: descriptions?.student?.carer_type_id,
                editable: fields?.student?.carer_type_id?.edit,
              },
              {
                name: 'Dependant',
                field: 'dependant_type_id',
                type: 'select',
                options: staticOptions?.student?.dependants,
                maxLength: 2,
                validations: [...(!fields?.student?.dependant_type_id?.edit ? ['disabled'] : [])],
                description: descriptions?.student?.dependant_type_id,
                editable: fields?.student?.dependant_type_id?.edit,
              },
              {
                name: 'Language preference',
                field: 'language_preference_id',
                type: 'select',
                options: staticOptions?.student?.languages,
                maxLength: 2,
                validations: [...(!fields?.student?.language_preference_id?.edit ? ['disabled'] : [])],
                description: descriptions?.student?.language_preference_id,
              },
              {
                name: 'Scottish candidate number',
                field: 'scottish_candidate_number',
                type: 'textInput',
                maxLength: 9,
                validations: [...(!fields?.student?.scottish_candidate_number?.edit ? ['disabled'] : [])],
                description: descriptions?.student?.scottish_candidate_number,
              },
              {
                name: 'Service leaver',
                field: 'service_leaver_type_id',
                type: 'select',
                options: staticOptions?.student?.serviceLeavers,
                maxLength: 2,
                validations: [...(!fields?.student?.service_leaver_type_id?.edit ? ['disabled'] : [])],
                description: descriptions?.student?.service_leaver_type_id,
              },
              {
                name: 'Service student',
                field: 'service_student_id',
                type: 'select',
                options: staticOptions?.student?.serviceStudents,
                maxLength: 2,
                validations: [...(!fields?.student?.service_student_id?.edit ? ['disabled'] : [])],
                description: descriptions?.student?.service_student_id,
              },
              {
                name: 'Transgender',
                field: 'transgender_id',
                type: 'select',
                options: staticOptions?.student?.transgenders,
                maxLength: 2,
                validations: [...(!fields?.student?.transgender_id?.edit ? ['disabled'] : [])],
                description: descriptions?.student?.transgender_id,
              },
              {
                name: 'UCAS personal identifier',
                field: 'ucas_personal_identifier',
                type: 'textInput',
                maxLength: 10,
                validations: [...(!fields?.student?.ucas_personal_identifier?.edit ? ['disabled'] : [])],
                description: descriptions?.student?.ucas_personal_identifier,
              },
              {
                name: 'Unique learner number',
                field: 'unique_learner_number',
                type: 'textInput',
                maxLength: 10,
                validations: [...(!fields?.student?.unique_learner_number?.edit ? ['disabled'] : [])],
                description: descriptions?.student?.unique_learner_number,
              }
            ],
          },
          {
            title: 'Language Proficiency',
            field: 'language_proficiencies',
            quantity: {
              min: 0,
              max: Infinity,
            },
            canBeSaved: true,
            inputs: [
              {
                name: 'Language identifier',
                field: 'language_id',
                type: 'select',
                options: staticOptions?.languageProficiencies?.languageIdentifiers,
                validations: ['required', ...(!fields?.studentLanguageProficiency?.language_id?.edit ? ['disabled'] : [])],
                description: descriptions?.language_proficiencies?.language_id,
              },
              {
                name: 'Proficiency type',
                field: 'proficiency_type_id',
                type: 'select',
                options: staticOptions?.languageProficiencies?.proficiencyTypes,
                validations: ['required', ...(!fields?.studentLanguageProficiency?.proficiency_level_id?.edit ? ['disabled'] : [])],
                description: descriptions?.language_proficiencies?.proficiency_type_id,
              },
              {
                name: 'Level of proficiency',
                field: 'proficiency_level_id',
                type: 'select',
                options: staticOptions?.languageProficiencies?.levelOfProficiencies,
                validations: [...(!fields?.studentLanguageProficiency?.proficiency_type_id?.edit ? ['disabled'] : [])],
                description: descriptions?.language_proficiencies?.proficiency_level_id,
              },
            ],
          },
          // {
          //   title: 'National Identity',
          //   field: 'national_identities',
          //   quantity: {
          //     min: 0,
          //     max: Infinity,
          //   },
          //   canBeSaved: true,
          //   inputs: [
          //     {
          //       name: 'National identity type',
          //       field: 'national_identity_type_id',
          //       type: 'select',
          //       options: staticOptions?.nationalIdentity?.nationalIdentityTypes,
          //       validations: ['required', ...(!fields?.studentNationalIdentity?.national_identity_type_id?.edit ? ['disabled'] : [])],
          //       description: descriptions?.national_identities?.national_identity_type_id,
          //     },
          //   ],
          // },
        ],
      },
    },
  ]

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <SectionTitle title='HESA Details' />
      </div>
      {!data.id && (
        <div className={`${classes.edit_wrapper}`} ref={editButtonRef}>
          {form?.edit && !isEditable && <EditButton clickHandler={startEditMode} />}
        </div>
      )}
      <div className={classes.contentWrapper}>
        <div className={classes.subforms}>
          {subforms?.map(subform => (
            <Subform
              name={subform.name}
              initialData={subform.initialData}
              isEditable={isEditable}
              groupsConfigs={subform.groupsConfigs}
              saveHandler={(data, callback) => saveHesaDetailForm(subform.name, data, callback)}
              key={subform.name}
              onOpenDescription={onOpenDescription}
              onOpenDeleteModal={onOpenDeleteModal}
              onShowFieldError={onShowFieldError}
              onToggleEditMode={exitEditMode}
              permissions={form}
            />
          ))}
        </div>
        {!!snapshots?.length && (
          <div className={classes.snapshots}>
            <div className={classes.title}>Snapshots:</div>
            {snapshots.map((snapshot, i) => (
              <div
                className={`${classes.snapshot} ${snapshot.id === data?.id ? classes.active : ''}`}
                onClick={() => getSnapshotInformation(snapshot.id)}
                key={i}
              >
                {snapshot.created_at}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
