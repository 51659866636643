import React from 'react'
import { SessionStatusForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { sessionStatusSelectors, sessionStatusActions } from '@Store/studies'

export const CreateSessionStatus = ({ programmeRecord, onOpenDescriptionModal, handleBack }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { contactId, studyProgrammeId } = useParams()
  const sessionStatusActivity = useSelector(sessionStatusSelectors.sessionStatus)
  const options = useSelector(sessionStatusSelectors.options)
  const isLoading = useSelector(sessionStatusSelectors.isLoading)

  const onCreate = data => {
    dispatch(sessionStatusActions.create({ data, history, studentCourseSessionId: programmeRecord.student_course_session_id, studyProgrammeId, contactId }))
  }

  const onCancel = () => {
    handleBack()
  }

  return (
    <SessionStatusForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      initialValue={sessionStatusActivity}
      options={options}
      isLoading={isLoading}
    />
  )
}
