import * as types from './admission.types'

const initialState = {
	application: null,
	permissions: {
		form: {}
	},
	options: {
		static: '',
		dynamic: '',
	},
	isLoading: false,
}
export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.SET_APPLICATION:
			return {
				...state,
				application: action.payload.data,
				permissions: action.payload.permissions,

			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: {
					...state.options,
					[action.payload.name]: action.payload.data,
				},
			}
		default:
			return state
	}
}
