import React, { useState, useEffect, useMemo } from 'react'
import classes from './style.module.scss'
import { useLocation } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { modalActions, snackbarActions, optionsSelectors } from '@Root/store'
import { permissionsActions, permissionsSelectors } from '@Store/permissions'
import { API } from '@Root/API'
import { errorMessage, onOpenEmail } from '@Root/helpers'
import { ActionsDropdown, Table } from '@Root/components'
import { trashInformationMandatoryRules, createDataConfig, booleanOptions } from '@Root/configs'
import { optionsActions } from '@Store/options'
import { studentActions, studentSelectors } from '@Store/contact/person'
import { useTrackProcessImport } from '@Hooks'

export const ContactsTable = ({ styleConfig, isEmptyPermissions }) => {
  const location = useLocation()
  const dataForCreateContact = useSelector(optionsSelectors.dataForCreateContact)
  const studentStaticOptions = useSelector(studentSelectors.options).static
  const additionalPermissions = useSelector(permissionsSelectors.additionalPermissions)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const rulePermissions = useSelector(permissionsSelectors.rulePermissions)
  const { trackProcessImport, importError } = useTrackProcessImport()
  const [{ shouldBeUpdated, confRollover, handlerWithTableParams }, setData] = useState({
    shouldBeUpdated: false,
    confRollover: { open: false, conf: {} },
    handlerWithTableParams: null
  })
  const tab = location.pathname.split('/')[3]

  useEffect(() => {
    fetchOptions()
    if (isEmptyPermissions) {
      dispatch(permissionsActions.getAdditional(tab))
    }
  }, [])

  useEffect(() => {
    if (shouldBeUpdated)
      setData({
        shouldBeUpdated: false,
        confRollover,
        handlerWithTableParams
      })
  }, [shouldBeUpdated])

  const dispatch = useDispatch()

  const getAllStudent = async params => {
    return await API.contact.person.student.rollover.getAllById(params)
  }

  const actions = useMemo(() => {
    let array = []

    if (dataCreationPermissions?.person) {
      array.push({
        name: 'Add a person',
        path: '/home/contacts/all-contacts/new-person',
      })
    }

    if (dataCreationPermissions?.organisation) {
      array.push({
        name: 'Add an organisation',
        path: '/home/contacts/all-contacts/new-organisation',
      })
    }

    if (dataCreationPermissions?.rollover_students_newly && 'students' === tab) {
      array.push({
        name: 'Rollover newly recruited students',
        handler: () => {
          setData({
            shouldBeUpdated,
            confRollover: {
              open: true,
              conf: {
                name: 'records',
                handler: params => getAllStudent(params),
                headerText: 'Rollover newly recruited students',
                text: 'Do you want to proceed?',
                key: 'recruited_students',
                handelSubmit: params => API.contact.person.student.rollover.post(params),
                handelTrackProcess: (data, config) => trackProcessImport(data, config),
                handelError: error => importError(error),
                invalid_fields: ['id', 'name', 'error'],
                validFields: ['id', 'name'],
                isRollBack: false,
                notification_succeed: 'Rollover for newly recruited students is successfully completed',
              },
            },
            handlerWithTableParams
          })
        },
      })
    }

    if (dataCreationPermissions?.rollover_students_continuing && 'students' === tab) {
      array.push({
        name: 'Rollover continuing students',
        handler: () => {
          setData({
            shouldBeUpdated,
            confRollover: {
              open: true,
              conf: {
                name: 'records',
                handler: params => getAllStudent(params),
                headerText: 'Rollover Continuing students',
                text: 'Do you want to proceed?',
                key: 'continuing_students',
                handelSubmit: params => API.contact.person.student.rollover.postContinuingStudents(params),
                handelTrackProcess: (data, config) => trackProcessImport(data, config),
                handelError: error => importError(error),
                invalid_fields: ['id', 'name', 'error'],
                validFields: ['id', 'name'],
                isRollBack: false,
                notification_succeed: 'Rollover Continuing students is successfully completed',
                handlerWarningCheck: (params) => API.contact.person.student.rollover.checkStudents(params)
              },
            },
            handlerWithTableParams
          })
        },
      })
    }

    if (dataCreationPermissions?.rollover_leavers && 'students' === tab) {
      array.push({
        name: 'Rollover leavers',
        handler: () => {
          setData({
            shouldBeUpdated,
            confRollover: {
              open: true,
              conf: {
                name: 'records',
                handler: params => getAllStudent(params),
                headerText: 'Rollover leavers',
                text: 'Do you want to proceed?',
                key: 'leavers',
                handelSubmit: params => API.contact.person.student.rollover.postLeavers(params),
                handelTrackProcess: (data, config) => trackProcessImport(data, config),
                handelError: error => importError(error),
                invalid_fields: ['id', 'name', 'error'],
                validFields: ['sid', 'name'],
                isRollBack: false,
                notification_succeed: 'Rollover leavers is successfully completed!',
              },
            },
            handlerWithTableParams
          })
        },
      })
    }
    if (additionalPermissions?.students?.include_in_hesa && 'students' === tab) {
      array.push({
        name: 'Include in HESA',
        handler: () => {
          setData({
            shouldBeUpdated, confRollover, handlerWithTableParams: (params) => {
              API.contact.person.student.includeInHesa.putIncludeInHesa({ ...params, include_in_hesa: true }).then(({ data }) => {
                dispatch(snackbarActions.setSuccessNotification(({ text: data.data[0] })))
              }).finally(e => {
                setData({ shouldBeUpdated: true, confRollover, handlerWithTableParams: null })
              })
            }
          })
        },
      })
    }
    if (additionalPermissions?.students?.include_in_hesa && 'students' === tab) {
      array.push({
        name: 'Not include in HESA',
        handler: () => {
          setData({
            shouldBeUpdated, confRollover, handlerWithTableParams: (params) => {
              API.contact.person.student.includeInHesa.putIncludeInHesa({ ...params, include_in_hesa: false }).then(({ data }) => {
                dispatch(snackbarActions.setSuccessNotification(({ text: data.data[0] })))
              }).finally(e => {
                setData({ shouldBeUpdated: true, confRollover, handlerWithTableParams: null })
              })
            }
          })
        },
      })
    }
    return array
  }, [])

  const tableOptions = () => {
    const {
      all_program_type = [],
      institutions = [],
      student_types = [],
      genders = [],
      person_relationships = [],
      organisation_relationships = [],
      academic_year_list = [],
    } = dataForCreateContact
    const { marital_statuses = [], nationalities = [], ethnicities = [], disabilities = [] } = studentStaticOptions

    return {
      gender: genders.map(x => x.label),
      relationship: [...person_relationships, ...organisation_relationships].map(x => x.label),
      institution: institutions.map(x => x.label),
      student_types: student_types.map(x => x.label),
      programme_types: all_program_type.map(x => x.label),
      cohort_group: academic_year_list,
      university_card_date: academic_year_list,
      marital_status_type: marital_statuses.map(x => x.label),
      has_learning_support: booleanOptions.map(x => x.label),
      has_disability: booleanOptions.map(x => x.label),
      is_international: booleanOptions.map(x => x.label),
      nationality_type: nationalities.map(x => x.label),
      disability_type: disabilities.map(x => x.label),
      ethnicity_type: ethnicities.map(x => x.label),
      studied_at: booleanOptions.map(x => x.label),
      include_in_hesa: booleanOptions.map(x => x.label),
    }
  }

  const exportHandlers = format => {
    const handlers = {
      csv: { handler: API.contact.exports.byFormat, useCustomCollumns: true },
      xml: { handler: API.contact.exports.byFormat, useCustomCollumns: true },
      moodle_csv: { handler: API.contact.exports.moodle, useCustomCollumns: false },
    }
    return handlers[format]
  }

  const configureExportColumns = (columns, hiddenColumns, params) => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfigureExportModal', {
          clickRejectButtonHandler: reject,
          title: 'Choose exported columns',
          columns: columns,
          resolveButtonText: 'Submit',
          hiddenColumns: hiddenColumns,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(data => API.contact.exports.configureExportColumns(data, params))
      .then(() => {
        dispatch(snackbarActions.setSuccessNotification({ text: 'Changes has been saved successfully!' }))
      })
      .finally(() => dispatch(modalActions.hideModal()))
  }

  const tableData = () => {
    const mandatoryRules = trashInformationMandatoryRules
    return {
      'all-contacts': {
        ...createDataConfig('all-contacts', mandatoryRules, [], 'getContacts', 'all-contacts', true, true, true, true),
        options: tableOptions(),
        exportTypeName: 'all-contacts',
        fetchExportedDataHander: format => exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {
          Email: onOpenEmail,
        },
        permissions: additionalPermissions
          ? {
            rule: rulePermissions['all-contacts'],
            hasExport:
              additionalPermissions?.allContacts?.xml_export ||
              additionalPermissions?.allContacts?.csv_export ||
              additionalPermissions?.allContacts?.moodle_export,
            hasCSVExport: additionalPermissions?.allContacts?.csv_export,
          }
          : null,
      },
      students: {
        ...createDataConfig('students', mandatoryRules, [], 'getContacts', 'student', true, true, true, true, false, true),
        fetchExportedDataHander: format => exportHandlers(format),
        exportTypeName: 'contacts',
        clickImportButtonHandler: () => importCSV('student'),
        options: tableOptions(),
        clickLinkHandlers: {},
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => configureExportColumns(columns, hiddenColumns),
        permissions: additionalPermissions
          ? {
            hasExport:
              additionalPermissions?.students?.xml_export || additionalPermissions?.students?.csv_export || additionalPermissions?.students?.moodle_export,
            hasCSVImport: additionalPermissions?.students?.csv_import,
            hasXMLExport: additionalPermissions?.students?.xml_export,
            hasCSVExport: additionalPermissions?.students?.csv_export,
            hasMoodleExport: additionalPermissions?.students?.moodle_export,
            rule: rulePermissions?.students,
          }
          : null,
      },
      academics: {
        ...createDataConfig('academics', mandatoryRules, [], 'getContacts', 'academic', true, true, true, true, false, true),
        options: tableOptions(),
        exportTypeName: 'academics-contacts',
        fetchExportedDataHander: format => exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
            rule: rulePermissions.academics,
            hasExport:
              additionalPermissions?.academics?.xml_export || additionalPermissions?.academics?.csv_export || additionalPermissions?.academics?.moodle_export,
            hasCSVExport: additionalPermissions?.academics?.csv_export,
          }
          : null,
      },
      staff: {
        ...createDataConfig('staff', mandatoryRules, [], 'getContacts', 'staff', true, true, true, true, false, true),
        options: tableOptions(),
        exportTypeName: 'staff-contacts',
        fetchExportedDataHander: format => exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
            rule: rulePermissions.staff,
            hasExport: additionalPermissions?.staff?.xml_export || additionalPermissions?.staff?.csv_export || additionalPermissions?.staff?.moodle_export,
            hasCSVExport: additionalPermissions?.staff?.csv_export,
          }
          : null,
      },
      clergy: {
        ...createDataConfig('clergy', mandatoryRules, [], 'getContacts', 'clergy', true, true, true, true, false, true),
        options: tableOptions(),
        exportTypeName: 'clergy-contacts',
        fetchExportedDataHander: format => exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
            rule: rulePermissions.clergy,
            hasExport: additionalPermissions?.clergy?.xml_export || additionalPermissions?.clergy?.csv_export || additionalPermissions?.clergy?.moodle_export,
            hasCSVExport: additionalPermissions?.clergy?.csv_export,
          }
          : null,
      },
      associations: {
        ...createDataConfig('associations', mandatoryRules, [], 'getContacts', 'association', true, true, true, true, false, true),
        options: tableOptions(),
        exportTypeName: 'association-contacts',
        fetchExportedDataHander: format => exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
            rule: rulePermissions.associations,
            hasExport:
              additionalPermissions?.associations?.xml_export ||
              additionalPermissions?.associations?.csv_export ||
              additionalPermissions?.associations?.moodle_export,
            hasCSVExport: additionalPermissions?.associations?.csv_export,
          }
          : null,
      },
      churches: {
        ...createDataConfig('churches', mandatoryRules, [], 'getContacts', 'church', true, true, true, true, false, true),
        options: tableOptions(),
        exportTypeName: 'church-contacts',
        fetchExportedDataHander: format => exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
            rule: rulePermissions?.churches,
            hasExport:
              additionalPermissions?.churches?.xml_export || additionalPermissions?.churches?.csv_export || additionalPermissions?.churches?.moodle_export,
            hasCSVExport: additionalPermissions?.churches?.csv_export,
          }
          : null,
      },
      dioceses: {
        ...createDataConfig('dioceses', mandatoryRules, [], 'getContacts', 'diocese', true, true, true, true, false, true),
        options: tableOptions(),
        exportTypeName: 'diocese-contacts',
        fetchExportedDataHander: format => exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
            rule: rulePermissions.dioceses,
            hasExport:
              additionalPermissions?.dioceses?.xml_export || additionalPermissions?.dioceses?.csv_export || additionalPermissions?.dioceses?.moodle_export,
            hasCSVExport: additionalPermissions?.dioceses?.csv_export,
          }
          : null,
      },
      'training-facilities': {
        ...createDataConfig('training-facilities', mandatoryRules, [], 'getContacts', 'training-facilities', true, true, true, true, false, true),
        fetchDataHandler: params => API.getContacts(params, 'trainingfacility'),
        exportTypeName: 'training-facility-contacts',
        fetchExportedDataHander: format => exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => configureExportColumns(columns, hiddenColumns),
        options: tableOptions(),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
            rule: rulePermissions['training-facilities'],
            hasExport:
              additionalPermissions?.training_facilities?.xml_export ||
              additionalPermissions?.training_facilities?.csv_export ||
              additionalPermissions?.training_facilities?.moodle_export,
            hasCSVExport: additionalPermissions?.training_facilities?.csv_export,
          }
          : null,
      },
      other: {
        ...createDataConfig('other', mandatoryRules, [], 'getContacts', 'other', true, true, true, true, false, true),
        options: tableOptions(),
        exportTypeName: 'other-contacts',
        fetchExportedDataHander: format => exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
            rule: rulePermissions.other,
            hasExport: additionalPermissions?.other?.xml_export || additionalPermissions?.other?.csv_export || additionalPermissions?.other?.moodle_export,
            hasCSVExport: additionalPermissions?.other?.csv_export,
          }
          : null,
      },
    }
  }

  const fetchOptions = () => {
    const hasOptions = Object.keys(dataForCreateContact).length > 0
    if (!hasOptions) {
      dispatch(optionsActions.getDataForCreateContact())
      dispatch(studentActions.getStaticOptions())
    }
  }

  const refreshTable = () => {
    setData({ shouldBeUpdated: true, confRollover, handlerWithTableParams })
  }

  const importCSV = type => {
    const config = {
      student: {
        name: 'Student records',
        validFields: ['sid', 'name', 'email'],
        invalid_fields: ['sid', 'name', 'email', 'error_msg'],
        importFile: API.contact.imports.csv,
        trackProcess: API.contact.imports.trackProcess,
        isRollBack: true,
        refreshTable: refreshTable,
        notification_succeed: 'Students have been imported successfully!',
        isImport: true,
      },
    }[type]

    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('UploadingModal', {
          clickRejectButtonHandler: () => dispatch(modalActions.hideModal()),
          clickResolveButtonHandler: file => {
            config
              .importFile(file)
              .then(({ data: { data } }) => {
                dispatch(modalActions.hideModal())
                trackProcessImport(data, config)
              })
              .catch(error => importError(error))
          },
        })
      )
    })
  }

  const onChooseAction = actionName => {
    const item = [...actions].filter(el => el.name === actionName)

    item[0]?.handler()
  }

  return (
    <div className={classes.wrapper}>
      {actions.length > 0 && <ActionsDropdown actions={actions} style={styleConfig.actionsDropdown} clickHandler={onChooseAction} />}
      <Table
        key={tableData()[tab].name}
        name={tableData()[tab].name}
        mandatoryRules={tableData()[tab].mandatoryRules}
        options={tableData()[tab].options}
        fetchDataHandler={tableData()[tab].fetchDataHandler}
        fetchSaveColumnOptions={tableData()[tab].fetchSaveColumnOptions}
        fetchColumnOptions={tableData()[tab].fetchColumnOptions}
        fetchExportedDataHander={tableData()[tab].fetchExportedDataHander}
        clickLinkHandlers={tableData()[tab].clickLinkHandlers}
        hasRules={tableData()[tab].hasRules}
        hasExport={tableData()[tab].hasExport}
        hasImport={tableData()[tab].hasImport}
        hasFilters={tableData()[tab].hasFilters}
        hasSorting={tableData()[tab].hasSorting}
        exportTypeName={tableData()[tab].exportTypeName}
        clickImportButtonHandler={tableData()[tab].clickImportButtonHandler}
        errorHandler={error => dispatch(snackbarActions.setSnackbar(({ text: errorMessage(error), isError: true })))}
        shouldBeUpdated={shouldBeUpdated}
        datePeriodTop={styleConfig.table.datePeriod.top}
        styleConfig={{
          ...styleConfig.table,
          tableBar: styleConfig.tableBar,
        }}
        defaultExportFields={tableData()[tab].defaultExportFields}
        hasExportConfiguration={tableData()[tab].hasExportConfiguration}
        clickExportConfigurationButtonHandler={tableData()[tab].clickExportConfigurationButtonHandler}
        permissions={tableData()[tab].permissions}
        confRollover={confRollover}
        setConfRollover={param => {
          setData({ confRollover: param, shouldBeUpdated, handlerWithTableParams })
        }}
        handlerWithTableParams={handlerWithTableParams}
      />
    </div>
  )
}
