import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { RoundPlusButton, SectionTitle, TextArea, LoadMoreButton } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from '@Store/modal'
import { useError } from '@Root/hooks'
import { NotesItem } from './NotesItem'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import textSize from 'text-size'
import { Reply } from './Reply'
import { getFromLocalStorage } from '@Helpers/localStorage'
import { SkeletonNote } from '@Root/components/Skeleton/SkeletonNote/index'

export const NotesForm = ({
  canManipulate = false,
  loadingConf,
  isSuccess,
  initialValue,
  canCreate,
  onEdit,
  onCreate,
  onDelete,
  onReply,
  notesLength,
  onSort,
  openIdHistoryOfChanges,
  onOpenHistoryOfChanges,
  handelPaginationNoteAction,
  page,
  isDragDisabled,
  paginateReply,
}) => {
  const [newNote, setNewNote] = useState(null)
  const [replyNote, setReplyNote] = useState(null)
  const [currentNoteId, setCurrentNoteId] = useState(null)
  const [currentReplyId, setCurrentReplyId] = useState(null)
  const [editedNoteId, setEditedNoteId] = useState(null)
  const [editValue, setEditValue] = useState('')
  const [data, setData] = useState([])
  const { errors, setError } = useError()
  const [replyNoteName, setReplyNoteName] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  const validateForm = () => {
    if (newNote === '') {
      setError('note', 'Required')
      return false
    }
    return true
  }

  const onCreatNote = () => {
    if (!validateForm()) return
    onCreate({ note_text: newNote })
  }

  const onSubmit = payload => {
    if (payload.type === 'Edit') {
      onEdit(editedNoteId, { note_text: editValue })
      setEditedNoteId(null)
    }
    if (payload.type === 'Reply' && replyNote !== null && replyNote.trim().length) {
      if (currentReplyId === '') {
        onReply({ reply_id: currentNoteId, parent_id: currentNoteId, note_text: replyNote })
      } else {
        onReply({ reply_id: currentReplyId, parent_id: currentNoteId, note_text: replyNote })
      }
      setReplyNote(null)
    }
  }

  useEffect(() => {
    cancelCreate()
    cancelEdit()
    cancelReplyNote()
  }, [isSuccess])

  const onEditNote = (id, value) => {
    setEditedNoteId(id)
    setEditValue(value)
  }
  const onReplyNote = (parantId, childrenId, name) => {
    setReplyNote('')
    setCurrentNoteId(parantId)
    setCurrentReplyId(childrenId)
    setReplyNoteName(name)
  }
  const onChangeEditValue = value => {
    setEditValue(value)
  }

  const cancelCreate = () => {
    setNewNote(null)
  }

  const cancelEdit = () => {
    setEditValue('')
    setEditedNoteId(null)
  }

  const cancelReplyNote = () => {
    setReplyNote(null)
  }

  const onDeleteNote = (id, text, pageReply, callBack) => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: text === 'reply' ? `This reply will be removed?` : `This note and all replies will be removed`,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        onDelete(id, pageReply, callBack)
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }
  const onDragEndNote = result => {
    const { source, destination } = result
    if (source.index === destination.index) return
    const notes = getFromLocalStorage('note')
    const dropEl = [...notes].find((el, idx) => idx === source.index)
    const newData = [...notes].reduce((acc, el, indx) => {
      if (indx === source.index) {
        return acc
      }
      if (indx === destination.index) {
        if (source.index < destination.index) {
          acc.push(el, dropEl)
        } else {
          acc.push(dropEl, el)
        }
      } else {
        acc.push(el)
      }
      return acc
    }, [])
    setData(newData)
    onSort(
      newData.map(el => ({ id: el.id })),
      page
    )
  }

  const onSizeNote = () =>
    data.reduce((acc, note) => {
      acc =
        Math.round(Math.round(textSize.getTextWidth({ text: note.note_text, fontSize: 14.5, fontName: 'San-Francisco' })) / 820) * 15 +
        15 +
        50 +
        onSizeReply(note?.replies) +
        acc
      return acc
    }, 0)

  const onSizeReply = valueArr =>
    valueArr.reduce((acc, el) => {
      acc = Math.round(Math.round(textSize.getTextWidth({ text: el.note_text, fontSize: 14.5, fontName: 'San-Francisco' })) / 620) * 15 + 20 + 50 + acc
      return acc
    }, 20)

  const renderCreateNote = value => {
    return (
      <div className={classes.textareaWrapper}>
        <TextArea style={{ width: 900, height: 84 }} value={value} changeHandler={value => setNewNote(value)} error={errors.note} />
        <div className={classes.buttonsWrapper}>
          <button className={classes.buttonSecondary} onClick={cancelCreate} disabled={loadingConf.isCreateLoading}>
            Cancel
          </button>
          <button className={classes.buttonMain} onClick={onCreatNote} disabled={loadingConf.isCreateLoading}>
            Add
          </button>
        </div>
      </div>
    )
  }

  const renderEditNote = initialValue => {
    return (
      <div>
        <TextArea style={{ width: '100%', height: 84 }} value={initialValue} changeHandler={value => onChangeEditValue(value)} />
        <div className={classes.buttonsWrapper}>
          <button className={classes.buttonSecondary} onClick={cancelEdit} disabled={loadingConf.isEditLoading.status}>
            Cancel
          </button>
          <button className={classes.buttonMain} onClick={() => onSubmit({ type: 'Edit' })} disabled={loadingConf.isEditLoading.status}>
            Save
          </button>
        </div>
      </div>
    )
  }

  return (
    <FormWrapper>
      <div className={classes.wrapper}>
        <div className={classes.titleWrapper}>
          <SectionTitle title='Notes' />
          {canCreate && newNote === null && canManipulate && <RoundPlusButton clickHandler={() => setNewNote('')} />}
        </div>
        {newNote !== null && renderCreateNote(newNote)}
        {(loadingConf.isGetLoading || loadingConf.isCreateLoading) && <SkeletonNote />}
        <DragDropContext onDragEnd={onDragEndNote}>
          <Droppable droppableId='Note'>
            {provided => (
              <div className={classes.notesWrapper} style={{ minHeight: onSizeNote() }} {...provided.droppableProps} ref={provided.innerRef}>
                {data.map((note, i) => {
                  return (
                    <Draggable key={note.id} draggableId={String(note.id)} index={i} isDragDisabled={data.length <= 1 ? true : isDragDisabled}>
                      {(provided, { isDragging }) => (
                        <div className={classes.notesItem} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <NotesItem
                            key={i}
                            note={note}
                            onOpenHistoryOfChanges={onOpenHistoryOfChanges}
                            openIdHistoryOfChanges={openIdHistoryOfChanges}
                            onEditNote={onEditNote}
                            onReplyNote={onReplyNote}
                            editedNoteId={editedNoteId}
                            canManipulate={canManipulate}
                            onDeleteNote={onDeleteNote}
                            renderEditNote={renderEditNote}
                            editValue={editValue}
                            parentId={note.id}
                            loadingConf={loadingConf}
                          />

                          <Reply
                            replies={note.replies}
                            parentId={note.id}
                            onOpenHistoryOfChanges={onOpenHistoryOfChanges}
                            openIdHistoryOfChanges={openIdHistoryOfChanges}
                            onEditNote={onEditNote}
                            onReplyNote={onReplyNote}
                            editedNoteId={editedNoteId}
                            canManipulate={canManipulate}
                            onDeleteNote={onDeleteNote}
                            renderEditNote={renderEditNote}
                            editValue={editValue}
                            loadingConf={loadingConf}
                            onSubmit={onSubmit}
                            errors={errors}
                            replyNoteName={replyNoteName}
                            currentNoteId={currentNoteId}
                            replyNote={replyNote}
                            cancelReplyNote={cancelReplyNote}
                            setReplyNote={setReplyNote}
                            onSizeReply={onSizeReply}
                            lastPage={note.last_page}
                            paginateReply={paginateReply}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!data.length && newNote === null && <div className={classes.message}>There are currently no associated notes for this contact</div>}

        {data.length >= 10 && notesLength !== data.length && (
          <div className={classes.wrapperBtn}>
            <LoadMoreButton article={'Note'} handelPaginationAction={handelPaginationNoteAction} />{' '}
          </div>
        )}
      </div>
    </FormWrapper>
  )
}
