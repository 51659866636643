import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './contact.types'
import { actions } from './contact.actions'
import { API, createInstance } from '@Root/API'
import { errorMessage } from '@Root/helpers'
import { consentsActions, snackbarActions, studentCourseSessionPostgradActions } from '@Root/store'
import { personContactRootSaga, studentActions } from './person'
import { organisationContactRootSaga } from './organisation'
import { additionalInformationRootSaga } from './additionalInformation'
import { noteRootSaga } from '@Store/contact/note'
import { attachmentRootSaga, contactAttachmentActions } from '@Store/contact/attachment'
import { admissionRootSaga } from './admission'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { contactId } = payload
  try {
    const { data } = yield call(createInstance().get, `contacts/${contactId}`)

    const {
      contact_type,
      attachments,
      consent,
      post_grad,
      moodle_links,
      contact,
      is_academic,
      is_association,
      is_cleric,
      is_staff,
      is_student,
      has_course_session,
      student = {},
      is_church,
      is_diocese,
      is_training_facility,
      is_trashed,
    } = data.data

    const information = {
      type: contact_type,
      has_course_session,
      relationships: { is_academic, is_association, is_cleric, is_staff, is_student, is_church, is_diocese, is_training_facility, is_trashed },
    }

    yield put(actions.setInformation(information))
    yield put(studentActions.setInformation(student))
    if (contact_type === 'person') {
      yield put(actions.setPerson(contact))
    } else {
      yield put(actions.setOrganisation(contact))
    }
    if (attachments) yield put(contactAttachmentActions.set(attachments))
    yield put(consentsActions.setConsents(consent))
    if (consent.permissions) yield put(consentsActions.setPermissions(consent.permissions))
    yield put(studentCourseSessionPostgradActions.set(post_grad))
    yield put(actions.setMoodleLinks(moodle_links))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { history, data, contactType } = payload
  try {
    const response = yield call(API.contact.create, contactType, data)
    const id = response.data.data.id
    yield put(actions.setError({ errorName: '', errorMessage: '' }))
    history.push(`/home/contacts/all-contacts/${id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The contact has been created successfully!' }))
  } catch (error) {
    try {
      const [errorName, errorMessages] = Object.entries(error.response.data.errors)[0]
      yield put(actions.setError({ errorName, errorMessage: errorMessages[0] }))
    } catch (error) {
      yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    }
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { contactType, data } = payload

  try {
    const response = yield call(API.contact.edit, contactType, data)
    yield put(actions.setError({ errorName: '', errorMessage: '' }))
    yield put(actions.get({ contactId: data.contact_id }))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The contact information has been saved successfully!' }))
  } catch (error) {
    try {
      const [errorName, errorMessages] = Object.entries(error.response.data.errors)[0]
      yield put(actions.setError({ errorName, errorMessage: errorMessages[0] }))
    } catch (error) {
      yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    }
    yield put(actions.toggleLoading(false))
  }
  yield put(actions.toggleSuccess(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { contactId, history } = payload
  try {
    yield call(API.contact.remove, contactId)
    history.push('/home/contacts/all-contacts')
    yield put(snackbarActions.setSuccessNotification({ text: 'The contact has been deleted successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getStaticOptions() {
  try {
    const {
      data: { genders, contact_address_type, academic_years_list, countries },
    } = yield call(API.contact.options.getStatic)

    const formattedOptions = {
      genders: genders.map(obj => ({ value: obj.id, label: obj.label })),
      academic_years_list: academic_years_list.map(obj => ({ value: obj.id, label: obj.name })),
      countries: countries.map(obj => ({ value: obj.id, label: obj.name })),
      contact_address_type: contact_address_type.map(obj => ({ value: obj.id, label: obj.name })),
    }
    yield put(actions.setOptions({ name: 'static', data: formattedOptions }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

function* getDynamicOptions() {
  try {
    const { data } = yield call(API.options.getInstitutions)

    const formattedOptions = {
      institutions: data.data.map(obj => ({ value: obj.id, label: obj.name })),
    }
    yield put(actions.setOptions({ name: 'dynamic', data: formattedOptions }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}
export function* rootSaga() {
  yield all([
    takeEvery(types.GET_CONTACT, get),
    takeEvery(types.EDIT_CONTACT, edit),
    takeEvery(types.CREATE_CONTACT, create),
    takeEvery(types.DELETE_CONTACT, remove),
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
    takeEvery(types.GET_DYNAMIC_OPTIONS, getDynamicOptions),
    personContactRootSaga(),
    organisationContactRootSaga(),
    additionalInformationRootSaga(),
    noteRootSaga(),
    attachmentRootSaga(),
    admissionRootSaga(),
  ])
}
