import s from './style.module.scss'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const SkeletonSearch = ({ id }) => {
  return (
    <div className={s.Card} id={id}>
      <div className={s.Content}>
        <div className={s.Wrapper}>
          <div className={s.Marck}>
            <Skeleton width={70} />
            <Skeleton width={70} />
            <Skeleton width={70} />
          </div>
        </div>
        <div className={s.Text}>
          <Skeleton width={500} />
        </div>

        <div className={s.Result}>
          <Skeleton width={450} />
          <Skeleton width={450} />
          <Skeleton width={450} />
          <Skeleton width={450} />
          <Skeleton width={450} />
        </div>
      </div>
    </div>
  )
}
