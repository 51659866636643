import * as types from './fieldTooltipManager.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  messages: [{ name: 'User name', value: 'TEST VALUE', id: 1 }],
  permissions: {},
  descriptions: {
    contact: {
      person: {
        form: [],
        students: [],
        academics: [],
        staff: [],
        clergy: [],
        associations: [],
        hesa_details: {},
      },
      organisation: {
        form: [],
        churches: [],
        dioceses: [],
        'training-facilities': [],
      },
      rollover: {},
      consents: {},
    },
    studies: {
      programmes: [],
      'records-of-studies': [],
      'postgrad-student-details': [],
      fees: [],
      admissions: [],
      'funding-and-monitoring': [],
      modules: [],
      'module-subject': [],
      'module-instance': [],
      'module-cost-center': [],
      'module-location': [],
      'module-role': [],
      engagements: [],
      engagement_collaborative_provision: [],
      engagement_entry_profile: [],
      engagement_leaver: [],
      entry_qualification_award: [],
      engagement_qualification_award: [],
      qualification_award_accreditation: [],
      qualification_subject: [],
      engagement_accreditation_aim: [],
      engagement_student_initiatives: [],
    },
    admin: {
      'system-users': [],
      snapshots: [],
      institutions: [],
      qualifications: [],
      sessionYear: [],
      venues: [],
    },
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    case types.SET_CONTACT_DESCRIPTIONS:
      return {
        ...state,
        descriptions: {
          ...state.descriptions,
          contact: {
            ...state.descriptions.contact,
            [action.payload.entity]: {
              ...state.descriptions.contact[action.payload.entity],
              [action.payload.entity_type]: action.payload.data,
            },
          },
        },
      }

    case types.SET_ROLLOVER_DESCRIPTION:
      return {
        ...state,
        descriptions: {
          ...state.descriptions,
          contact: {
            ...state.descriptions.contact,
            [action.payload.entity]: {
              ...state.descriptions.contact[action.payload.entity],
              [action.payload.entity_type]: action.payload.data,
            },
          },
        },
      }

    case types.SET_DESCRIPTIONS:
      return {
        ...state,
        descriptions: {
          ...state.descriptions,
          [action.payload.entity]: {
            ...state.descriptions[action.payload.entity],
            [action.payload.entity_type]: action.payload.data,
          },
        },
      }
    default:
      return state
  }
}
