import React from 'react'
import classes from './style.module.scss'
import { ArrowIcon } from '@Root/assets'
import { CancelButton, CheckboxInput, InputLabel, SaveButton } from '@Root/components'

export class AdvancedAdditionalAccordionManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isSuccess && !prevProps.isSuccess) {
      this.setState({ opened: false })
    }
  }

  onChange = (label, entity) => () => {
    const { data, onDataChange, permissionType } = this.props
    const modifiedData = data.map(item =>
      item.label === label
        ? {
            ...item,
            fields: item.fields.map(field => {
              if (field.entity !== entity) return field
              return {
                ...field,
                permissions: field.permissions.includes(permissionType.label)
                  ? field.permissions.filter(permission => permission !== permissionType.label)
                  : [...field.permissions, permissionType.label],
              }
            }),
          }
        : item
    )
    onDataChange(modifiedData)
  }

  onTickAll = () => {
    const { data, onDataChange, permissionType } = this.props
    const modifiedData = data.map(item => ({
      ...item,
      fields: item.fields.map(field => ({
        ...field,
        permissions: field.permissions.includes(permissionType.label) ? field.permissions : [...field.permissions, permissionType.label],
      })),
    }))
    onDataChange(modifiedData)
  }

  onUnTickAll = () => {
    const { data, onDataChange, permissionType } = this.props
    const modifiedData = data.map(item => ({
      ...item,
      fields: item.fields.map(field => ({
        ...field,
        permissions: field.permissions.includes(permissionType.label)
          ? field.permissions.filter(permission => permission !== permissionType.label)
          : field.permissions,
      })),
    }))
    onDataChange(modifiedData)
  }

  render() {
    const {
      state: { opened },
      props: { isEditable, permissionType, data = [] },
      onUnTickAll,
      onTickAll,
    } = this
    const { label: labelOwn } = permissionType

    return (
      <div className={`${classes.accordion_item}, ${opened && `${classes.accordion_item__opened}`}`}>
        <div
          className={`${classes.accordion_item__line}`}
          onClick={() => {
            this.setState({ opened: !opened })
          }}
        >
          <h3 className={classes.accordion_item__title}>{permissionType.title}</h3>
          <img className={classes.accordion_item__icon} src={ArrowIcon} alt={'arrow'} />
        </div>
        <div className={classes.accordion_item__inner}>
          <div className={classes.grid}>
            {data.length > 0 &&
              data?.map(({ fields, title, label: tabLabel }) => {
                return (
                  <div className={classes.column}>
                    <h2 className={classes.title}>{title}</h2>
                    <div className={classes.fields}>
                      {fields.map(({ entity, label, permissions, except }) => {
                        const isHide = except.includes(labelOwn)
                        const isChecked = permissions.includes(permissionType.label)
                        return isHide ? (
                          <></>
                        ) : (
                          <div className={classes.item} key={label}>
                            <InputLabel text={label} />
                            <CheckboxInput isChecked={isChecked} changeHandler={this.onChange(tabLabel, entity)} isDisabled={!isEditable} />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
          </div>
          <div className={classes.actions}>
            <CancelButton onClick={onUnTickAll}>Untick all</CancelButton>
            <SaveButton onClick={onTickAll}>Tick all</SaveButton>
          </div>
        </div>
      </div>
    )
  }
}
