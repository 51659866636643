import React, { useEffect, useState, useRef } from 'react'
import * as classes from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'
import { booleanOptions } from '@Root/configs'
import { validateData } from '@Root/helpers'
import { SubformWrapper } from '@Root/HOCs'

const titles = { onEdit: 'Create Student Course Session', onRead: 'Student Course Session' }
export const StudyRecordProgrammeForm = ({
  initialValue,
  onCancel,
  isNew,
  onSave,
  onDelete,
  title,
  initialErrors,
  isSuccess,
  isLoading,
  isDuplicate,
  onOpenDescriptionModal,
  options,
  descriptions,
  permissions,
}) => {
  const [data, setData] = useState({})
  const [isOpenedAccordion, setIsOpenedAccordion] = useState(false)
  const { errors, setError, onClear: onClearError } = useError(isDuplicate)
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const memoData = useRef({})

  useEffect(() => {
    if (isNew) {
      toggleEditable(isNew)
    }
  }, [isNew])

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      memoData.current = initialValue
    }
  }, [isSuccess, toggleEditable])

  useEffect(() => {
    if (initialValue) setData(initialValue)
    if (!memoData.current?.student_course_session_identifier_id) memoData.current = initialValue
  }, [initialValue])

  //NOTE:Rewrite like in additional fields (admin page)
  useEffect(() => {
    if (initialErrors.includes('status_id')) {
      setError('status_id', 'A study record for this student already exists.')
    }
    if (initialErrors.includes('academic_year')) {
      setError('academic_year', 'A study record for this student already exists.')
    }
    if (initialErrors.includes('engagement_id')) {
      setError('engagement_id', 'A study record for this student already exists.')
    }
    if (initialErrors.includes('course_identifier')) {
      setError('course_identifier', 'A study record for this student already exists.')
    }
    if (initialErrors.includes('session_year_id')) {
      setError('session_year_id', 'A session year for this student already exists.')
    }
    if (initialErrors.includes('student_course_session_identifier_id')) {
      setError('student_course_session_identifier_id', 'A study record for this student already exists.')
    }

    if (initialErrors.includes('predicted_student_load')) {
      setError('predicted_student_load', 'Invalid value, the field must be number')
    }
  }, [initialErrors])

  const validateForm = () => {
    const {
      program_id,
      academic_year,
      study_years,
      credits,
      status_id,
      engagement_id,
      student_course_session_identifier_id,
      expected_end_date,
      phd_submission_date,
      course_session_start_date,
      student_course_session_end_date
    } = data
    if (!program_id) {
      setError('program_id', 'Required')
      return false
    }
    if (!student_course_session_identifier_id) {
      setError('student_course_session_identifier_id', 'Required')
      return false
    }
    if (!academic_year) {
      setError('academic_year', 'Required')
      return false
    }
    if (!engagement_id) {
      setError('engagement_id', 'Required')
      return false
    }
    if (!study_years) {
      setError('study_years', 'Required')
      return false
    }
    if (!credits) {
      setError('credits', 'Required')
      return false
    }
    if (!status_id) {
      setError('status_id', 'Required')
      return false
    }
    if (
      validateData([
        expected_end_date,
        phd_submission_date,
        course_session_start_date,
        student_course_session_end_date
      ])
    ) {
      return false
    }
    return true
  }

  const onChangeValue = field => value => {
    setError(field, null)
    if (field === 'program_id') {
      const currentProgram = [...options.programmes].find(el => el.value === value)
      if (value && currentProgram) {
        setData(prevState => ({ ...prevState, [field]: value, course_identifier: currentProgram.course_identifier }))
      } else {
        setData(prevState => ({ ...prevState, [field]: value, course_identifier: null }))
      }
    } else {
      setData(prevState => ({ ...prevState, [field]: value }))
    }
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onClearError()
    onSave(data)
  }

  const onCancelEdit = () => {
    if (!isNew) {
      toggleEditable(false)
      onCancel(memoData.current)
      setData(memoData.current)
      onClearError()
    } else {
      onCancel()
      if (isDuplicate) toggleEditable()
    }
  }

  const onChangeReasonForSessionEnd = (field, subField, value) => {
    setData(prevState => ({ ...prevState, [field]: { ...prevState[field], [subField]: value } }))
  }

  //const selectedProgramme = useMemo(() => options?.programmes?.find(programme => programme.value === data.program_id), [data.program_id, options?.programmes])

  return (
    <div className={classes.StudiesProgrammeForm}>
      <FormWrapper
        buttons={isEditable ? (!isNew ? ['save', 'cancel', permissions.form?.delete ? 'delete' : ''] : ['save', 'cancel']) : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Study Record' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        canEdit={permissions.form?.edit}
        clickToggleEditModeButtonHandler={toggleEditable}
        isEditable={isEditable}
        readTitle={titles.onRead}
        editTitle={title?.onEdit || titles.onEdit}
        idForm={data?.id ? `student_course_session_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={classes.wrapper}>
          <div className={classes.column}>
            <div className={classes.row}>
              <Field
                label={'Programme Name'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.program_id}
                onChange={onChangeValue('program_id')}
                placeholder='Programme Name'
                isDisabled={!isEditable || !permissions.fields?.program_id?.edit}
                isRequired={isEditable}
                maxLength={2}
                options={options.programmes}
                description={descriptions.program_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.program_id}
                field={'select'}
                isView={permissions.fields?.program_id?.view}
                errorIsInfinite={isDuplicate}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Engagement number'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.engagement_id}
                onChange={onChangeValue('engagement_id')}
                placeholder='Engagement number'
                isDisabled={!isEditable || !permissions.fields?.engagement_id?.edit}
                isRequired={isEditable}
                maxLength={2}
                options={options.engagement_ids}
                description={descriptions.engagement_number}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.engagement_id}
                field={'select'}
                isView={permissions.fields?.engagement_id?.view}
                errorIsInfinite={isDuplicate}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Student course session identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data?.student_course_session_identifier_id}
                onChange={onChangeValue('student_course_session_identifier_id')}
                placeholder='Student course session identifier'
                isDisabled={!isEditable || !permissions.fields?.student_course_session_identifier_id?.edit}
                isRequired={isEditable}
                maxLength={50}
                description={descriptions.student_course_session_identifier}
                error={errors.student_course_session_identifier_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                isView={permissions.fields?.student_course_session_identifier_id?.view}
                errorIsInfinite={isDuplicate}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Course identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data?.course_identifier}
                onChange={onChangeValue('course_identifier')}
                placeholder='Course identifier'
                //isDisabled={!isEditable || !permissions.fields?.course_identifier?.edit}
                isDisabled
                isRequired={isEditable}
                maxLength={50}
                description={descriptions.course_identifier}
                error={errors.course_identifier}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                isView={permissions.fields?.course_identifier?.view}
                errorIsInfinite={isDuplicate}
              />
            </div>{' '}

            <div className={classes.row}>
              <Field
                label={'Academic Year'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.academic_year}
                onChange={onChangeValue('academic_year')}
                placeholder='Academic Year'
                isDisabled={!isEditable || !permissions.fields?.academic_year?.edit}
                isRequired={isEditable}
                maxLength={2}
                options={options.academic_years_list}
                description={descriptions.academic_year}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.academic_year}
                field={'select'}
                isView={permissions.fields?.academic_year?.view}
                errorIsInfinite={isDuplicate}
              />
            </div>{' '}
            <div className={classes.row}>
              <Field
                label={'Study Year'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.study_years}
                onChange={onChangeValue('study_years')}
                placeholder='Study Year'
                isDisabled={!isEditable || !permissions.fields?.study_years?.edit}
                isRequired={isEditable}
                maxLength={2}
                options={options.study_years}
                description={descriptions.study_years}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.study_years}
                field={'select'}
                isView={permissions.fields?.study_years?.view}
              />
            </div>{' '}
            <div className={classes.row}>
              <Field
                label={'Context/placement'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.context_id}
                onChange={onChangeValue('context_id')}
                placeholder='Context/placement'
                isDisabled={!isEditable || !permissions.fields?.context_id?.edit}
                maxLength={2}
                options={options.context}
                description={descriptions.context_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.context_id}
                field={'select'}
                isView={permissions.fields?.context_id?.view}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Auditing?'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.auditing}
                onChange={onChangeValue('auditing')}
                placeholder='Auditing'
                isDisabled={!isEditable || !permissions.fields?.auditing?.edit}
                maxLength={2}
                options={booleanOptions}
                description={descriptions.auditing}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.auditing}
                field={'select'}
                isView={permissions.fields?.auditing?.view}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Credits'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.credits}
                onChange={onChangeValue('credits')}
                placeholder='Credits'
                isDisabled={!isEditable || !permissions.fields?.credits?.edit}
                isRequired={isEditable}
                description={descriptions.credits}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.credits}
                field={'textField'}
                isView={permissions.fields?.credits?.view}
              />
            </div>{' '}
            <div className={classes.row}>
              <Field
                label={'SLC Loan?'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.SLC_Loan_id}
                onChange={onChangeValue('SLC_Loan_id')}
                placeholder='SLC Loan?'
                isDisabled={!isEditable || !permissions.fields?.SLC_Loan_id?.edit}
                options={booleanOptions}
                description={descriptions.SLC_Loan_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.SLC_Loan_id?.view}
              />
            </div>{' '}
            <div className={classes.row}>
              <Field
                label={'Session Year'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data?.session_year_id}
                onChange={onChangeValue('session_year_id')}
                placeholder='Session Year'
                isDisabled={!isEditable || !permissions.fields?.session_year_id?.edit}
                options={options.session_years}
                description={descriptions.session_year_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.session_year_id?.view}
                error={errors.session_year_id}
                errorIsInfinite={isDuplicate}
              />
            </div>
            {data.import && (
              <>
                <div className={classes.row}>
                  <Field
                    label={'ID'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    value={data?.ID}
                    onChange={onChangeValue('ID')}
                    placeholder='ID'
                    isDisabled={!isEditable || !permissions.fields?.ID?.edit}
                    description={descriptions.ID}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'textField'}
                    isView={permissions.fields?.ID?.view}
                  />
                </div>{' '}
                <div className={classes.row}>
                  <Field
                    label={'Trashed'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    value={data?.trashed}
                    onChange={onChangeValue('trashed')}
                    placeholder='Trashed'
                    isDisabled={!isEditable || !permissions.fields?.trashed?.edit}
                    description={descriptions.trashed}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'textField'}
                    isView={permissions.fields?.trashed?.view}
                  />
                </div>{' '}
                <div className={classes.row}>
                  <Field
                    label={'Statusold'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    value={data?.statusold}
                    onChange={onChangeValue('statusold')}
                    placeholder='Statusold'
                    isDisabled={!isEditable || !permissions.fields?.statusold?.edit}
                    description={descriptions.statusold}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'textField'}
                    isView={permissions.fields?.statusold?.view}
                  />
                </div>{' '}
                <div className={classes.row}>
                  <Field
                    label={'Sselig'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    value={data?.sselig}
                    onChange={onChangeValue('sselig')}
                    placeholder='Sselig'
                    isDisabled={true}
                    description={descriptions.sselig}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'textField'}
                    isView={permissions.fields?.sselig?.view}
                  />
                </div>{' '}
                <div className={classes.row}>
                  <Field
                    label={'Future prog'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    value={data?.future_prog}
                    onChange={onChangeValue('future_prog')}
                    placeholder='Future prog'
                    isDisabled={true}
                    description={descriptions.future_prog}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'textField'}
                    isView={permissions.fields?.future_prog?.view}
                  />
                </div>
              </>
            )}
            <div className={classes.row}>
              <Field
                label={'Invoice fee amount'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data?.invoice_fee_amount}
                onChange={onChangeValue('invoice_fee_amount')}
                placeholder='Invoice fee amount'
                isDisabled={!isEditable || !permissions.fields?.invoice_fee_amount?.edit}
                maxLength={6}
                description={descriptions.invoice_fee_amount}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                restriction='digits'
                isView={permissions.fields?.invoice_fee_amount?.view}
              />
            </div>{' '}
            <div className={classes.row}>
              <Field
                label={'Invoice HESA identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.invoice_hesa_identifier_id}
                onChange={onChangeValue('invoice_hesa_identifier_id')}
                placeholder='Invoice HESA identifier'
                isDisabled={!isEditable || !permissions.fields?.invoice_hesa_identifier_id?.edit}
                maxLength={4}
                options={options.invoice_hesa_identifiers}
                description={descriptions.invoice_hesa_identifier}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.invoice_hesa_identifier_id?.view}
              />
            </div>{' '}

          </div>
          <div className={classes.column}>

            <div className={classes.row}>
              <Field
                label={'Student Course Session Start Date'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data?.course_session_start_date}
                onChange={onChangeValue('course_session_start_date')}
                placeholder='Student Course Session Start Date'
                isDisabled={!isEditable || !permissions.fields?.course_session_start_date?.edit}
                description={descriptions.course_session_start_date}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'datePicker'}
                isView={permissions.fields?.course_session_start_date?.view}
              />
            </div>{' '}
            <div className={classes.row}>
              <Field
                label={'Student course session fee amount'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data?.student_course_session_fee_amount}
                onChange={onChangeValue('student_course_session_fee_amount')}
                placeholder='Student course session fee amount'
                isDisabled={!isEditable || !permissions.fields?.student_course_session_fee_amount?.edit}
                maxLength={6}
                description={descriptions.student_course_session_fee_amount}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                isView={permissions.fields?.student_course_session_fee_amount?.view}
                restriction='digits'
              />
            </div>{' '}
            <div className={classes.row}>
              <Field
                label={'Student course session mode'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.student_course_session_mode_id}
                onChange={onChangeValue('student_course_session_mode_id')}
                placeholder='Student course session mode'
                isDisabled={!isEditable || !permissions.fields?.student_course_session_mode_id?.edit}
                maxLength={2}
                options={options.student_course_session_modes}
                description={descriptions.student_course_session_mode}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.student_course_session_mode_id?.view}
              />
            </div>{' '}
            <div className={classes.row}>
              <Field
                label={'HESA Reason for Session Ending'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data?.HESA_reason_course_session?.id}
                onChange={value => onChangeReasonForSessionEnd('HESA_reason_course_session', 'id', value)}
                placeholder='HESA Reason for Session Ending'
                isDisabled={!isEditable || !permissions.fields?.HESA_reason_course_session?.edit}
                options={options.reason_for_student_course_session_ending}
                description={descriptions.HESA_reason_course_session}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.HESA_reason_course_session?.view}
              />
            </div>{' '}
            {data?.HESA_reason_course_session?.id && (
              <div className={classes.row}>
                <Field
                  label={'Note for Session Ending'}
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={data?.HESA_reason_course_session?.reason_note}
                  onChange={value => onChangeReasonForSessionEnd('HESA_reason_course_session', 'reason_note', value)}
                  placeholder='HESA Reason for Session Ending'
                  isDisabled={!isEditable || !permissions.fields?.HESA_reason_course_session?.edit}
                  description={descriptions.reason_note}
                  onOpenDescriptionModal={onOpenDescriptionModal}
                  field={'textField'}
                  isView={permissions.fields?.HESA_reason_course_session?.view}
                />
              </div>
            )}
            <div className={classes.row}>
              <Field
                label={'Student course session end date'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data?.student_course_session_end_date}
                onChange={onChangeValue('student_course_session_end_date')}
                placeholder='Student course session end date'
                isDisabled={!isEditable || !permissions.fields?.student_course_session_end_date?.edit}
                maxLength={10}
                description={descriptions.course_session_end_date}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'datePicker'}
                isView={permissions.fields?.student_course_session_end_date?.view}
              />
            </div>{' '}
            <div className={classes.row}>
              <Field
                label={'Study abroad'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data?.study_abroad_id}
                onChange={onChangeValue('study_abroad_id')}
                placeholder='Study abroad'
                isDisabled={!isEditable || !permissions.fields?.study_abroad_id?.edit}
                maxLength={2}
                options={options.study_abroads}
                description={descriptions.study_abroad_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.study_abroad_id?.view}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Status'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.status_id}
                onChange={onChangeValue('status_id')}
                placeholder='Status'
                isDisabled={!isEditable || !permissions.fields?.status_id?.edit}
                isRequired={isEditable}
                maxLength={2}
                options={options.status}
                description={descriptions.status_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.status_id}
                field={'select'}
                isView={permissions.fields?.status_id?.view}
                errorIsInfinite={isDuplicate}
              />
            </div>{' '}
            <div className={classes.row}>
              <Field
                label={'Fee Status'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.fee_status_id}
                onChange={onChangeValue('fee_status_id')}
                placeholder='Fee Status'
                isDisabled={!isEditable || !permissions.fields?.fee_status_id?.edit}
                maxLength={2}
                options={options.fee_status}
                description={descriptions.fee_status_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.fee_status_id?.view}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Programme Year'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.program_years_id}
                onChange={onChangeValue('program_years_id')}
                placeholder='Programme Year'
                isDisabled={!isEditable || !permissions.fields?.program_years_id?.edit}
                maxLength={2}
                options={options.program_years_list}
                description={descriptions.program_years_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.program_years_id?.view}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Ordinand'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.ordinand_id}
                onChange={onChangeValue('ordinand_id')}
                placeholder='Ordinand'
                isDisabled={!isEditable || !permissions.fields?.ordinand_id?.edit}
                maxLength={2}
                options={options.ordinand}
                description={descriptions.ordinand_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.ordinand_id}
                field={'select'}
                isView={permissions.fields?.ordinand_id?.view}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Learning Center'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.learning_centre_id}
                onChange={onChangeValue('learning_centre_id')}
                placeholder='Learning Center'
                isDisabled={!isEditable || !permissions.fields?.learning_centre_id?.edit}
                maxLength={2}
                options={options.learning_centre}
                description={descriptions.learning_centre_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.learning_centre_id}
                field={'select'}
                isView={permissions.fields?.learning_centre_id?.view}
              />
            </div>{' '}
            <div className={classes.row}>
              <Field
                label={'Accommodation'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.accommodation_id}
                onChange={onChangeValue('accommodation_id')}
                placeholder='Accommodation'
                isDisabled={!isEditable || !permissions.fields?.accommodation_id?.edit}
                maxLength={2}
                options={options.accommodation}
                description={descriptions.accommodation_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.accommodation_id?.view}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Sponsor'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.sponsor_id}
                onChange={onChangeValue('sponsor_id')}
                placeholder='Sponsor'
                isDisabled={!isEditable || !permissions.fields?.sponsor_id?.edit}
                maxLength={2}
                options={options.sponsors}
                description={descriptions.sponsor_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.sponsor_id?.view}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Diocese'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.diocese_id}
                onChange={onChangeValue('diocese_id')}
                placeholder='Diocese'
                isDisabled={!isEditable || !permissions.fields?.diocese_id?.edit}
                maxLength={2}
                options={options.diocese}
                description={descriptions.diocese_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.diocese_id?.view}
              />
            </div>
            <div className={classes.row}>
              <Field
                label={'Pastoral Tutor'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                optionsStyle={{ width: 260 }}
                value={data?.pastoral_tutor}
                onChange={onChangeValue('pastoral_tutor')}
                placeholder='Pastoral Tutor'
                isDisabled={!isEditable || !permissions.fields?.pastoral_tutor?.edit}
                maxLength={2}
                options={options.pastoral_tutors}
                description={descriptions.pastoral_tutor}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={permissions.fields?.pastoral_tutor?.view}
              />
            </div>

            <div className={classes.row}>
              <Field
                label={'Do not include in HESA'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data?.include_in_hesa}
                onChange={onChangeValue('include_in_hesa')}
                isDisabled={!isEditable || !permissions.fields?.include_in_hesa?.edit}
                description={descriptions.include_in_hesa}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'checkbox'}
                isView={permissions.fields?.include_in_hesa?.view}
              />
            </div>
          </div>
          <div className={classes.column}>
            <div className={classes.wrapperColumn}>
              <SubformWrapper
                title={"Unused fields"}
                isRoot
                isOnlyForView
                style={{ content: { padding: "10px" } }}
                isOpened={isOpenedAccordion}
                isOpenedHandler={() => { setIsOpenedAccordion(!isOpenedAccordion) }}
              >
                <div className={classes.row}>
                  <Field
                    label={'Employer fees'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    optionsStyle={{ width: 260 }}
                    value={data?.employer_fee_id}
                    onChange={onChangeValue('employer_fee_id')}
                    placeholder='Employer fees'
                    isDisabled={!isEditable || !permissions.fields?.employer_fee_id?.edit}
                    maxLength={2}
                    options={options.employer_fees}
                    description={descriptions.employer_fee}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'select'}
                    isView={permissions.fields?.employer_fee_id?.view}
                  />
                </div>
                <div className={classes.row}>
                  <Field
                    label={'Expected end date'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    optionsStyle={{ width: 260 }}
                    value={data?.expected_end_date}
                    onChange={onChangeValue('expected_end_date')}
                    placeholder='Expected end date'
                    isDisabled={!isEditable || !permissions.fields?.expected_end_date?.edit}
                    maxLength={10}
                    description={descriptions.expected_end_date}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'datePicker'}
                    isView={permissions.fields?.expected_end_date?.view}
                  />
                </div>
                <div className={classes.row}>
                  <Field
                    label={'Fee method'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    optionsStyle={{ width: 260 }}
                    value={data?.fee_method_id}
                    onChange={onChangeValue('fee_method_id')}
                    placeholder='Fee method'
                    isDisabled={!isEditable || !permissions.fields?.fee_method_id?.edit}
                    maxLength={2}
                    options={options.fee_methods}
                    description={descriptions.fee_method}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'select'}
                    isView={permissions.fields?.fee_method_id?.view}
                  />
                </div>
                <div className={classes.row}>
                  <Field
                    label={'Intercalation'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    listStyle={{ width: 260 }}
                    value={data?.intercalation_id}
                    onChange={onChangeValue('intercalation_id')}
                    placeholder='Intercalation'
                    isDisabled={!isEditable || !permissions.fields?.intercalation_id?.edit}
                    maxLength={2}
                    description={descriptions.intercalation}
                    options={options.intercalations}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'select'}
                    isView={permissions.fields?.intercalation_id?.view}
                  />
                </div>
                <div className={classes.row}>
                  <Field
                    label={'PGR language identifier'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    optionsStyle={{ width: 260 }}
                    value={data?.pgr_language_identifier_id}
                    onChange={onChangeValue('pgr_language_identifier_id')}
                    placeholder='PGR language identifier'
                    isDisabled={!isEditable || !permissions.fields?.pgr_language_identifier_id?.edit}
                    maxLength={2}
                    options={options.pgr_language_identifiers}
                    description={descriptions.pgr_language_identifier}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'select'}
                    isView={permissions.fields?.pgr_language_identifier_id?.view}
                  />
                </div>
                <div className={classes.row}>
                  <Field
                    label={'PGR language percentage'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    value={data?.pgr_language_percentage}
                    onChange={onChangeValue('pgr_language_percentage')}
                    placeholder='PGR language percentage'
                    isDisabled={!isEditable || !permissions.fields?.pgr_language_percentage?.edit}
                    maxLength={4}
                    description={descriptions.pgr_language_percentage}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'textField'}
                    isView={permissions.fields?.pgr_language_percentage?.view}
                  />
                </div>
                <div className={classes.row}>
                  <Field
                    label={'Placement'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    optionsStyle={{ width: 260 }}
                    value={data?.placement_id}
                    onChange={onChangeValue('placement_id')}
                    placeholder='Placement'
                    isDisabled={!isEditable || !permissions.fields?.placement_id?.edit}
                    maxLength={2}
                    options={options.placements}
                    description={descriptions.placement}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'select'}
                    isView={permissions.fields?.placement_id?.view}
                  />
                </div>
                <div className={classes.row}>
                  <Field
                    label={'Predicted student load'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    value={data?.predicted_student_load}
                    onChange={onChangeValue('predicted_student_load')}
                    placeholder='Predicted student load'
                    isDisabled={!isEditable || !permissions.fields?.predicted_student_load?.edit}
                    maxLength={4}
                    description={descriptions.predicted_student_load}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'textField'}
                    isView={permissions.fields?.predicted_student_load?.view}
                    error={errors.predicted_student_load}
                  />
                </div>
                <div className={classes.row}>
                  <Field
                    label={'Preparatory Student course session flag'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    optionsStyle={{ width: 260 }}
                    value={data?.preparatory_student_course_session_flag_id}
                    onChange={onChangeValue('preparatory_student_course_session_flag_id')}
                    placeholder='Preparatory Student course session flag'
                    isDisabled={!isEditable || !permissions.fields?.preparatory_student_course_session_flag_id?.edit}
                    maxLength={2}
                    options={options.preparatory_student_course_session_flags}
                    description={descriptions.preparatory_student_course_session_flag}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'select'}
                    isView={permissions.fields?.preparatory_student_course_session_flag_id?.view}
                  />
                </div>
                <div className={classes.row}>
                  <Field
                    label={'Fee Status'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    optionsStyle={{ width: 260 }}
                    value={data?.fee_status_id}
                    onChange={onChangeValue('fee_status_id')}
                    placeholder='Fee Status'
                    isDisabled={!isEditable || !permissions.fields?.fee_status_id?.edit}
                    maxLength={2}
                    options={options.fee_status}
                    description={descriptions.fee_status_id}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'select'}
                    isView={permissions.fields?.fee_status_id?.view}
                  />
                </div>
                <div className={classes.row}>
                  <Field
                    label={'Intended Thesis Title'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    optionsStyle={{ width: 260 }}
                    value={data?.intended_thesis_title}
                    onChange={onChangeValue('intended_thesis_title')}
                    placeholder='Intended Thesis Title'
                    isDisabled={!isEditable || !permissions.fields?.intended_thesis_title?.edit}
                    maxLength={500}
                    description={descriptions.intended_thesis_title}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'textArea'}
                    isView={permissions.fields?.intended_thesis_title?.view}
                  />
                </div>
                <div className={classes.row}>
                  <Field
                    label={'PhD Submission Date'}
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260, left: -20 }}
                    value={data?.phd_submission_date}
                    onChange={onChangeValue('phd_submission_date')}
                    placeholder='PhD Submission Date'
                    isDisabled={!isEditable || !permissions.fields?.phd_submission_date?.edit}
                    maxLength={10}
                    description={descriptions.phd_submission_date}
                    onOpenDescriptionModal={onOpenDescriptionModal}
                    field={'datePicker'}
                    isView={permissions.fields?.phd_submission_date?.view}
                  />
                </div>
              </SubformWrapper>
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}
