import { all, call, put, takeEvery } from 'redux-saga/effects'
import { API } from '@Root/API'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage } from '@Root/helpers'
import { actions } from './church.actions'
import * as types from './church.types'
import { contactActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { contactId } = payload
  try {
    const { data } = yield call(API.contact.organisation.church.get, contactId)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.contact.organisation.church.edit, payload)
    yield put(actions.set(data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The church information has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  const { contactId, relationshipId, history } = payload
  yield put(actions.toggleLoading(true))
  try {
    yield call(API.contact.organisation.church.remove, contactId, relationshipId)
    yield put(contactActions.get({ contactId }))
    yield put(actions.set(null))
    history.push(`/home/contacts/all-contacts/${contactId}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The church information has been deleted successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(contactActions.toggleLoading(false))
}

function* getStaticOptions() {
  try {
    const response = yield call(API.contact.organisation.church.options.getStatic)
    const formattedData = {
      institutions: response[0].data.data.map(obj => ({ value: obj.id, label: obj.name })),
    }
    yield put(actions.setOptions({ name: 'static', data: formattedData }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

function* getDynamicOptions() {
  try {
    const { data } = yield call(API.contact.organisation.church.options.getDynamic)
    const formattedData = {
      diocese: [...data].map(({ id, label }) => ({ value: id, label })),
    }
    yield put(actions.setOptions({ name: 'dynamic', data: formattedData }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_CHURCH, get),
    takeEvery(types.EDIT_CHURCH, edit),
    takeEvery(types.DELETE_CHURCH, remove),
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
    takeEvery(types.GET_DYNAMIC_OPTIONS, getDynamicOptions),
  ])
}
