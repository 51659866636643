import React, { useCallback, useMemo } from 'react'
import classes from './style.module.scss'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { authActions, authSelectors } from '@Root/store'
import { Spinner, Header } from '@Root/components'

import { SearchPage } from './SearchPage'
import { ContactsPage } from './Contacts'
import { StudiesPage } from './Studies'
import { AdmissionsPage } from './Admissions'
import { FinancePage } from './Finance'
import { GDPRPage } from './GDPR'
import { AdminPage } from './Admin'
import { Security } from './Security'
import { ModalWrapper } from '@Root/HOCs'
import { HelpTextPage } from './HelpText'
import { pagesLinks } from '@Root/configs'
import { RedirectUnAuthUser } from '@Root/processes'
import { useHistory } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'

export const HomePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(authSelectors.user)
  const isLoading = useSelector(authSelectors.isLoading)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const tabsPermissions = useSelector(permissionsSelectors.tabsPermissions)

  const onLogout = () => {
    dispatch(authActions.logOut(history))
  }

  const formattedLinks = useMemo(() => pagesLinks.filter(link => tabsPermissions?.[link.label || link.path]?.view), [pagesLinks, tabsPermissions])

  const canBeRedirected = useMemo(() => (tabsPermissions ? Object.entries(tabsPermissions).some(([_, item]) => item.view) : false), [tabsPermissions])
  const redirectToHome = useCallback(
    redirectFrom => {
      return canBeRedirected ? <Redirect from={redirectFrom} to='/home/contacts' /> : <Redirect to='/page-not-found' />
    },
    [canBeRedirected]
  )
  const canTwoFA = user.reset_id !== 3
  return (
    <RedirectUnAuthUser>
      <div className={classes.wrapper}>
        {!isLoading && dataCreationPermissions && tabsPermissions && Object.keys(user)?.length > 0 ? (
          <>
            <Header
              links={formattedLinks}
              userName={user.name}
              userIcon={user.photo}
              path={user.id ? `/home/admin/manage-system-team/system-users/${user.id}` : null}
              logOut={onLogout}
              canTwoFA={canTwoFA}
            />

            <Switch>
              <Redirect exact from='/home' to='/home/contacts' />
              {canTwoFA && (
                <Route
                  path='/home/security'
                  component={() => <Security isDisable={user?.google2fa_active} canDeactivated={user?.prevent_turning_off || user?.force_activated} />}
                />
              )}

              {tabsPermissions.contacts?.view ? <Route path='/home/contacts' component={ContactsPage} /> : redirectToHome('/home/contacts')}
              {tabsPermissions.studies?.view ? <Route path='/home/studies' render={() => <StudiesPage />} /> : redirectToHome('/home/studies')}
              {tabsPermissions.admissions?.view ? <Route path='/home/admissions' render={() => <AdmissionsPage />} /> : redirectToHome('/home/admissions')}
              {tabsPermissions.finance?.view ? <Route path='/home/finance' render={() => <FinancePage />} /> : redirectToHome('/home/finance')}
              {tabsPermissions.gdpr?.view ? <Route path='/home/gdpr' render={() => <GDPRPage />} /> : redirectToHome('/home/gdpr')}
              {tabsPermissions.admin?.view ? <Route path='/home/admin' render={() => <AdminPage />} /> : redirectToHome('/home/admin')}
              {tabsPermissions.help_text?.view ? <Route path='/home/help-text' render={() => <HelpTextPage />} /> : redirectToHome('/home/help-text')}
              <Route path='/home/teid-search' render={() => <SearchPage />} />
              <Redirect to='/page-not-found' />
            </Switch>
          </>
        ) : (
          <Spinner style={{ position: 'absolute' }} size='big' />
        )}
        <ModalWrapper />
      </div>
    </RedirectUnAuthUser>
  )
}
