import React from 'react'
import styles from './style.module.scss'

export const CancelButton = ({ children, onClick, disabled, style, ...res }) => {
	return (
		<button className={styles.button} onClick={onClick} disabled={disabled} style={style} {...res}>
			{children}
		</button>
	)
}
