import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { all, regularExpressions } from '@Root/helpers'
import eyeIcon from '../../../assets/icons/eye.png'
import eyeWithLineIcon from '../../../assets/icons/eye-with-line.png'
import { Spinner } from '@Root/components'
import { DATE, NO_DATA_AVAILABLE } from '@Root/configs'

export class TextInput extends Component {
  state = {
    error: null,
    passwordIsShown: false,
  }

  showError = error => {
    this.setState({ error })
  }

  handleChange = event => {
    const { restriction, changeHandler } = this.props
    const value = event.target.value
    const validity = event.target.validity

    if (validity.patternMismatch) {
      event.target.setCustomValidity(`Please, match the date format: ${DATE.format}`)
    } else {
      event.target.setCustomValidity(``)
    }

    if (restriction) {
      const valueIsAllowed = (value, regularExpression) => {
        return value.match(regularExpression)
      }
      ;(value === '' || valueIsAllowed(value, regularExpressions[restriction])) && changeHandler(value)
    } else {
      changeHandler(value)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      if (this.props.error) {
        this.showError(this.props.error)
      } else {
        //if (this.props.errorIsInfinite )
        this.setState({ error: null })
      }
    }
  }

  render() {
    const { error, passwordIsShown } = this.state
    const {
      value,
      blurHandler = () => {},
      isDisabled,
      isPassword,
      placeholder,
      classNames = [],
      style,
      isSpinning,
      maxLength,
      pattern,
      autoFocus = false,
    } = this.props
    const filteredValue = value ? value : ''
    const { handleChange } = this
    const isNotDataAvailable = isDisabled && value === NO_DATA_AVAILABLE.label
    return (
      <div
        className={`${classes.TextInput} ${classNames.reduce((acc, className) => acc + ` ${classes[className]}`, '')}`}
        style={{ ...style, borderColor: error ? '#D0021B' : null }}
      >
        <input
          className={classes.input}
          style={isPassword ? { paddingRight: 30 } : null}
          type={isPassword && !passwordIsShown ? 'password' : 'text'}
          value={!isNotDataAvailable ? filteredValue : NO_DATA_AVAILABLE.value}
          onChange={e =>
            all(
              () => handleChange(e)
              //() => this.setState({ error: null })
            )
          }
          onBlur={() => blurHandler()}
          disabled={isDisabled}
          placeholder={placeholder}
          maxLength={maxLength}
          pattern={pattern}
          autoFocus={autoFocus}
        />
        {isSpinning && (
          <div className={classes.spinner}>
            <Spinner size='extra-small' color='dark' />
          </div>
        )}
        {isPassword && value && (
          <div className={classes.eyeIcon} onClick={() => this.setState({ passwordIsShown: !passwordIsShown })}>
            <img src={passwordIsShown ? eyeWithLineIcon : eyeIcon} alt='' />
          </div>
        )}
        {error && <div className={classes.error}>{error}*</div>}
      </div>
    )
  }
}

TextInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  isPassword: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  errorIsInfinite: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.oneOf(['borderless', 'transparent'])),
  style: PropTypes.object,
  restriction: PropTypes.oneOf(['letters', 'lettersAndWhiteSpaces', 'digits', 'email', 'betweenZeroAndHundred', 'betweenOneAndHundred']),
  maxLength: PropTypes.number,
  isSpinning: PropTypes.bool,
}

TextInput.defaultProps = {
  changeHandler: () => {},
  isPassword: false,
  errorIsInfinite: false,
  isSpinning: false,
  maxLength: Infinity,
}
