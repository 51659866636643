import React, { useEffect, useCallback } from 'react'
import classes from './style.module.scss'
import { useDispatch } from 'react-redux'
import { programmeActions, modalActions, engagementActions, studentCourseSessionActions } from '@Root/store'
import { SectionWrapper } from '@Root/HOCs'
import { useHistory, useLocation, useParams } from 'react-router'
import { CreateStudentCourseSession } from './Create'
import { EditStudentCourseSession } from './Edit'
import { DetailInformation } from './DetailInfomration'

export const StudentCourseSessionPage = () => {
  const location = useLocation()
  const history = useHistory()
  const { contactId, studyProgrammeId, contactTab } = useParams()
  const pathname = location.pathname
  const isNew = contactTab === 'new'
  const isDublicate = pathname.includes('duplicate')

  const dispatch = useDispatch()

  const goToContactBack = () => {
    history.push(`/home/contacts/all-contacts/${contactId}`)
  }

  const goToProgrammeBack = () => {
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}`)
  }

  const pageConfig = () => ({
    backButtonText: !isDublicate ? 'Contact' : 'Back',
    backButtonHandler: !isDublicate ? goToContactBack : goToProgrammeBack,
    canManipulateOtherForms: !isDublicate,
  })

  // useEffect(() => {
  // 	if (isDublicate) {
  // 		dispatch(studentCourseSessionNoteActions.set([]))
  // 		dispatch(studentCourseSessionAttachmentActions.set([]))
  // 	}
  // }, [isDublicate, dispatch])

  const fetchData = useCallback(() => {
    dispatch(programmeActions.getProgrammesOptions())
    dispatch(studentCourseSessionActions.getStaticOptions())
    dispatch(studentCourseSessionActions.getDynamicOptions())
    dispatch(studentCourseSessionActions.getDynamicOptionsByStudent({ studentId: contactId }))
  }, [contactId, dispatch])

  useEffect(() => {
    fetchData()
  }, [fetchData, isDublicate])

  useEffect(() => {
    dispatch(engagementActions.setErrors([]))
  }, [dispatch])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionsWrapper}>
        <SectionWrapper hasBackButton backButtonText={pageConfig().backButtonText} clickBackButtonHandler={pageConfig().backButtonHandler} activeName>
          <div>
            <div className={classes.studies_forms}>
              {isNew ? (
                <CreateStudentCourseSession onOpenDescriptionModal={onOpenDescriptionModal} backButtonHandler={pageConfig().backButtonHandler} />
              ) : (
                <EditStudentCourseSession onOpenDescriptionModal={onOpenDescriptionModal} isDuplicate={isDublicate} />
              )}
            </div>
            {!isNew && <DetailInformation isDuplicate={isDublicate} canManipulateOtherForms={pageConfig().canManipulateOtherForms} />}
          </div>
        </SectionWrapper>
      </div>
    </div>
  )
}
