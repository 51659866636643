import * as types from './options.types'

const initialState = {
  dataForCreateContact: {},
  genders: [],
  countries: [],
  institutions: [],
  admissionBapStatuses: [],
  accommodation: [],
  SLCLoan: [],
  statuses: [],
  inactiveModuleFlags: [],
  suspensions: [],
  rollover: {},
  financeFunding: {
    static: '',
    dynamic: '',
  },
  financeTuitionFees: {
    static: '',
    dynamic: '',
  },
  isLoading: false,
  roles: [],
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DATA_FOR_CREATE_CONTACT:
      return {
        ...state,
        dataForCreateContact: action.payload,
      }
    case types.SET_GENDERS:
      return {
        ...state,
        genders: action.payload,
      }
    case types.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      }
    case types.SET_INSTITUTIONS:
      return {
        ...state,
        institutions: action.payload,
      }
    case types.SET_ACCOMMODATION:
      return {
        ...state,
        accommodation: action.payload,
      }
    case types.SET_SLC_LOAN:
      return {
        ...state,
        SLCLoan: action.payload,
      }
    case types.SET_STATUSES:
      return {
        ...state,
        statuses: action.payload,
      }
    case types.SET_FINANCE_FUNDING:
      return {
        ...state,
        financeFunding: {
          ...state.financeFunding,
          [action.payload.name]: action.payload.data,
        },
      }
    case types.SET_FINANCE_TUITION:
      return {
        ...state,
        financeTuitionFees: {
          ...state.financeTuitionFees,
          [action.payload.name]: action.payload.data,
        },
      }
    case types.SET_STUDIES_SUSPENSIONS_OPTIONS:
      return {
        ...state,
        suspensions: action.payload,
      }

    case types.SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      }

    case types.SET_ROLLOVER:
      return {
        ...state,
        rollover: action.payload,
      }

    default:
      return state
  }
}
