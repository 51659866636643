import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { switchEmptyStringsToNullsInObject } from '@Root/helpers'
import { FormWrapper } from '@Root/HOCs'
import { InputLabel, TextInput, Select } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { useToggle } from '@Root/hooks'
import { API } from '@Root/API'
import { churchActions, churchSelectors, organisationSelectors } from '@Store/contact/organisation'
import { Redirect, useHistory } from 'react-router'
import { permissionsActions } from '@Store/permissions'
import { contactSelectors } from '@Root/store'

const PARENT_URL = '/home/contacts/all-contacts'
const relationShip = 'Church'
const titles = { onRead: 'Church Details', onEdit: 'Edit Church Details' }
export const ChurchForm = ({ contactId }) => {
  const history = useHistory()
  const [isEditable, toggleEditable] = useToggle(false)
  const [data, setData] = useState({})
  const relationships = useSelector(organisationSelectors.relationships)
  const church = useSelector(churchSelectors.church)
  const isLoading = useSelector(churchSelectors.isLoading)
  const isSuccess = useSelector(churchSelectors.isSuccess)
  const permissions = useSelector(churchSelectors.permissions)
  const isPermissionsLoaded = useSelector(churchSelectors.isPermissionsLoaded)
  const staticOptions = useSelector(churchSelectors.options).static
  const dynamicOptions = useSelector(churchSelectors.options).dynamic
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).organisation.churches
  const contactPermissions = useSelector(contactSelectors.permissions)
  const dispatch = useDispatch()

  useEffect(() => {
    const hasOptions = Object.keys(staticOptions).length > 0
    if (!hasOptions) dispatch(churchActions.getStaticOptions())
  }, [dispatch, staticOptions])

  useEffect(() => {
    dispatch(churchActions.getStaticOptions())
    dispatch(churchActions.getDynamicOptions())
    dispatch(fieldTooltipManagerActions.getContact({ entity: 'contacts', entity_type: 'organisation', entity_sub_type: 'churches' }))
  }, [dispatch])

  useEffect(() => {
    if (contactId) {
      dispatch(churchActions.get({ contactId }))
      dispatch(permissionsActions.getMain({ entity: 'church', contactId: Number(contactId) }))
    }
  }, [dispatch, contactId])

  useEffect(() => {
    if (church) setData(church)
  }, [church])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const onCancel = () => {
    setData(church)
    toggleEditable()
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: `The "${relationShip}" relationship will be removed?`,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        const relationshipId = relationships.find(item => item.label === relationShip).value
        dispatch(churchActions.remove({ contactId, relationshipId, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = async () => {
    const modifiedData = switchEmptyStringsToNullsInObject(data)
    dispatch(churchActions.edit({ ...modifiedData, id: contactId }))
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  if (!contactPermissions.tabs?.church) return <Redirect to={PARENT_URL} />
  const { form, fields } = permissions
  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', 'save'] : []} //...(form?.delete ? ['delete'] : [])
      //buttonsNames={[{ button: 'delete', name: 'Delete this church' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading || !isPermissionsLoaded}
      clickCancelButtonHandler={onCancel}
      clickDeleteButtonHandler={onDelete}
      clickSaveButtonHandler={onSave}
      canEdit={form?.edit}
      clickToggleEditModeButtonHandler={toggleEditable}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      idForm={data?.id ? `church_details_${data?.id}` : null}
      isSuccess={isSuccess}
    >
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text='Contact Officer' description={descriptions.contact_officer} onOpenDescription={onOpenDescriptionModal} />
          <div style={{ position: 'absolute', left: 150 }}>
            <TextInput
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.contact_officer}
              changeHandler={value => handleChangeInput('contact_officer', value)}
              isDisabled={!isEditable || !fields.contact_officer?.edit}
            />
          </div>

          <InputLabel
            text='Diocese'
            description={descriptions.diocese_id}
            onOpenDescription={onOpenDescriptionModal}
            style={{ position: 'absolute', left: 490 }}
          />
          <div style={{ position: 'absolute', left: 600, width: 260 }}>
            <Select
              inputClassNames={!isEditable ? ['borderless'] : []}
              options={dynamicOptions.diocese}
              value={data.diocese_id}
              changeHandler={value => handleChangeInput('diocese_id', value)}
              isDisabled={!isEditable || !fields.diocese_id?.edit}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Vicar / Pastor' description={descriptions.vicar} onOpenDescription={onOpenDescriptionModal} />
          <div style={{ position: 'absolute', left: 150 }}>
            <TextInput
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.vicar}
              changeHandler={value => handleChangeInput('vicar', value)}
              isDisabled={!isEditable || !fields.vicar?.edit}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Denomination' description={descriptions.denomination} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 150, width: 260 }}
            value={data.denomination}
            changeHandler={value => handleChangeInput('denomination', value)}
            isDisabled={!isEditable || !fields.denomination?.edit}
          />
        </div>
      </div>
    </FormWrapper>
  )
}

ChurchForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    contact_officer: PropTypes.string,
    vicar: PropTypes.string,
    denomination: PropTypes.string,
    diocese_id: PropTypes.number,
  }),
  fetchContactHandler: PropTypes.func,
  fetchDioceseByIdHandler: PropTypes.func,
  fetchDioceseByIndexHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func,
}

ChurchForm.defaultProps = {
  permissions: [],
  initialData: {
    contact_officer: null,
    vicar: null,
    denomination: null,
    diocese_id: null,
  },
  fetchContactHandler: () => {},
  fetchDioceseByIdHandler: () => {},
  fetchDioceseByIndexHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {},
}
