import { modalActions, snackbarActions } from '@Root/store'
import { useDispatch } from 'react-redux'
import { API } from '@Root/API'
import { errorMessage } from '@Root/helpers'

export const useTrackProcessImport = () => {
  const dispatch = useDispatch()

  const trackProcessImport = (data, config) => {
    const processId = data.job_id
    const importType = data.import_type
    const payload = {
      job_id: processId,
      import_type: importType,
    }

    dispatch(
      modalActions.showModal('ProcessModal', {
        headerText: 'Results',
        text: `Data process is in progress. Please, don't close this window`,
        title: 'Process data',
        fetchDataHandler: () => API.contact.imports.trackProcess(payload),
        clickRejectButtonHandler: () => dispatch(modalActions.hideModal()),
        clickResolveButtonHandler: data => {
          resultModalImport(data, config)
        },
      })
    )
  }

  const resultModalImport = (data, config) => {
    const { name, invalid_fields, validFields, notification_succeed, isImport } = config

    dispatch(
      modalActions.showModal('ImportModal', {
        headerText: 'Results',
        importDescription: data?.succeed_data.length
          ? `The following ${name} were ${isImport ? 'imported' : 'updated:'}`
          : `No ${name} were ${isImport ? 'imported' : 'updated'}.`,
        importData: {
          data: data?.succeed_data,
          fields: validFields,
        },
        invalidImportDescription: data?.invalid_data?.length ? 'Conflict with' : `No ${isImport ? 'import' : 'update'} conflicts were detected.`,
        invalidImportData:
          data?.invalid_data?.length || invalid_fields?.length ? { data: data?.invalid_data, fields: invalid_fields } : { data: [], fields: invalid_fields },
        leftButtonText: config?.isRollBack && data?.rollback?.length ? 'Roll back' : null,
        rightButtonText: 'Ok',
        clickRejectButtonHandler: button => {
          dispatch(modalActions.hideModal())
        },
        clickResolveButtonHandler: button => {
          if (button === 'left') {
            Rollback(data, config)
          } else if (button === 'right') {
            dispatch(modalActions.hideModal())
            dispatch(snackbarActions.setSuccessNotification({ text: notification_succeed }))
            if (config?.refreshTable) config?.refreshTable()
          }
        },
      })
    )
  }

  const importError = error => {
    const convertErrors = serverError => {
      try {
        const errorsObj = serverError.response.data.data.errors
        return Object.keys(errorsObj)
          .sort((a, b) => {
            const x = +a.slice(11)
            const y = +b.slice(11)
            if (x > y) return 1
            if (x < y) return -1
            return 0
          })
          .map(key => ({
            title: key,
            rows: errorsObj[key],
          }))
      } catch (error) {
        let message = serverError?.response?.data?.message
        return [{ title: 'Error Message', rows: [message] }]
      }
    }

    const errors = convertErrors(error)
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ImportModal', {
          headerText: 'Results',
          importDescription: `Unable to process the data. Please check your template.${errors?.length ? ' Errors list:' : ''}`,
          errors,
          rightButtonText: 'Ok',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    }).then(
      () => {
        dispatch(modalActions.hideModal())
      },
      () => {
        dispatch(modalActions.hideModal())
      }
    )
  }

  const Rollback = (importData, config) => {
    const { refreshTable, name, validFields } = config
    const { rollback, succeed_data } = importData
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ImportModal', {
          headerText: 'Warning',
          importDescription: `The ${name} records from the most recent import will be deleted. Click the delete button to delete the records:`,
          importData: { data: succeed_data, fields: validFields },
          leftButtonText: 'Cancel Rollback',
          rightButtonText: 'Delete',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    }).then(
      button => {
        if (button === 'left') {
          dispatch(modalActions.hideModal())
          refreshTable()
        } else if (button === 'right') {
          API.contact.imports.rollback({ rollback: rollback }).then(
            () => {
              new Promise((resolve, reject) => {
                dispatch(
                  modalActions.showModal('NotificationModal', {
                    text: 'Your records were deleted.',
                    clickRejectButtonHandler: reject,
                    clickResolveButtonHandler: resolve,
                  })
                )
              }).then(
                () => {
                  dispatch(modalActions.hideModal())
                },
                () => {
                  dispatch(modalActions.hideModal())
                }
              )
            },
            error => {
              dispatch(snackbarActions.setSnackbar({ text: `Unable to roll back. Error message: ${errorMessage(error)}`, isError: true }))
              dispatch(modalActions.hideModal())
              refreshTable()
            }
          )
        }
      },
      () => {
        dispatch(modalActions.hideModal())
        Promise.resolve()
      }
    )
  }
  return { trackProcessImport, importError }
}
