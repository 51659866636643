import { useState } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { InputLabel, TextInput, Select, DataList, DataListAsync, DatePicker, Spinner, CheckboxInput } from '@Root/components'

export const SubformInputGroup = ({
  inputs,
  data,
  changeHandler,
  deleteIsAllowed,
  deleteHandler,
  isEditable,
  inputPath,
  error,
  children,
  onOpenDescription,
  saveHandler,
  isSpinning,
  saveIsAllowed,
  rootParentOptions,
  isOnlyForView,
}) => {
  const [customsCodeStore, setCustomsCodeStore] = useState({})

  const errorMessage = inputField => {
    return error && error.inputPath === `${inputPath}/${inputField}` ? error.message : null
  }

  const checkDynamicOptions = input => {
    if (!input.options) return
    const isCustomComponent = input.custom_component
    const dynamicOptionField = input.options_dynamic_field
    const options = input.options
    if (dynamicOptionField) {
      return rootParentOptions[dynamicOptionField]
    }
    if (isCustomComponent && options) {
      const value = data[input.field]
      if (options.find(el => el.value === value) || value === null) {
        return options
      } else {
        options.push({ label: value, value })
      }
    }
    return options
  }

  const handlerCustomsCode = field => value => {
    setCustomsCodeStore({ ...customsCodeStore, [field]: value })
  }

  const isNewTab = data.id === null
  return (
    <div className={classes.wrapper} id={data.elementDOMId}>
      {inputs.map((input, i) => {
        const isDisabled = input.validations.includes('disabled')
        const canBeEdit = isOnlyForView ? true : !isNewTab && isDisabled ? true : !isEditable
        const isCustomComponent = input.custom_component
        return (
          <div className={classes.inputWrapper} key={i}>
            <InputLabel
              text={input.name}
              hasAsterisk={(input.validations || []).includes('required')}
              description={input.description}
              onOpenDescription={onOpenDescription}
            />
            {input.type === 'textInput' && (
              <TextInput
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 370 }}
                value={data[input.field]}
                changeHandler={value => changeHandler(input.field, value)}
                isDisabled={canBeEdit}
                error={errorMessage(input.field)}
                restriction={input.restriction}
                maxLength={input.maxLength}
              />
            )}
            {input.type === 'select' && (
              <Select
                inputClassNames={!isEditable ? ['borderless'] : []}
                inputStyle={{ width: 370 }}
                options={checkDynamicOptions(input)}
                value={data[input.field]}
                changeHandler={value => changeHandler(input.field, value)}
                isDisabled={canBeEdit}
                error={errorMessage(input.field)}
                optionsStyle={input.custom_component && { overflow: 'inherit' }}
                customComponents={
                  isCustomComponent && (
                    <TextInput
                      style={{ width: 340, border: 'none', height: 36 }}
                      value={customsCodeStore[input.field]}
                      changeHandler={handlerCustomsCode(input.field)}
                      placeholder='Set custom code'
                      isDisabled={canBeEdit}
                      maxLength={input.maxLength}
                      autoFocus
                    />
                  )
                }
                callBack={
                  isCustomComponent &&
                  (() => {
                    changeHandler(input.field, customsCodeStore[input.field])
                    setCustomsCodeStore({})
                  })
                }
              />
            )}
            {input.type === 'dataList' && (
              <DataList
                inputClassNames={!isEditable ? ['borderless'] : []}
                inputStyle={{ width: 370 }}
                options={input.options}
                value={data[input.field]}
                changeHandler={value => changeHandler(input.field, value)}
                isDisabled={canBeEdit}
                error={errorMessage(input.field)}
              />
            )}
            {input.type === 'dataListAsync' && (
              <DataListAsync
                inputClassNames={!isEditable ? ['borderless'] : []}
                inputStyle={{ width: 370 }}
                value={data[input.field]}
                fetchLabelHandler={value => input.fetchLabelHandler(value)}
                fetchOptionsHandler={value => input.fetchOptionsHandler(value)}
                changeHandler={value => changeHandler(input.field, value)}
                isDisabled={canBeEdit}
                error={errorMessage(input.field)}
              />
            )}
            {input.type === 'datePicker' && (
              <DatePicker
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 370 }}
                value={data[input.field]}
                changeHandler={value => changeHandler(input.field, value)}
                isDisabled={canBeEdit}
                error={errorMessage(input.field)}
              />
            )}

            {input.type === 'checkbox' && (
              <CheckboxInput
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 380 }}
                value={data[input.field]}
                changeHandler={value => changeHandler(input.field, value)}
                isDisabled={canBeEdit}
                error={errorMessage(input.field)}
              />
            )}
          </div>
        )
      })}
      {children}
      {isSpinning && (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      {!isOnlyForView && isEditable && (
        <div className={classes.buttonWrapper}>
          {deleteIsAllowed && (
            <button className={classes.buttonSecondary} onClick={deleteHandler} disabled={false}>
              Delete
            </button>
          )}
          {saveIsAllowed && (
            <button className={classes.buttonSave} onClick={saveHandler} disabled={isSpinning}>
              Save
            </button>
          )}
        </div>
      )}
    </div>
  )
}

SubformInputGroup.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      field: PropTypes.string,
      type: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        ])
      ),
    })
  ),
  data: PropTypes.object,
  changeHandler: PropTypes.func,
  deleteIsAllowed: PropTypes.bool,
  deleteHandler: PropTypes.func,
  isEditable: PropTypes.bool,
  inputPath: PropTypes.string,
  error: PropTypes.shape({
    inputPath: PropTypes.string,
    message: PropTypes.string,
  }),
  children: PropTypes.node,
  onOpenDescription: PropTypes.func,
}

SubformInputGroup.defaultProps = {
  inputs: [],
  data: {},
  changeHandler: () => {},
  deleteIsAllowed: false,
  deleteHandler: () => {},
  isEditable: false,
  inputPath: '',
  error: null,
  children: null,
  onOpenDescription: () => {},
  rootParentOptions: {},
}
