import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classes from './style.module.scss'
import closeIcon from '../../../assets/icons/close.png'
import { Field, NotificationModal } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { optionsActions, optionsSelectors, fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { useError } from '@Root/hooks'
import { API } from '@Root/API'

export const Rollover = ({ param, conf, clickRejectButtonHandler, clickResolveButtonHandler }) => {
  const [data, setData] = useState({})
  const dispatch = useDispatch()
  const option = useSelector(optionsSelectors.rollover)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagements_rollover
  const rolloverDescription = useSelector(fieldTooltipManagerSelectors.rolloverDescriptions)
  const [notification, setNotification] = useState({})
  const [open, setOpen] = useState()
  const { errors, setError, onClear: onClearError } = useError()
  const { key, handelSubmit } = conf

  useEffect(() => {
    dispatch(optionsActions.getRollover())

    switch (key) {
      case 'leavers':
        dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'engagements_rollover' }))
        break
      case 'recruited_students':
        dispatch(fieldTooltipManagerActions.getRolloverDescription({ entity: 'rollover', entity_type: 'students_rollover_newly' }))
        break

      case 'continuing_students':
        dispatch(fieldTooltipManagerActions.getRolloverDescription({ entity: 'rollover', entity_type: 'students_rollover_continuing' }))
        break

      default:
        break
    }
  }, [])

  useEffect(() => {
    const { is_change_fee_amount } = data
    if (!is_change_fee_amount) {
      onChangeValue('invoice_fee_amount')('')
    }
  }, [data?.is_change_fee_amount])

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onOpenDescriptionModal = (description, title) => {
    setNotification({ text: description, clickRejectButtonHandler: closeModal, clickResolveButtonHandler: closeModal, title })
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
    setNotification({})
  }

  const getMarck = () => {
    switch (key) {
      case 'leavers':
        return (
          <>
            <Field
              label={'Student course session end date'}
              classNames={['borderless']}
              style={{ width: 260 }}
              value={data?.student_course_session_end_date}
              onChange={onChangeValue('student_course_session_end_date')}
              placeholder='Student course session end date'
              isDisabled={false}
              isRequired={true}
              maxLength={10}
              description={descriptions?.student_course_session_end_date}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'datePicker'}
              isView={true}
              error={errors.student_course_session_end_date}
              styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
            />

            <Field
              label={'Engagement end date'}
              classNames={['borderless']}
              style={{ width: 260 }}
              value={data?.engagement_end_date}
              onChange={onChangeValue('engagement_end_date')}
              placeholder='Engagement end date'
              isDisabled={false}
              isRequired={true}
              maxLength={10}
              description={descriptions?.expected_end_date}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'datePicker'}
              isView={true}
              error={errors.engagement_end_date}
              styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
            />
            <Field
              label={'Сompletion status'}
              classNames={['borderless']}
              style={{ width: 260 }}
              value={data?.status_id || null}
              onChange={onChangeValue('status_id')}
              placeholder='Сompletion status'
              isDisabled={false}
              field={'select'}
              options={option.status}
              isRequired={true}
              optionsStyle={{ width: 260 }}
              maxLength={2}
              description={descriptions?.status_id}
              onOpenDescriptionModal={onOpenDescriptionModal}
              error={errors.status_id}
              styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
            />
          </>
        )

      case 'recruited_students':
        return (
          <>
            <Field
              label={'Status'}
              classNames={['borderless']}
              style={{ width: 260 }}
              value={data?.status_id || null}
              onChange={onChangeValue('status_id')}
              placeholder='Status'
              isDisabled={false}
              field={'select'}
              options={option.status}
              isRequired={true}
              optionsStyle={{ width: 260 }}
              maxLength={2}
              description={rolloverDescription?.students_rollover_newly?.status_id}
              onOpenDescriptionModal={onOpenDescriptionModal}
              error={errors.status_id}
              styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
            />
          </>
        )

      case 'continuing_students':
        return (
          <>
            <div className={classes.wrapperInput}>
              {' '}
              <p className={classes.title}>Old student course session</p>
              <Field
                label={'What would you like to set the student course session end date as?'}
                classNames={['borderless']}
                style={{ width: 260 }}
                value={data?.student_course_session_end_date}
                onChange={onChangeValue('student_course_session_end_date')}
                placeholder='Session Year End Date'
                isDisabled={false}
                description={rolloverDescription?.students_rollover_continuing?.student_course_session_end_date}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors?.student_course_session_end_date}
                styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
                field={'datePicker'}
              />
              <Field
                label={'If the closing student course session already has an end date set, do you want to override it with the above?'}
                classNames={['borderless']}
                style={{ width: 270 }}
                value={data?.override_student_course_session_end_date}
                onChange={onChangeValue('override_student_course_session_end_date')}
                isDisabled={false}
                description={rolloverDescription?.students_rollover_continuing?.override_student_course_session_end_date}
                error={errors?.override_student_course_session_end_date}
                field={'checkbox'}
                onOpenDescriptionModal={onOpenDescriptionModal}
                styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
              />
              <Field
                label={'What would you like to set the status to?'}
                classNames={['borderless']}
                style={{ width: 260 }}
                value={data?.old_status_id || null}
                onChange={onChangeValue('old_status_id')}
                placeholder='Status'
                isDisabled={false}
                field={'select'}
                options={option.status}
                optionsStyle={{ width: 260 }}
                maxLength={2}
                description={rolloverDescription?.students_rollover_continuing?.old_status_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.old_status_id}
                styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
              />
              <Field
                label={'If you would like to set a Note for Session Ending, please specify the text here'}
                classNames={['borderless']}
                style={{ width: 260 }}
                value={data?.reason_note}
                onChange={onChangeValue('reason_note')}
                placeholder='Note'
                isDisabled={false}
                maxLength={1000}
                description={rolloverDescription?.students_rollover_continuing?.reason_note}
                error={errors?.reason_note}
                field={'textArea'}
                autoHeight
                onOpenDescriptionModal={onOpenDescriptionModal}
                styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
              />
            </div>
            <div className={classes.wrapperInput}>
              <p className={classes.title}>New student course session</p>
              <Field
                label={'What would you like the start date for the new student course session to be set to?'}
                classNames={['borderless']}
                style={{ width: 260 }}
                value={data?.course_session_start_date}
                onChange={onChangeValue('course_session_start_date')}
                placeholder='New Session Year Start Date'
                isDisabled={false}
                description={rolloverDescription?.students_rollover_continuing?.course_session_start_date}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors?.course_session_start_date}
                styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
                field={'datePicker'}
              />
              <Field
                label={'Do you want to change the invoice fee amount?'}
                classNames={['borderless']}
                style={{ width: 270 }}
                value={data?.change_invoice_fee_amount}
                onChange={onChangeValue('change_invoice_fee_amount')}
                isDisabled={false}
                description={rolloverDescription?.students_rollover_continuing?.change_invoice_fee_amount}
                error={errors?.change_invoice_fee_amount}
                field={'checkbox'}
                onOpenDescriptionModal={onOpenDescriptionModal}
                styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
              />
              {data?.change_invoice_fee_amount && (
                <Field
                  label={'What would you like the invoice fee amount on the new student course session to be set as?'}
                  classNames={['borderless']}
                  style={{ width: 260 }}
                  value={data?.invoice_fee_amount}
                  onChange={onChangeValue('invoice_fee_amount')}
                  placeholder='Invoice fee amount'
                  maxLength={6}
                  description={rolloverDescription?.students_rollover_continuing?.invoice_fee_amount}
                  onOpenDescriptionModal={onOpenDescriptionModal}
                  field={'textField'}
                  restriction='digits'
                  isView={true}
                  isDisabled={!data?.change_invoice_fee_amount}
                  styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
                />
              )}

              <Field
                label={'What session year should the new student course session be set to?'}
                classNames={['borderless']}
                style={{ width: 260 }}
                value={data?.session_year_id || null}
                onChange={onChangeValue('session_year_id')}
                placeholder='Session Year'
                isDisabled={false}
                options={option.session_years}
                description={rolloverDescription?.students_rollover_continuing?.session_year_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isView={true}
                error={errors.session_year_id}
                isRequired={true}
                styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
              />
              <Field
                label={'What would you like to set the status to?'}
                classNames={['borderless']}
                style={{ width: 260 }}
                value={data?.status_id || null}
                onChange={onChangeValue('status_id')}
                placeholder='Status'
                isDisabled={false}
                field={'select'}
                options={option.status}
                isRequired={true}
                optionsStyle={{ width: 260 }}
                maxLength={2}
                description={rolloverDescription?.students_rollover_continuing?.status_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.status_id}
                styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
              />
              <Field
                label={'Do you want to copy the student financial support data?'}
                classNames={['borderless']}
                style={{ width: 270 }}
                value={data?.copy_financial_support}
                onChange={onChangeValue('copy_financial_support')}
                isDisabled={false}
                description={rolloverDescription?.students_rollover_continuing?.copy_financial_support}
                error={errors?.copy_financial_support}
                field={'checkbox'}
                onOpenDescriptionModal={onOpenDescriptionModal}
                styleWrapper={{ gridTemplateColumns: '280px 1fr' }}
              />
            </div>
          </>
        )
      default:
        return <></>
    }
  }

  const validation = () => {
    if (!data?.status_id) {
      setError('status_id', 'Required')
      return false
    }

    switch (key) {
      case 'leavers':
        if (!data?.student_course_session_end_date) {
          setError('student_course_session_end_date', 'Required')
          return false
        }
        if (!data?.engagement_end_date) {
          setError('engagement_end_date', 'Required')
          return false
        }
        break

      case 'continuing_students':
        if (!data?.session_year_id) {
          setError('session_year_id', 'Required')
          return false
        }

        if (!data?.old_status_id) {
          setError('old_status_id', 'Required')
          return false
        }

        break

      default:
        break
    }

    return true
  }

  const onSubmit = async () => {
    if (!validation()) return

    if (key === 'continuing_students') {
      return (
        dispatch(
          modalActions.showModal('ModalProcessedRollover', {
            handler: params => conf.handlerWarningCheck(params),
            headerText: conf.headerText,
            text: 'This/these students already have a HESA Reason for Session Ending or note for session ending entry set. Do you want to proceed - existing data will be overwritten.',
            param,
            saveData: { ...data, ...param },
            clickRejectButtonHandler: () => {
              clickRejectButtonHandler()
            },
            clickResolveButtonHandler: async data => {
              dispatch(modalActions.hideModal())
              const res = await handelSubmit(data)
              clickResolveButtonHandler(res.data.data)
            },
          })
        ))

    }

    submitHandle()
  }

  const submitHandle = async () => {
    try {
      const res = await handelSubmit({ ...data, ...param })
      clickResolveButtonHandler(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <h3 className={classes.headerText}>Rollover</h3>
        <div className={classes.icon} onClick={clickRejectButtonHandler}>
          <img src={closeIcon} alt='' />
        </div>
      </div>
      <div className={classes.line} />
      <div className={classes.contentText}>{getMarck()}</div>

      <div className={classes.line} />
      <div className={classes.buttons}>
        <button className={classes.buttonLeft} onClick={clickRejectButtonHandler}>
          Cancel
        </button>
        <button className={classes.buttonRight} onClick={onSubmit}>
          Confirm
        </button>
      </div>
      {open && (
        <div className={classes.backDrop} onClick={closeModal}>
          <NotificationModal {...notification} />
        </div>
      )}
    </div>
  )
}

Rollover.propTypes = {
  handler: PropTypes.func,
  clickRejectButtonHandler: PropTypes.func,
  clickResolveButtonHandler: PropTypes.func,
}
