import React, { useEffect, useState, useRef } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { useToggle } from '@Root/hooks'
import { useSelector } from 'react-redux'
import { nanoid } from '@Root/../node_modules/nanoid/index'
import { useError } from '@Root/hooks'

const titles = { onRead: 'Course Role', onEdit: 'Edit Course Role', onCreate: 'Create Course Role' }
export const CourseRoleForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  initialErrors,
  onOpenDescriptionModal,
}) => {
  const [data, setData] = useState({})
  const [customCode, setCustomCode] = useState({ label: '', value: '' })
  const [hesaIdOptions, setHesaIdOptions] = useState([])
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).programmes_course_role
  const { errors, setError, onClear: onClearError } = useError()
  const memoData = useRef({})

  //init data operation
  useEffect(() => {
    initData(initialValue)
    if (!memoData.current?.proportion) memoData.current = initialValue
  }, [initialValue, options.hesa_identifiers])

  const initData = initData => {
    if (options?.hesa_identifiers) {
      if (options.hesa_identifiers.find(el => el.value === initData.hesa_identifier) || initData.hesa_identifier === null) {
        setHesaIdOptions([...options.hesa_identifiers])
        setData(initData)
      } else {
        const id = nanoid()
        setHesaIdOptions([...options.hesa_identifiers, { label: initData.hesa_identifier, value: id }])
        setData({ ...initData, hesa_identifier: id })
      }
    }
  }

  useEffect(() => {
    if (initialErrors?.proportion) {
      setError('proportion', initialErrors.proportion[0])
    }
  }, [initialErrors])

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      onClearError()
      memoData.current = initialValue
    }
  }, [isSuccess])

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const validateForm = () => {
    const { hesa_identifier, role_type_id, proportion } = data

    if (!hesa_identifier) {
      setError('hesa_identifier', 'Required')
      return false
    }
    if (!role_type_id) {
      setError('role_type_id', 'Required')
      return false
    }
    if (!proportion) {
      setError('proportion', 'Required')
      return false
    }
    return true
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    if (options.hesa_identifiers.find(el => el.value === data.hesa_identifier)) {
      onSave(data)
    } else {
      onSave({ ...data, hesa_identifier: hesaIdOptions.find(el => el.value === data.hesa_identifier).label })
      setCustomCode({ label: '', value: '' })
    }
  }

  const onCancelEdit = () => {
    if (!isNew) {
      toggleEditable()
      onClearError()
      initData(memoData.current)
      onCancel(memoData.current)
    } else {
      onCancel()
    }
  }

  const onChangeCustomCode = field => value => {
    setCustomCode({ [field]: value, value: nanoid() })
  }

  const onAddCustomCode = () => {
    setData(prevState => ({ ...prevState, hesa_identifier: customCode.value }))
    setHesaIdOptions([...hesaIdOptions, customCode])
    setCustomCode({ label: '', value: '' })
  }

  const { onEdit, onRead, onCreate } = titles
  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Course Role' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={data?.id ? `course_role_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'HESA identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.hesa_identifier}
                onChange={onChangeValue('hesa_identifier')}
                placeholder='HESA identifier'
                isDisabled={!isEditable}
                isRequired={isEditable}
                options={hesaIdOptions}
                description={descriptions?.hesa_identifier_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                optionsStyle={{ overflow: 'inherit' }}
                customComponents={
                  <Field
                    style={{ width: 230, height: 36, border: 'none' }}
                    value={customCode.label}
                    onChange={onChangeCustomCode('label')}
                    placeholder='Set custom code'
                    isDisabled={!isEditable}
                    field={'textField'}
                    maxLength={8}
                    autoFocus
                  />
                }
                error={errors.hesa_identifier}
                callBack={onAddCustomCode}
              />
              <Field
                label={'Role type'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.role_type_id}
                onChange={onChangeValue('role_type_id')}
                placeholder='Role type'
                isDisabled={!isEditable}
                options={options.role_types}
                description={descriptions?.role_type_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                error={errors.role_type_id}
                isRequired={isEditable}
              />
              <Field
                label={'Course role proportion'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.proportion}
                onChange={onChangeValue('proportion')}
                placeholder='Course role proportion'
                isDisabled={!isEditable}
                isRequired={isEditable}
                description={descriptions?.proportion}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                maxLength={4}
                error={errors.proportion}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}
