import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'
import { nanoid } from '@Root/../node_modules/nanoid/index'

const titles = { onEdit: 'Edit the Entry Profile', onRead: 'Entry Profile Details', onCreate: 'Create Entry Profile' }
export const EntryProfileForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  descriptions,
  onOpenDescriptionModal,
}) => {
  const [data, setData] = useState({})
  const { errors, setError } = useError()
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const [customCode, setCustomCode] = useState({
    label: '',
    value: '',
  })
  const [previousProvidersOptions, setPreviousProvidersOptions] = useState([])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  //init data operation
  useEffect(() => {
    if (options?.previous_providers) {
      if (options.previous_providers.find(el => el.value === initialValue.previous_provider) || initialValue.previous_provider === null) {
        setPreviousProvidersOptions([...options.previous_providers])
        setData(initialValue)
      } else {
        const id = nanoid()
        setPreviousProvidersOptions([
          ...options.previous_providers,
          {
            label: initialValue.previous_provider,
            value: id,
          },
        ])
        setData({
          ...initialValue,
          previous_provider: id,
        })
      }
    }
  }, [initialValue, options.previous_providers])

  const validateForm = () => {
    const { permanent_address_id } = data
    if (!permanent_address_id) {
      setError('permanent_address_id', 'Required')
      return false
    }
    return true
  }

  const onChangeValue = field => value => {
    setData(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onCancelEditMode = () => {
    onChangeMode()
    setData(initialValue)
  }

  const onSubmitForm = () => {
    if (!validateForm()) return

    if (options.previous_providers.find(el => el.value === data.previous_provider) || data.previous_provider === null) {
      onSave(data)
    } else {
      onSave({ ...data, previous_provider: previousProvidersOptions.find(el => el.value === data.previous_provider).label })
      setCustomCode({ label: '', value: '' })
    }
  }

  const onCancelEdit = () => {
    if (!isNew) {
      onCancelEditMode()
      return
    }
    onCancel()
  }

  const onChangeCustomCode = field => value => {
    setCustomCode({
      [field]: value,
      value: nanoid(),
    })
  }
  const onAddCustomCode = () => {
    setData(prevState => ({
      ...prevState,
      previous_provider: customCode.value,
    }))
    setPreviousProvidersOptions([...previousProvidersOptions, customCode])
    setCustomCode({
      label: '',
      value: '',
    })
  }

  const {
    id,
    access_programme_id,
    care_leaver_id,
    crdptstu,
    credit_scheme_id,
    dependant_id,
    estranged_student_id,
    highestqualoe_id,
    marital_status_id,
    parental_education_id,
    permanent_address_id,
    postcode_permanent_address,
    previous_provider,
    religious_background_id,
    socio_economic_classification_id,
    standard_occupational_id,
    ucas_scheme_code,
    year_left_last_provider,
    apel_credit_id,
    standard_occupational2020_id,
  } = data

  const {
    access_programmes,
    apel_credits: apel_credits_options,
    care_leavers,
    credit_schemes,
    dependants,
    estranged_students,
    highestqualoes,
    marital_statuses,
    parental_education,
    permanent_addresses,
    religious_backgrounds,
    socio_economic_classifications,
    standard_occupational_classifications,
    socio_economic_classifications2020,
  } = options

  const { onEdit, onRead, onCreate } = titles
  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[
          {
            button: 'delete',
            name: 'Delete this entry profile',
          },
        ]}
        isSuccess={isSuccess}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={id ? `edit_entry_profile_${id}` : null}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Access programme'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={access_programme_id}
                field={'select'}
                onChange={onChangeValue('access_programme_id')}
                placeholder='Access programme'
                isDisabled={!isEditable}
                options={access_programmes}
                maxLength={2}
                description={descriptions?.access_programme_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                optionsStyle={{
                  width: 260,
                }}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'APEL credits'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={apel_credit_id}
                field={'select'}
                onChange={onChangeValue('apel_credit_id')}
                placeholder='APEL credits'
                isDisabled={!isEditable}
                options={apel_credits_options}
                maxLength={2}
                restriction='digits'
                description={descriptions?.apel_credit_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Care leaver'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={care_leaver_id}
                field={'select'}
                onChange={onChangeValue('care_leaver_id')}
                placeholder='Care leaver'
                isDisabled={!isEditable}
                options={care_leavers}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.care_leaver_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Credits points counted towards study'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={crdptstu}
                onChange={onChangeValue('crdptstu')}
                placeholder='Credits points counted towards study'
                isDisabled={!isEditable}
                maxLength={3}
                description={descriptions?.crdptstu}
                onOpenDescriptionModal={onOpenDescriptionModal}
                restriction='digits'
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Credit scheme'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={credit_scheme_id}
                field={'select'}
                onChange={onChangeValue('credit_scheme_id')}
                placeholder='Credit scheme'
                isDisabled={!isEditable}
                options={credit_schemes}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.credit_scheme_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Dependant'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={dependant_id}
                field={'select'}
                onChange={onChangeValue('dependant_id')}
                placeholder='Dependant'
                isDisabled={!isEditable}
                options={dependants}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.dependant_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Estranged student'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={estranged_student_id}
                field={'select'}
                onChange={onChangeValue('estranged_student_id')}
                placeholder='Estranged student'
                isDisabled={!isEditable}
                options={estranged_students}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.estranged_student_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Highest qualification on entry'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={highestqualoe_id}
                field={'select'}
                onChange={onChangeValue('highestqualoe_id')}
                placeholder='Highest qualification on entry'
                isDisabled={!isEditable}
                options={highestqualoes}
                maxLength={2}
                optionsStyle={{ width: 260, overflow: 'inherit' }}
                description={descriptions?.highestqualoe_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                search={true}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Marital status'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={marital_status_id}
                field={'select'}
                onChange={onChangeValue('marital_status_id')}
                placeholder='Marital status'
                isDisabled={!isEditable}
                options={marital_statuses}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.marital_status_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Parental education'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={parental_education_id}
                field={'select'}
                onChange={onChangeValue('parental_education_id')}
                placeholder='Parental education'
                isDisabled={!isEditable}
                options={parental_education}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.parental_education_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Country of permanent address'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={permanent_address_id}
                field={'select'}
                onChange={onChangeValue('permanent_address_id')}
                placeholder='Country of permanent address'
                isDisabled={!isEditable}
                options={permanent_addresses}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                }}
                error={errors.permanent_address_id}
                isRequired
                description={descriptions?.permanent_address_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Postcode of permanent home address'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={postcode_permanent_address}
                onChange={onChangeValue('postcode_permanent_address')}
                placeholder='Postcode of permanent home address'
                isDisabled={!isEditable}
                maxLength={8}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.postcode_permanent_address}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Previous provider'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={previous_provider}
                field={'select'}
                onChange={onChangeValue('previous_provider')}
                placeholder='Previous provider'
                isDisabled={!isEditable}
                options={previousProvidersOptions}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                  overflow: 'inherit',
                }}
                description={descriptions?.previous_provider}
                onOpenDescriptionModal={onOpenDescriptionModal}
                customComponents={
                  <Field
                    style={{
                      width: 230,
                      border: 'none',
                      height: 36,
                    }}
                    value={customCode.label}
                    onChange={onChangeCustomCode('label')}
                    placeholder='Set custom code'
                    isDisabled={!isEditable}
                    field={'textField'}
                    maxLength={8}
                    autoFocus
                  />
                }
                callBack={onAddCustomCode}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Religious background'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={religious_background_id}
                field={'select'}
                onChange={onChangeValue('religious_background_id')}
                placeholder='Religious background'
                isDisabled={!isEditable}
                options={religious_backgrounds}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.religious_background_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Socio economic classification'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={socio_economic_classification_id}
                field={'select'}
                onChange={onChangeValue('socio_economic_classification_id')}
                placeholder='Socio economic classification'
                isDisabled={!isEditable}
                options={socio_economic_classifications}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.socio_economic_classification_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Standard occupational classification 2010'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={standard_occupational_id}
                field={'select'}
                onChange={onChangeValue('standard_occupational_id')}
                placeholder='Standard occupational classification 2010'
                isDisabled={!isEditable}
                options={standard_occupational_classifications}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.standard_occupational_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Standard occupational classification 2020'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={standard_occupational2020_id}
                field={'select'}
                onChange={onChangeValue('standard_occupational2020_id')}
                placeholder='Standard occupational classification 2020'
                isDisabled={!isEditable}
                options={socio_economic_classifications2020}
                maxLength={2}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.standard_occupational2020_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'UCAS scheme code'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={ucas_scheme_code}
                onChange={onChangeValue('ucas_scheme_code')}
                placeholder='UCAS scheme code'
                isDisabled={!isEditable}
                maxLength={4}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.ucas_scheme_code}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Year left last provider'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{
                  width: 260,
                }}
                value={year_left_last_provider}
                onChange={onChangeValue('year_left_last_provider')}
                placeholder='Year left last provider'
                isDisabled={!isEditable}
                maxLength={4}
                optionsStyle={{
                  width: 260,
                }}
                description={descriptions?.year_left_last_provider}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}
