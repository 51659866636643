import * as types from './globalSearch.types'

const initialState = {
  data: [],
  meta: { current_page: 1, per_page: 10 },
  options: {
    category: [
      {
        label: 'All',
        value: 'all',
      },
      { label: 'Students', value: 'student' },
      { label: 'Academics', value: 'academic' },
      { label: 'Staff', value: 'staff' },
      { label: 'Clergy', value: 'clergy' },
      { label: 'Alumni', value: 'association' },
      { label: 'Churches', value: 'church' },
      { label: 'Dioceses', value: 'diocese' },
      { label: 'Training Facilities', value: 'trainingfacility' },
      { label: 'Other', value: 'other' },
    ],
  },
  isLoading: true,
  isLoadingPagination: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.TOGGLE_LOADING_PAGINATION:
      return {
        ...state,
        isLoadingPagination: action.payload,
      }
    case types.SET_SEARCH_DATA:
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
      }

    case types.SET_SEARCH_DATA_PAGINATION:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        meta: action.payload.meta,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: action.payload,
      }
    default:
      return state
  }
}
