import { useState, useEffect } from 'react'
import { SectionTitle, SearchField, Select, SaveButton, CancelButton, LoadMoreButton, SkeletonSearch } from '@Root/components'
import { useSelector, useDispatch } from 'react-redux'
import classes from './style.module.scss'
import { globalSearchSelectors, globalSearchActions } from '@Store/globalSearch/index'
import { getFromQueryString } from '@Root/helpers'
import { NavLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import { CustomScrollbar } from '@Root/HOCs'
import { useError } from '@Root/hooks'
import { API } from '@Root/API'

export const SearchPage = () => {
  const [data, setData] = useState({
    search_phrase: '',
    contact_type: 'all',
  })
  const dispatch = useDispatch()
  const history = useHistory()
  const result = useSelector(globalSearchSelectors.getDataSearch)
  const meta = useSelector(globalSearchSelectors.getMetaSearch)
  const loading = useSelector(globalSearchSelectors.isLoading)
  const loadingPagination = useSelector(globalSearchSelectors.isLoadingPagination)
  const optionsCategory = useSelector(globalSearchSelectors.globalSearchOptions)?.category
  const searchUrl = getFromQueryString('q')
  const categorySearchUrl = getFromQueryString('contact_type')
  const { errors, setError, onClear: onClearError } = useError()
  const { search_phrase, contact_type } = data

  useEffect(() => {
    setData({ ...data, search_phrase: searchUrl, contact_type: categorySearchUrl })
    dispatch(globalSearchActions.getSearchData({ search_phrase: searchUrl, contact_type: categorySearchUrl, page: 1 }))
  }, [])

  const handleChange = (name, value) => {
    if (name === 'contact_type' && value === null) {
      setData({ ...data, contact_type: 'all' })
    } else {
      setData({ ...data, [name]: value })
    }
  }

  const validation = () => {
    const { search_phrase } = data
    if (search_phrase.length < 2) {
      setError('search_phrase', 'Min 2 characters allowed')
      return false
    }

    onClearError()
    return true
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!validation()) return
    dispatch(globalSearchActions.getSearchData({ ...data, page: 1 }))
    history.push(`/home/teid-search?${search_phrase ? 'q=' + search_phrase : ''}&contact_type=${contact_type}`)
  }

  const handelPagination = () => {
    dispatch(globalSearchActions.getSearchDataPagination({ ...data, page: meta.current_page + 1 }))
    history.push(`/home/teid-search?${search_phrase ? 'q=' + search_phrase : ''}${contact_type ? '&contact_type=' + contact_type : ''}`)
  }

  const scrollTo = id => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }

  useEffect(() => {
    scrollTo('loadingPaginationSkeleton')
  }, [loadingPagination])

  const onExportSearch = async () => {
    try {
      const res = await API.globalSearch.exportCSV({ contact_type, search_phrase, total: meta.total })
      console.log(res);
      const blob = new Blob([res.data], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.csv');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <main className={classes.mainPage}>
      <div className={classes.wrapper}>
        <form className={classes.formSearch} onSubmit={handleSubmit}>
          {result.length > 0 && (
            <CancelButton
              type={'button'}
              style={{
                width: '140px',
                flexShrink: 0
              }}
              onClick={onExportSearch}
            >
              CSV Export
            </CancelButton>
          )}

          <SearchField
            onChange={value => handleChange('search_phrase', value)}
            value={search_phrase}
            placeholder='search TEID'
            styleSearch={{ margin: 0, display: 'block', width: '100%' }}
            styleWrapper={{ width: '100%' }}
            error={errors.search_phrase}
          />

          <Select inputStyle={{ width: 400 }} options={optionsCategory} value={contact_type} changeHandler={value => handleChange('contact_type', value)} />
          <SaveButton type={'submit'}> Search </SaveButton>
        </form>

        <section className={classes.sectionSearch}>
          {loading ? (
            <div className={classes.skeletonLoader}>
              <SkeletonSearch id='Skeleton-first' />
              <SkeletonSearch id='Skeleton-second' />
            </div>
          ) : (
            <>
              {' '}
              {result.length > 0 ? (
                <>
                  <SectionTitle title={`Search results for: ${searchUrl}`} />
                  <ul className={classes.resultCardList}>
                    <CustomScrollbar verticalOnly>
                      <div className={classes.wrapperList}>
                        {result.map(el => (
                          <li className={classes.resultCardItem} key={el?.id}>
                            <h3>
                              {el.contact_type}{' '}
                              <NavLink to={el.contact_name_link} className={classes.link}>
                                {el.contact_name}
                              </NavLink>
                            </h3>
                            <p>'{searchUrl}' is found in:</p>
                            <ul className={classes.resultList}>
                              {el.results.map(({ field_name, category, category_link, id, file_path }) => (
                                <li className={classes.resultItem} key={id}>
                                  <p>
                                    <NavLink to={category_link} className={classes.link}>
                                      {category}
                                    </NavLink>
                                    {field_name ? file_path ? (<>: <a href={file_path} target="_blank" rel="noreferrer noopener">{field_name}</a></>) : `: ${field_name}` : ''}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </div>
                      {loadingPagination && (
                        <li className={classes.skeletonLoaderPagination}>
                          <SkeletonSearch id='loadingPaginationSkeleton' />
                        </li>
                      )}

                      {meta.last_page !== meta.current_page && !loadingPagination && (
                        <div className={classes.wrapperBtn}>
                          {' '}
                          <LoadMoreButton handelPaginationAction={handelPagination} />
                        </div>
                      )}
                    </CustomScrollbar>
                  </ul>
                </>
              ) : (
                <>
                  <div className={classes.error}>No results found</div>
                </>
              )}
            </>
          )}
        </section>
      </div>
    </main>
  )
}
